"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationContextProvider = exports.useAuthenticationContext = exports.makeIdentityPlatformUrl = exports.AuthenticationOrigin = void 0;
var React = __importStar(require("react"));
var AuthenticationContext = React.createContext(undefined);
var AuthenticationOrigin;
(function (AuthenticationOrigin) {
    AuthenticationOrigin["PasswordProtection"] = "password protection";
    AuthenticationOrigin["SpringboardEngagementSavePage"] = "save page";
    AuthenticationOrigin["SpringboardEngagementContactOwner"] = "contact owner";
    AuthenticationOrigin["SpringboardEngagementMenu"] = "springboard engagement menu";
    AuthenticationOrigin["AccessAuthentication"] = "access authentication";
    AuthenticationOrigin["PostAcceptFlow"] = "post-accept flow";
    AuthenticationOrigin["AcceptPrefill"] = "accept prefill";
})(AuthenticationOrigin || (exports.AuthenticationOrigin = AuthenticationOrigin = {}));
var makeIdentityPlatformUrl = function (baseUrl) {
    return function (action, continueUrl, origin) {
        var userType = "springboard";
        return "".concat(baseUrl, "/auth/v1/").concat(action, "?continueUrl=").concat(encodeURIComponent(continueUrl.toString()), "&origin=").concat(encodeURIComponent(origin), "&userType=").concat(userType);
    };
};
exports.makeIdentityPlatformUrl = makeIdentityPlatformUrl;
var useAuthenticationContext = function () {
    var auth = React.useContext(AuthenticationContext);
    if (!auth) {
        throw new Error("useAuthenticationContext() must be used within a <AuthenticationContextProvider> or <AuthenticationProvider> component");
    }
    return auth;
};
exports.useAuthenticationContext = useAuthenticationContext;
var AuthenticationContextProvider = function (_a) {
    var authAppBaseUrl = _a.authAppBaseUrl, children = _a.children;
    return (React.createElement(AuthenticationContext.Provider, { value: { getAuthUrl: (0, exports.makeIdentityPlatformUrl)(authAppBaseUrl), authAppBaseUrl: authAppBaseUrl } }, children));
};
exports.AuthenticationContextProvider = AuthenticationContextProvider;
