// Generated by CoffeeScript 1.12.7
(function () {
  var QUOTE_MOBILE_BREAKPOINT,
    QuoteContent,
    QuoteContentTemplate,
    SectionView,
    TotalsView,
    isEqual,
    quoteLabelEventEmitter,
    quoteSelection;

  QuoteContentTemplate = require("./QuoteContent.html");

  SectionView = require("./QuoteSection.js");

  TotalsView = require("./Totals");

  quoteSelection = require("./quoteSectionSelection")["default"];

  quoteLabelEventEmitter = require("./../Labels/QuoteLabelEventEmitter")["default"];

  isEqual = require("lodash").isEqual;

  /*
    @NOTE (som, 2018-04-23): the mobile breakpoint for quote blocks is not necessary going to be
    the same as other content, which is why we don't just use `mobileBreakpoint`
   */

  QUOTE_MOBILE_BREAKPOINT = 750;

  module.exports = QuoteContent = (function () {
    function QuoteContent(
      isEditor,
      createUi,
      make,
      quoteAdapter,
      $rootScope,
      disableInteractives,
      isPrintMode,
      clientViewComponents,
      usingFeature,
    ) {
      var canMoveSectionDown,
        canMoveSectionUp,
        counterToLetters,
        createSectionView,
        deleteSection,
        init,
        intervalId,
        isMobile,
        isUsingReactQuoteBlock,
        lastCalculatedTotals,
        recalculateTotals,
        refreshViews,
        sectionViews,
        selectSection,
        swapSections,
        totalsView;
      isUsingReactQuoteBlock = usingFeature("reactQuoteBlock");
      sectionViews = [];
      isMobile = null;
      totalsView = make(TotalsView, {
        isEditor: isEditor,
      });
      intervalId = null;
      lastCalculatedTotals = null;
      init = (function (_this) {
        return function () {
          refreshViews(true);
          $(window).on("resize", function () {
            return refreshViews();
          });
          return (intervalId = setInterval(recalculateTotals, 500));
        };
      })(this);

      /*
        Rebuild and refresh views if we have changed from a mobile to non-mobile screen state
        (and vice-versa)
        @param skipDigest [Bool] We don't force a digest cycle when first initialising the app,
        as not everything is ready yet
       */
      refreshViews = (function (_this) {
        return function (skipDigest) {
          var originalValue;
          if (skipDigest == null) {
            skipDigest = false;
          }
          quoteLabelEventEmitter.removeAllListeners();
          originalValue = isMobile;
          isMobile = window.innerWidth <= QUOTE_MOBILE_BREAKPOINT && !isPrintMode;
          if (isMobile !== originalValue) {
            _this.ui.isMobile = isMobile;
            _this.forceRefreshViews(skipDigest);
            return totalsView.updateIsMobile(isMobile);
          }
        };
      })(this);
      this.forceRefreshViews = (function (_this) {
        return function (skipDigest) {
          var idx, j, len, ref, sectionAdapter;
          if (skipDigest == null) {
            skipDigest = false;
          }
          sectionViews = [];
          ref = quoteAdapter.getSectionAdapters();
          for (idx = j = 0, len = ref.length; j < len; idx = ++j) {
            sectionAdapter = ref[idx];
            createSectionView(sectionAdapter, idx);
          }
          _this.assignSectionViewIds();
          if (!skipDigest) {
            return $rootScope.$apply();
          }
        };
      })(this);
      deleteSection = (function (_this) {
        return function (sectionView) {
          var index;
          if (quoteAdapter.sectionCount() > 1) {
            index = _.findIndex(sectionViews, sectionView);
            if (index >= 0) {
              quoteAdapter.deleteSection(index);
              sectionViews.splice(index, 1);
            }
          }
          return _this.assignSectionViewIds();
        };
      })(this);
      selectSection = (function (_this) {
        return function (sectionView, selected) {
          var err, index;
          index = _.findIndex(sectionViews, sectionView);
          if (index >= 0) {
            try {
              return quoteAdapter.selectSection(index, selected);
            } catch (error) {
              err = error;
              return console.error("TODO: handle section select error:", err);
            }
          }
        };
      })(this);
      createSectionView = (function (_this) {
        return function (sectionAdapter, index) {
          var view;
          view = make(SectionView, {
            quoteView: _this,
            sectionAdapter: sectionAdapter,
            deleteSection: deleteSection,
            selectSection: selectSection,
            canMoveSectionUp: canMoveSectionUp,
            canMoveSectionDown: canMoveSectionDown,
            isMobile: isMobile,
          });
          return sectionViews.splice(index, 0, view);
        };
      })(this);
      canMoveSectionUp = (function (_this) {
        return function (sectionView) {
          var index;
          index = _.findIndex(sectionViews, sectionView);
          return index !== 0;
        };
      })(this);
      canMoveSectionDown = (function (_this) {
        return function (sectionView) {
          var index;
          index = _.findIndex(sectionViews, sectionView);
          return index !== sectionViews.length - 1;
        };
      })(this);

      /*
        Move a section up one place, if it is not already the top section
       */
      this.moveSectionUp = (function (_this) {
        return function (sectionView) {
          var index;
          index = _.findIndex(sectionViews, sectionView);
          if (index > 0) {
            return swapSections(index, index - 1);
          }
        };
      })(this);

      /*
        Clone a section and insert it below the cloned section
       */
      this.cloneSection = (function (_this) {
        return function (sectionView) {
          var clonedSectionAdapter, index;
          index = _.findIndex(sectionViews, sectionView);
          clonedSectionAdapter = quoteAdapter.cloneSection(index);
          createSectionView(clonedSectionAdapter, index);
          return _this.assignSectionViewIds();
        };
      })(this);

      /*
        Move an section down one place, if it is not already the bottom section
       */
      this.moveSectionDown = (function (_this) {
        return function (sectionView) {
          var index;
          index = _.findIndex(sectionViews, sectionView);
          if (index < sectionViews.length - 1) {
            return swapSections(index, index + 1);
          }
        };
      })(this);
      swapSections = (function (_this) {
        return function (indexA, indexB) {
          var sectionToMove;
          quoteAdapter.swapSections(indexA, indexB);
          sectionToMove = sectionViews[indexA];
          sectionViews[indexA] = sectionViews[indexB];
          sectionViews[indexB] = sectionToMove;
          return _this.assignSectionViewIds();
        };
      })(this);
      counterToLetters = function (num) {
        var BASE, digitChar, digitValue, letterString;
        letterString = "";
        BASE = 26;
        while (true) {
          digitValue = (num % BASE) + 1;
          num = (num - digitValue) / BASE;
          digitChar = String.fromCharCode(64 + digitValue);
          letterString = "" + digitChar + letterString;
          if (num <= 0) {
            break;
          }
        }
        return letterString;
      };
      this.assignSectionViewIds = (function (_this) {
        return function () {
          var index, j, len, results, sectionId, sectionView, useNumbers;
          useNumbers = quoteAdapter.getSelectionType() === "combined";
          results = [];
          for (index = j = 0, len = sectionViews.length; j < len; index = ++j) {
            sectionView = sectionViews[index];
            if (useNumbers) {
              sectionId = index + 1;
            } else {
              sectionId = counterToLetters(index);
            }
            results.push(sectionView.setSectionId(sectionId));
          }
          return results;
        };
      })(this);
      lastCalculatedTotals = quoteAdapter.calculateTotals();
      recalculateTotals = (function (_this) {
        return function () {
          var newTotals;
          newTotals = quoteAdapter.calculateTotals();
          if (!isEqual(lastCalculatedTotals, newTotals)) {
            lastCalculatedTotals = newTotals;
            return $rootScope.$apply();
          }
        };
      })(this);
      this.ui = createUi(QuoteContentTemplate, {
        isEditor: isEditor,
        totalsUi: totalsView.ui,
        isMobile: null,
        getSections: (function (_this) {
          return function () {
            if (quoteAdapter.hasMismatchedLengths()) {
              _this.forceRefreshViews(isUsingReactQuoteBlock);
            }
            return sectionViews;
          };
        })(this),
        getSectionCount: function () {
          return sectionViews.length;
        },
        getSectionClasses: (function (_this) {
          return function (i) {
            var obj;
            return (
              (obj = {
                singleSection: _this.ui.isSingleSection(),
                "project-block__widget-element": true,
              }),
              (obj["project-block__widget-element--" + i] = true),
              obj
            );
          };
        })(this),
        createSection: (function (_this) {
          return function (prevSection) {
            var index, newSectionAdapter;
            index = _.findIndex(sectionViews, prevSection) + 1;
            newSectionAdapter = quoteAdapter.createSection(index);
            createSectionView(newSectionAdapter, index);
            return _this.assignSectionViewIds();
          };
        })(this),
        showQuoteTotals: function () {
          return quoteAdapter.showQuoteTotals();
        },
        isSingleSection: function () {
          return quoteAdapter.sectionCount() === 1;
        },
        $onElementRendered: function (element) {
          return element.on("$destroy", function () {
            return clearInterval(intervalId);
          });
        },
      });
      init();
    }

    return QuoteContent;
  })();
}.call(this));
