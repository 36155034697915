// Generated by CoffeeScript 1.12.7

/*
  External Video Background

  This class takes a single non-injected url parameter and renders it as a
  video.

  It should be able to handle multiple player implementations, but right now
  the only one is YouTube.
 */

(function () {
  module.exports = function (invoke) {
    var VideoBackground, players;
    players = [invoke(require("./YouTube.js"))];
    return (VideoBackground = (function () {
      function VideoBackground(createUi, make, $q, url) {
        this.ui = createUi(require("./VideoBackground.html"), {
          url: url,
        });
        this.el = $q(
          (function (_this) {
            return function (resolve, reject) {
              return (_this.ui.$onElementRendered = function (els) {
                if (els[0]) {
                  return resolve(els[0]);
                } else {
                  return reject(new Error("no dom element"));
                }
              });
            };
          })(this),
        );
        this.player = $q(
          (function (_this) {
            return function (resolve, reject) {
              var i, len, player;
              for (i = 0, len = players.length; i < len; i++) {
                player = players[i];
                if (player.match.test(url)) {
                  return resolve(player(_this.el, url));
                }
              }
              return reject(new Error("couldn't find matching player for url"));
            };
          })(this),
        );
      }

      return VideoBackground;
    })());
  };
}.call(this));
