"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteComponents = exports.DragDropHandlerIndicatorPosition = exports.DragDropHandlerDirection = void 0;
var DIProvider_1 = require("DIProvider/DIProvider");
var DragDropHandlerDirection;
(function (DragDropHandlerDirection) {
    DragDropHandlerDirection["Horizontal"] = "horizontal";
    DragDropHandlerDirection["Vertical"] = "vertical";
})(DragDropHandlerDirection || (exports.DragDropHandlerDirection = DragDropHandlerDirection = {}));
var DragDropHandlerIndicatorPosition;
(function (DragDropHandlerIndicatorPosition) {
    DragDropHandlerIndicatorPosition["Before"] = "before";
    DragDropHandlerIndicatorPosition["After"] = "after";
})(DragDropHandlerIndicatorPosition || (exports.DragDropHandlerIndicatorPosition = DragDropHandlerIndicatorPosition = {}));
exports.QuoteComponents = (0, DIProvider_1.makeServiceIdentifier)(Symbol("QuoteComponents"));
