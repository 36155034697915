"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimationSpeed = exports.AnimationDirection = exports.AnimationType = exports.AnimationOption = exports.TableBorderType = exports.isFontTextElementType = exports.cloneIColorObjectType = exports.ListMarkerType = exports.FontTypeface = exports.FontVendor = exports.TextElementType = exports.BlockType = exports.UpdateType = exports.AlignmentTypes = exports.TintBlendModeTypes = exports.OptionLabelTypes = void 0;
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
Object.defineProperty(exports, "BlockType", { enumerable: true, get: function () { return BlockTypes_1.BlockType; } });
var OptionLabelTypes;
(function (OptionLabelTypes) {
    OptionLabelTypes["Small"] = "small";
    OptionLabelTypes["Medium"] = "medium";
    OptionLabelTypes["Large"] = "large";
})(OptionLabelTypes || (exports.OptionLabelTypes = OptionLabelTypes = {}));
var TintBlendModeTypes;
(function (TintBlendModeTypes) {
    TintBlendModeTypes["Normal"] = "normal";
    TintBlendModeTypes["Blend"] = "blend";
})(TintBlendModeTypes || (exports.TintBlendModeTypes = TintBlendModeTypes = {}));
var AlignmentTypes;
(function (AlignmentTypes) {
    AlignmentTypes["Left"] = "left";
    AlignmentTypes["Center"] = "center";
})(AlignmentTypes || (exports.AlignmentTypes = AlignmentTypes = {}));
var UpdateType;
(function (UpdateType) {
    UpdateType["All"] = "all";
    UpdateType["New"] = "moving-forward";
})(UpdateType || (exports.UpdateType = UpdateType = {}));
var TextElementType;
(function (TextElementType) {
    TextElementType["H1"] = "h1";
    TextElementType["H2"] = "h2";
    TextElementType["P"] = "p";
    TextElementType["Blockquote"] = "blockQuote";
    TextElementType["UnorderedListItem"] = "list";
    TextElementType["OrderedListItem"] = "orderedList";
})(TextElementType || (exports.TextElementType = TextElementType = {}));
var FontVendor;
(function (FontVendor) {
    FontVendor["Google"] = "GoogleFonts";
    FontVendor["Typekit"] = "Typekit";
    FontVendor["Custom"] = "HostedWebfont";
})(FontVendor || (exports.FontVendor = FontVendor = {}));
var FontTypeface;
(function (FontTypeface) {
    FontTypeface["Serif"] = "serif";
    FontTypeface["SansSerif"] = "sans-serif";
    FontTypeface["Monospace"] = "monospace";
})(FontTypeface || (exports.FontTypeface = FontTypeface = {}));
var ListMarkerType;
(function (ListMarkerType) {
    ListMarkerType["Circle"] = "circle";
    ListMarkerType["Square"] = "square";
    ListMarkerType["Dash"] = "dash";
})(ListMarkerType || (exports.ListMarkerType = ListMarkerType = {}));
var cloneIColorObjectType = function (color) {
    return __assign({}, color);
};
exports.cloneIColorObjectType = cloneIColorObjectType;
var isFontTextElementType = function (type) {
    return [TextElementType.H1, TextElementType.H2, TextElementType.P].includes(type);
};
exports.isFontTextElementType = isFontTextElementType;
var TableBorderType;
(function (TableBorderType) {
    TableBorderType["Horizontal"] = "horizontal";
    TableBorderType["All"] = "all";
})(TableBorderType || (exports.TableBorderType = TableBorderType = {}));
var AnimationOption;
(function (AnimationOption) {
    AnimationOption["None"] = "none";
    AnimationOption["Section"] = "section";
    AnimationOption["Together"] = "together";
})(AnimationOption || (exports.AnimationOption = AnimationOption = {}));
var AnimationType;
(function (AnimationType) {
    AnimationType["Fade"] = "fade";
    AnimationType["Slide"] = "slide";
})(AnimationType || (exports.AnimationType = AnimationType = {}));
var AnimationDirection;
(function (AnimationDirection) {
    AnimationDirection["Left"] = "left";
    AnimationDirection["Right"] = "right";
    AnimationDirection["Up"] = "up";
    AnimationDirection["Down"] = "down";
})(AnimationDirection || (exports.AnimationDirection = AnimationDirection = {}));
var AnimationSpeed;
(function (AnimationSpeed) {
    AnimationSpeed["Slow"] = "slow";
    AnimationSpeed["Medium"] = "medium";
    AnimationSpeed["Fast"] = "fast";
})(AnimationSpeed || (exports.AnimationSpeed = AnimationSpeed = {}));
(function (AnimationOption) {
    AnimationOption["NONE"] = "none";
    AnimationOption["SECTION"] = "section";
    AnimationOption["TOGETHER"] = "together";
})(AnimationOption || (exports.AnimationOption = AnimationOption = {}));
(function (AnimationType) {
    AnimationType["FADE"] = "fade";
    AnimationType["SLIDE"] = "slide";
})(AnimationType || (exports.AnimationType = AnimationType = {}));
(function (AnimationDirection) {
    AnimationDirection["LEFT"] = "left";
    AnimationDirection["RIGHT"] = "right";
    AnimationDirection["UP"] = "up";
    AnimationDirection["DOWN"] = "down";
})(AnimationDirection || (exports.AnimationDirection = AnimationDirection = {}));
(function (AnimationSpeed) {
    AnimationSpeed["SLOW"] = "slow";
    AnimationSpeed["MEDIUM"] = "medium";
    AnimationSpeed["FAST"] = "fast";
})(AnimationSpeed || (exports.AnimationSpeed = AnimationSpeed = {}));
