// Generated by CoffeeScript 1.12.7
(function () {
  var Slider;

  module.exports = Slider = (function () {
    function Slider(createUi, defaultValue, readout, onChange, getMin, getMax) {
      this.ui = createUi(require("./Slider.html"), {
        value: defaultValue,
        showReadOut: false,
        readout: readout,
        onChange: (function (_this) {
          return function () {
            if (readout != null) {
              _this.ui.showReadout = true;
            }
            _this.ui.value = parseFloat(_this.ui.value);
            return onChange(_this.ui.value);
          };
        })(this),
        percentageFilled: (function (_this) {
          return function () {
            var percent, range;
            range = getMax() - getMin();
            if (range > 0) {
              percent = (_this.ui.value - getMin()) / range;
              return percent * 100 + "%";
            } else {
              return "0";
            }
          };
        })(this),
        min: function () {
          return getMin();
        },
        max: function () {
          return getMax();
        },
      });
    }

    return Slider;
  })();
}.call(this));
