import "../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css\",\"source\":\"Ll8xOXpydnJ0MCB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xOXpydnJ0MSB7CiAgbWFyZ2luLWxlZnQ6IDUwJTsKICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoLTUwJSk7CiAgd2lkdGg6IDEwMCU7CiAgbWF4LXdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9Ci5fMTl6cnZydDYgewogIG1hcmdpbi1sZWZ0OiA1MCU7CiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogIHdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDExNDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDExNSU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDE0NDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDEzMyU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLl8xOXpydnJ0NSB7CiAgICBtYXJnaW4tbGVmdDogNTAlOwogICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogICAgd2lkdGg6IHZhcigtLXBhZ2UtY29udGVudC13aWR0aCk7CiAgfQp9\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9WT3Y6bMBCF7/MUU1UrQRWnZLtJto5U7ZtEBk9guvinxs3PVrx7BUsCJJD0ohftFeCDzxyPv4mNPH60jrSH2WapaEFFBL8mAEq4lDTzxnJYLO1h3a7FxnujTsvlJL72WNQekgqbiyOHbY61QSoshyQXygaRPUxhJ1zAWLNnHk4hEXkSzKPoAT5D0FOfQmAwD8Ow8rFCStIp7xs81prIKdWMPKriQv8SjqRd1WmrkBc7lrWjIs32JH3GIaq+98ZJFjsUrxzqB6tWKiU72gx1wUH89KYqNotzk7yyxGiP2tdlqndBGh3TQiGHptTzuqf5o0UOpHPSyAp6w9Ho3+AT35IrPEsyyuXV5UXwgZQ1zgvtO70bEm9UyMVQgRMJYzWG9XLyolCSgCJxiBqElhB0mrxaPttDWNfpMzlM5QiXAGXHYNEYXCH596C8jeU9MPtxV03cUSavqbzF5RWZl9WGILqN0U2QhuwvCLrL0D2K3mu8nEfmPEg9lqITSV0UusFm1pnvmHhWT+pm8z6wmKNC7bk2PpiREilWhin6zaawIqlSFRmpKcx2JNGMiahilIPiKVTVhsMp7DyKkh/7dSO0iw/r8791y3Lc+vYmO4KjNOspZXPy+dc3t3P+se3BxY3/Cy0Z7IkiHbQDyGD5ZA/htGlV+CenbMH77w9ZTsrfEQEbo7EHAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var spacingShim = '_6mi5is0';
export var layoutGap = 'var(--_6mi5is1)';
export var layoutPadding = 'var(--_6mi5is2)';
export var layoutAlignment = 'var(--_6mi5is3)';
export var layoutColumnCount = 'var(--_6mi5is4)';
export var layout = '_6mi5is5';
export var columnWidth = 'var(--_6mi5is6)';
export var layoutColumn = '_6mi5is7';
export var blockContentContainer = '_6mi5is8';