// Generated by CoffeeScript 1.12.7
(function () {
  var ItemRateInput, RateTemplate;

  RateTemplate = require("./ItemRateInput.html");

  module.exports = ItemRateInput = (function () {
    function ItemRateInput(createUi, quoteCurrencyFormatter, rate) {
      this.ui = createUi(RateTemplate, {
        currencySymbol: function () {
          return quoteCurrencyFormatter.getSymbol();
        },
        rate: rate,
      });
    }

    return ItemRateInput;
  })();
}.call(this));
