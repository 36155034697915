// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function ($timeout, $tooltip) {
    return function (message, tooltipElemSelector, config, hideTimeout) {
      var createdTooltip, createdTooltipTimeout;
      if (config == null) {
        config = {};
      }
      config.title = message;
      config.trigger = "manual";
      createdTooltip = $tooltip($(tooltipElemSelector), config);
      createdTooltip.$promise.then(function () {
        createdTooltip.show();
        return $timeout(function () {
          return createdTooltip.$applyPlacement();
        });
      });
      if (hideTimeout != null && hideTimeout > 0) {
        createdTooltipTimeout = $timeout(function () {
          return createdTooltip.hide();
        }, hideTimeout);
      }
      return {
        tooltip: createdTooltip,
        hideTimeout: createdTooltipTimeout,
      };
    };
  };
}.call(this));
