"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstContentOfType = exports.sortByDisplayOrder = exports.hasRecurringTotals = void 0;
var Recurrence_1 = require("@CommonFrontendBackend/QuoteV2/Recurrence");
var lodash_1 = require("lodash");
var hasRecurringTotals = function (total) {
    return total.recurringTotals && !(0, lodash_1.isEmpty)(total.recurringTotals);
};
exports.hasRecurringTotals = hasRecurringTotals;
var sortByDisplayOrder = function (totals) {
    return (0, lodash_1.sortBy)((0, lodash_1.filter)(totals, function (total) {
        if (totals.length > 1) {
            return total.preTax !== 0;
        }
        return true;
    }), function (s) { return (0, lodash_1.indexOf)(Recurrence_1.recurringFrequencyDisplayOrder, s.frequency); });
};
exports.sortByDisplayOrder = sortByDisplayOrder;
var getFirstContentOfType = function (tokens, allowedTypes) {
    var e_1, _a;
    var collectText = false;
    var tokensToReturn = [];
    try {
        for (var tokens_1 = __values(tokens), tokens_1_1 = tokens_1.next(); !tokens_1_1.done; tokens_1_1 = tokens_1.next()) {
            var token = tokens_1_1.value;
            if (token.type === "block") {
                if (collectText) {
                    break;
                }
                else {
                    if (allowedTypes.includes(token.subType)) {
                        tokensToReturn.push(token);
                        collectText = true;
                    }
                }
            }
            if (collectText && token.subType === "text") {
                tokensToReturn.push(token);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (tokens_1_1 && !tokens_1_1.done && (_a = tokens_1.return)) _a.call(tokens_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return tokensToReturn;
};
exports.getFirstContentOfType = getFirstContentOfType;
