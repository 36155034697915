"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIntlCurrency = void 0;
function parseIntlCurrency(formattedCurrency, locale, currency) {
    var parts = new Intl.NumberFormat(locale, { style: "currency", currency: currency }).formatToParts(11111.11);
    var currencyPart = parts.find(function (d) { return d.type === "currency"; });
    var groupPart = parts.find(function (d) { return d.type === "group"; });
    var decimalPart = parts.find(function (d) { return d.type === "decimal"; });
    var normalisedString = formattedCurrency;
    if (currencyPart) {
        normalisedString = normalisedString.replace(currencyPart.value, "");
    }
    if (groupPart) {
        normalisedString = normalisedString.replace(new RegExp("[".concat(groupPart.value, "]"), "g"), "");
    }
    if (decimalPart) {
        normalisedString = normalisedString.replace(decimalPart.value, ".");
    }
    return Number(normalisedString);
}
exports.parseIntlCurrency = parseIntlCurrency;
