import "../CommonFrontend/ContentTokens/SharedWidgetComponents/QuoteSummary/QuoteSummaryWidget.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/ContentTokens/SharedWidgetComponents/QuoteSummary/QuoteSummaryWidget.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71XUXPiNhB+51fspJMxzJ2MjSEBZ+i0velb+9zHjGwJoyBLPlnGph3+e0cyJLYxCUeYexG2rP129enT7uI++4LiYrHw4b8BQMmIXofge9790wAgkopQhWLJOc5yGsLxyXzUOOIUcbyThQ5hxSpKngb7gfuKGAqphyFNM70bwRdw265SrBImkJZZCFushgjlGY6ZSFBV8ednP+KPLy++n406oBbPIhCWZxzvQhBS0NaqSdNDJLWWaddJ/uaCt10EnzGetiNLFCOGK/OLNE0zjjU1fBapyENQNKNYD6dfwV8pCxRJsvslU0xoOIdZ865k+Q5wIXKqW4HNLEiGCWEiOWG8saHNCLzG2dvz8bMKcskZOdjFkkuF1hQbsFdTOmrYHYm73LQR7IMNVtNKI8xZIkLINVbtDT2erKGiLcC5XWEZqokJwYcxzM7xPG9egVl9A1rWp6wu+oXYh77oO8WYcv705jTGPB7OvHsYQ9BmBJ9ez8bX6BrVBe+rLrqF6mKbA9xYCs1EQcnIgnYkMrlGIqQP6SqxrX70Zmyp0izG/Ki7COeUs04KSs5J+NUVUixZ68tTy/pDwbN+wU87R6zVyal0ID5Bq1ZunnHWENKLhZRmf3oXGgrNqvMhvNzsZDe3qml992Nzki0anvmPaaphmV6Q+kS/EgBKqQgqFc5CiBTFG2QmWqbyZuRmVxaUTzn9fgE76np28nf31OhK5Ai8n1dDg6wCIouI0wtsV1JoVNJmbjFT/9gZFElOXg2CTpHRF7BbXM/u9s2U0FgqrJkUIZjUifRaySJZt9Z7zWL0+lhCwX/lLIzoSipqIU0WoUKHcHf31KxZTFjsiMt406iyk2lWmdf1gaTj+4rTKgQPPMCFljZTy5zVQSrKsWZb2pf/U0YIt18iHG8SJQtBEEtxQkMoFB/eEaxxaCfG+Tb5UqX8qdCr+df74Fu+TaBKuciXzlrrLByPy7J0y8CVKhlPPM8zFk4d+dKZTJ1D1PXzltHyD1ktHRP0ZAqTqXMf/HkffMuwXsOKcb50VBINgxn4AfiTYOTYWaQKTpcO3VIhCXGALJ2//YX76D3C3Ix/+R74c3fqTzmamYl6BN9MITvaJTN3Nn/gc3eyCJAd6xX1+K8zroMxWzBP3u93Vm/dTHrxOWuFRb6SKg0hjzGnQ8+dWc03/0ugB3Oa+8FvKSUMQx4rSgVgQWCYMoEOGnj0vKyqu5FOn32u5/mo6wHYN8HmB7B2O9nfUHZMF/1xHHvFc91iByW65W7YAex89B83FZ+oAJ1oNr3c7gf7/wEoCvrszg4AAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = '_1neau990';
export var table = '_1neau991';
export var quoteHeading = '_1neau992';
export var sectionDescription = '_1neau993';
export var colHeadingRow = '_1neau994';
export var colHeading = _7a468({defaultClassName:'_1neau995',variantClassNames:{align:{left:'_1neau996',right:'_1neau997'},isFirstColumn:{true:'_1neau998',false:'_1neau999'}},defaultVariants:{align:'left',isFirstColumn:false},compoundVariants:[]});
export var section = '_1neau99a';
export var lineItem = _7a468({defaultClassName:'_1neau99b',variantClassNames:{isLastRow:{true:'_1neau99c'}},defaultVariants:{isLastRow:false},compoundVariants:[]});
export var quoteTotal = '_1neau99d';
export var totalLine = '_1neau99e';
export var cell = _7a468({defaultClassName:'_1neau99f',variantClassNames:{align:{left:'_1neau99g',right:'_1neau99h'},isFirstColumn:{true:'_1neau99i',false:'_1neau99j'}},defaultVariants:{align:'left',isFirstColumn:false},compoundVariants:[]});
export var total = '_1neau99k';
export var totalCell = _7a468({defaultClassName:'_1neau99l',variantClassNames:{align:{left:'_1neau99m',right:'_1neau99n'},isLastRow:{true:'_1neau99o'}},defaultVariants:{align:'left',isLastRow:false},compoundVariants:[]});
export var subTotalCell = _7a468({defaultClassName:'_1neau99p',variantClassNames:{align:{left:'_1neau99q',right:'_1neau99r'}},defaultVariants:{align:'left'},compoundVariants:[]});
export var grandTotalCell = _7a468({defaultClassName:'_1neau99s',variantClassNames:{align:{left:'_1neau99t',right:'_1neau99u'}},defaultVariants:{align:'left'},compoundVariants:[]});
export var preDiscountValue = '_1neau99v';
export var lineItemFeatureList = '_1neau99w';
export var discountText = '_1neau99x';
export var grandTotalValue = '_1neau99y';