"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tintBlendModeExclusionColors = exports.minHeightOptions = exports.tintBlendModeOptions = exports.splashBlockSpacingOptions = void 0;
var commonTypes_1 = require("./commonTypes");
exports.splashBlockSpacingOptions = [
    {
        label: "1/3",
        value: "small",
    },
    {
        label: "1/2",
        value: "medium",
    },
    {
        label: "Full",
        value: "large",
    },
];
exports.tintBlendModeOptions = [
    {
        label: "Regular",
        value: commonTypes_1.TintBlendModeTypes.Normal,
    },
    {
        label: "Blend",
        value: commonTypes_1.TintBlendModeTypes.Blend,
    },
];
exports.minHeightOptions = {
    small: "33vh",
    medium: "65vh",
    large: "100vh",
};
exports.tintBlendModeExclusionColors = ["rgba(255,255,255,1)", "rgba(74,74,74,1)"];
