"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.esignatureDataToDataUrl = exports.esignatureDataFromImage = void 0;
var esignatureDataFromImage = function (esignatureImage) {
    var splitPosition = esignatureImage.indexOf(",") + 1;
    return esignatureImage.substring(splitPosition);
};
exports.esignatureDataFromImage = esignatureDataFromImage;
var esignatureDataToDataUrl = function (esignatureData) {
    return "data:image/png;base64,".concat(esignatureData);
};
exports.esignatureDataToDataUrl = esignatureDataToDataUrl;
