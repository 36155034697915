"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
function singleLineString(optionsOrStrings) {
    var values = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        values[_i - 1] = arguments[_i];
    }
    var separator = " ";
    var replaceNewlines = function (strings) {
        var values = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            values[_i - 1] = arguments[_i];
        }
        var output = "";
        for (var i = 0; i < values.length; i++) {
            output += strings[i] + values[i];
        }
        output += strings[values.length];
        var lines = output.split(/(?:\r\n|\n|\r)/);
        return lines
            .map(function (line) { return line.trim(); })
            .filter(function (line) { return line.length !== 0; })
            .join(separator);
    };
    if ("separator" in optionsOrStrings) {
        separator = optionsOrStrings.separator;
        return replaceNewlines;
    }
    else {
        return replaceNewlines.apply(void 0, __spreadArray([optionsOrStrings], __read(values), false));
    }
}
exports.default = singleLineString;
