"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMediaVariableToken = exports.mediaVariable = exports.Media = void 0;
var Models_1 = require("ContentTokens/Models");
var Media;
(function (Media) {
    Media["Image"] = "image";
    Media["Video"] = "video";
    Media["GoogleMaps"] = "googleMaps";
    Media["Iframe"] = "iframe";
})(Media || (exports.Media = Media = {}));
exports.mediaVariable = {
    createToken: function (media, name, label, options) {
        if (options === void 0) { options = {}; }
        var token = {
            type: "block",
            subType: Models_1.BlockType.MediaVariable,
            media: media,
            name: name,
            label: label,
        };
        var width = options.width, alignment = options.alignment, height = options.height;
        if (width) {
            token.width = width;
        }
        if (height) {
            token.height = height;
        }
        if (alignment) {
            token.alignment = alignment;
        }
        return token;
    },
    createSlateJson: function () {
        throw new Error("Not implemented");
    },
};
var isMediaVariableToken = function (token) {
    return token.type === "block" && token.subType === Models_1.BlockType.MediaVariable;
};
exports.isMediaVariableToken = isMediaVariableToken;
