"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var GetCustomClass_1 = require("../GetCustomClass");
exports.default = (function (Tooltip, make, di, createUi) {
    var buildHint = function (type) {
        return function (options) {
            var targetSelector = options.targetSelector, hint = options.hint, position = options.position, spacing = options.spacing;
            type = type || "small";
            var hideAfter = type === "small" ? 50 : 500;
            var tooltipOptions = __assign({ showOnHover: true, customTheme: "hint hint--".concat(type), position: position || "top", customClass: (0, GetCustomClass_1.getCustomClass)(position), spacing: spacing != null ? spacing : 5, hideAfter: hideAfter, holdHoverFor: 100 }, getContent(hint));
            return make(Tooltip, {
                targetSelector: targetSelector,
                options: tooltipOptions,
            });
        };
    };
    var getContent = function (hint) {
        if (typeof hint === "string") {
            return {
                stringContent: hint,
            };
        }
        else {
            return {
                contents: createUi(require("./HintInner.html"), {
                    hint: hint,
                }),
            };
        }
    };
    return {
        small: buildHint("small"),
        large: buildHint("large"),
    };
});
