"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayNow = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_1 = __importStar(require("react"));
var StripeContext_1 = require("../StripeContext");
var styles = __importStar(require("./PayNow.css"));
var react_final_form_1 = require("react-final-form");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var AcceptFlowContextProvider_1 = require("@CommonFrontend/AcceptFlow/AcceptFlowContextProvider");
var utils_1 = require("@qwilr/kaleidoscope/utils");
var Utils_1 = require("../Utils");
var PayNow = function (_a) {
    var payNowConfiguration = _a.payNowConfiguration, getShouldSkipOptionalPayment = _a.getShouldSkipOptionalPayment, _b = _a.shouldCaptureEmail, shouldCaptureEmail = _b === void 0 ? false : _b;
    var _c = (0, AcceptFlowContextProvider_1.useAcceptFlowContext)(), isPreviewAccept = _c.isPreviewAccept, settings = _c.settings, autofillEnabled = _c.autofillEnabled;
    var amountFormatted = payNowConfiguration.amountFormatted, isOptionalPayment = payNowConfiguration.isOptionalPayment, isPartialPayment = payNowConfiguration.isPartialPayment, currencyCode = payNowConfiguration.currencyCode, partialPaymentPercentage = payNowConfiguration.partialPaymentPercentage;
    var _d = (0, react_final_form_1.useForm)(), change = _d.change, getState = _d.getState;
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
            react_1.default.createElement("div", { onClick: function () {
                    if (autofillEnabled) {
                        console.log("Cannot auto-fill pay now details. Please use a test card from Stripe: https://stripe.com/docs/testing#cards");
                        var values = getState().values;
                        if (!values["cardName"]) {
                            change("cardName", (0, utils_1.randomString)(10));
                        }
                        if (shouldCaptureEmail && !values["email_for_card"]) {
                            change("email_for_card", "".concat((0, utils_1.randomString)(10), "@testing.com"));
                        }
                        if (!values["cardZip"]) {
                            change("cardZip", "1234");
                        }
                    }
                } },
                react_1.default.createElement(kaleidoscope_1.Heading, { level: "3", align: "center" }, "Payment")),
            react_1.default.createElement(kaleidoscope_1.Text, { align: "center", element: "div" }, "Enter card details for payment of the amount below. The card will not be charged until the project is accepted by all required."),
            isPreviewAccept && (react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Preview mode", alignment: kaleidoscope_1.AlertAlignment.Center, className: styles.previewAlert },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", align: "center" }, "This is preview mode, please don't enter any real credit card info."))),
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "end" },
                react_1.default.createElement(kaleidoscope_1.Stack, null,
                    isOptionalPayment && react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" }, "Optional payment"),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "l", strong: true }, "Total")),
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.totalRightSide },
                    isPartialPayment && (react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" },
                        "Part payment ",
                        partialPaymentPercentage ? "(".concat(partialPaymentPercentage, "%)") : "")),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "l", strong: true },
                        amountFormatted,
                        " ",
                        currencyCode))),
            react_1.default.createElement(StripeElements, { getShouldSkipOptionalPayment: function (formValues) { return getShouldSkipOptionalPayment(formValues, settings.payNow); }, shouldCaptureEmail: shouldCaptureEmail }))));
};
exports.PayNow = PayNow;
var stripeFieldInitialValue = { isEmpty: true };
var StripeElements = function (_a) {
    var _b, _c, _d;
    var getShouldSkipOptionalPayment = _a.getShouldSkipOptionalPayment, shouldCaptureEmail = _a.shouldCaptureEmail;
    var cardNumberRef = (0, react_1.useRef)(null);
    var cardExpiryRef = (0, react_1.useRef)(null);
    var cardCvcRef = (0, react_1.useRef)(null);
    var _e = (0, StripeContext_1.useStripeEnabledContext)(), stripe = _e.stripe, cardElementRef = _e.cardElementRef;
    var _f = __read((0, react_1.useState)(false), 2), stripeElementsReady = _f[0], setStripeElementsReady = _f[1];
    var _g = __read((0, react_1.useState)(), 2), cardNumberElement = _g[0], setCardNumberElement = _g[1];
    var _h = __read((0, react_1.useState)(), 2), cardExpiryElement = _h[0], setCardExpiryElement = _h[1];
    var _j = __read((0, react_1.useState)(), 2), cardCvcElement = _j[0], setCardCvcElement = _j[1];
    var isSimpleMode = (0, AcceptFlowContextProvider_1.useAcceptFlowContext)().isSimpleMode;
    var cardNumberField = (0, react_final_form_1.useField)("cardNumber", {
        validate: function (value) {
            if (!getShouldSkipOptionalPayment(formValues) && value.isEmpty) {
                return "Card number cannot be empty";
            }
            if (value.error) {
                return value.error;
            }
        },
        initialValue: stripeFieldInitialValue,
    });
    var cardExpiryField = (0, react_final_form_1.useField)("cardExpiry", {
        validate: function (value) {
            if (!getShouldSkipOptionalPayment(formValues) && value.isEmpty) {
                return "Expiry cannot be empty";
            }
            if (value.error) {
                return value.error;
            }
        },
        initialValue: stripeFieldInitialValue,
    });
    var cardCvcField = (0, react_final_form_1.useField)("cardCvc", {
        validate: function (value) {
            if (!getShouldSkipOptionalPayment(formValues) && value.isEmpty) {
                return "CVC cannot be empty";
            }
            if (value.error) {
                return value.error;
            }
        },
        initialValue: stripeFieldInitialValue,
    });
    var formContext = (0, react_1.useContext)(kaleidoscope_1.FormContext);
    (0, react_1.useEffect)(function () {
        if (!stripe) {
            return;
        }
        var elements = stripe.elements({
            fonts: [
                {
                    cssSrc: "https://use.typekit.net/mgh4sbk.css",
                },
            ],
        });
        var elementReadyPromises = [];
        var createStripeComponent = function (elementType, mountRef, placeholder) {
            var _a;
            var options = { placeholder: placeholder, style: ELEMENTS_APPEARANCE };
            var element = elements.create(elementType, options);
            element.mount(mountRef.current);
            var elementReadyPromise = new Promise(function (resolve) {
                element.on("ready", resolve);
            });
            (_a = mountRef.current) === null || _a === void 0 ? void 0 : _a.removeAttribute("tabindex");
            elementReadyPromises.push(elementReadyPromise);
            return element;
        };
        var cardNumberElem = createStripeComponent("cardNumber", cardNumberRef, "1234 1234 1234 1234");
        var cardExpiryElem = createStripeComponent("cardExpiry", cardExpiryRef, "MM / YY");
        var cardCvcElem = createStripeComponent("cardCvc", cardCvcRef, "CVC");
        cardElementRef.current = cardNumberElem;
        setCardNumberElement(cardNumberElem);
        setCardExpiryElement(cardExpiryElem);
        setCardCvcElement(cardCvcElem);
        Promise.all(elementReadyPromises).then(function () {
            setStripeElementsReady(true);
        });
        return function () {
            cardElementRef.current = undefined;
            cardNumberElem.unmount();
            cardExpiryElem.unmount();
            cardCvcElem.unmount();
            setCardNumberElement(undefined);
            setCardCvcElement(undefined);
            setCardExpiryElement(undefined);
        };
    }, [cardElementRef, stripe]);
    useAttachStripeElementListeners(cardExpiryElement, cardExpiryField);
    useAttachStripeElementListeners(cardCvcElement, cardCvcField);
    useAttachStripeElementListeners(cardNumberElement, cardNumberField);
    var formValues = (0, react_final_form_1.useFormState)().values;
    var cardExpiryError = ((_b = cardExpiryField.meta.data) === null || _b === void 0 ? void 0 : _b.showValidationErrors) ? cardExpiryField.meta.error : undefined;
    var cardCvcError = ((_c = cardCvcField.meta.data) === null || _c === void 0 ? void 0 : _c.showValidationErrors) ? cardCvcField.meta.error : undefined;
    return (react_1.default.createElement("div", { "data-testid": "stripe-elements-wrapper", "data-stripe-ready": stripeElementsReady },
        !stripe && react_1.default.createElement(kaleidoscope_1.Spinner, null),
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "l", className: styles.payNowFormWrapper({
                readyToView: !!stripe,
            }) },
            react_1.default.createElement(kaleidoscope_1.FormTextInput, { className: Utils_1.acceptFormFocusableClassname, name: "cardName", label: "Name on card", placeholder: "Firstname Lastname", validate: function (value) {
                    if (!getShouldSkipOptionalPayment(formValues) && !value) {
                        return "Name on card cannot be empty";
                    }
                } }),
            react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "cardName", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators }),
            shouldCaptureEmail && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(kaleidoscope_1.FormTextInput, { className: Utils_1.acceptFormFocusableClassname, name: "email_for_card", label: "Email", placeholder: "Email", type: kaleidoscope_1.TextInputType.Email, validate: function (value) {
                        if (!value) {
                            return "Email cannot be empty";
                        }
                    } }),
                react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "email_for_card", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators }))),
            react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs" },
                react_1.default.createElement(kaleidoscope_1.Label, { size: "s", className: styles.label }, "Card details"),
                react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
                    react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs" },
                        react_1.default.createElement("div", { className: styles.stripeElementWrapper({ styleMode: isSimpleMode ? "simple" : "standard" }), ref: cardNumberRef }),
                        react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "cardNumber", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators }),
                        react_1.default.createElement(MaybeStripeInputError, { error: ((_d = cardNumberField.meta.data) === null || _d === void 0 ? void 0 : _d.showValidationErrors) ? cardNumberField.meta.error : undefined })),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", justify: "spaceBetween" },
                        react_1.default.createElement("div", { className: styles.splitElementWrapper },
                            react_1.default.createElement(kaleidoscope_1.Tooltip, { size: kaleidoscope_1.TooltipSize.Large, show: !!cardExpiryError, content: cardExpiryError, "aria-live": "polite" },
                                react_1.default.createElement("div", { className: styles.stripeElementWrapper({ styleMode: isSimpleMode ? "simple" : "standard" }), ref: cardExpiryRef })),
                            react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "cardExpiry", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators })),
                        react_1.default.createElement("div", { className: styles.splitElementWrapper },
                            react_1.default.createElement(kaleidoscope_1.Tooltip, { size: kaleidoscope_1.TooltipSize.Large, show: !!cardCvcError, content: cardCvcError, "aria-live": "polite" },
                                react_1.default.createElement("div", { className: styles.stripeElementWrapper({ styleMode: isSimpleMode ? "simple" : "standard" }), ref: cardCvcRef })),
                            react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "cardCvc", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators }))))),
            react_1.default.createElement(kaleidoscope_1.FormTextInput, { className: Utils_1.acceptFormFocusableClassname, name: "cardZip", label: "Zip / post code", validate: function (zip) {
                    if (!getShouldSkipOptionalPayment(formValues) && !zip) {
                        return "Zip / post code cannot be empty";
                    }
                }, placeholder: "1234" }),
            react_1.default.createElement(kaleidoscope_1.ValidationSpy, { name: "cardZip", mutators: formContext === null || formContext === void 0 ? void 0 : formContext.mutators }))));
};
var MaybeStripeInputError = function (_a) {
    var error = _a.error;
    return error ? (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "xxs" },
        react_1.default.createElement("div", { className: styles.stripeInputErrorIcon },
            react_1.default.createElement(icons_1.Warning, { size: icons_1.IconSize.Small })),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "s", element: "div", className: styles.stripeInputErrorText, "aria-live": "polite" }, error))) : null;
};
var useAttachStripeElementListeners = function (stripeElement, field) {
    var _a = field.input, onBlur = _a.onBlur, onFocus = _a.onFocus, onChange = _a.onChange;
    (0, react_1.useEffect)(function () {
        if (!stripeElement) {
            return;
        }
        var handleBlur = function () { return onBlur(); };
        var handleFocus = function () { return onFocus(); };
        var handleChange = function (event) {
            var _a;
            onChange({ isEmpty: event.empty, error: (_a = event.error) === null || _a === void 0 ? void 0 : _a.message });
        };
        stripeElement.on("blur", handleBlur);
        stripeElement.on("focus", handleFocus);
        stripeElement.on("change", handleChange);
        return function () {
            stripeElement.off("change", handleChange);
            stripeElement.off("blur", handleBlur);
            stripeElement.off("focus", handleFocus);
        };
    }, [stripeElement, onBlur, onFocus, onChange]);
};
var ELEMENTS_APPEARANCE = {
    base: {
        fontFamily: '"proxima-nova", sans-serif',
        fontSize: "20px",
        fontWeight: 400,
        color: "#242c39",
        "::placeholder": {
            color: "rgba(129, 162, 178, 0.5)",
        },
    },
};
