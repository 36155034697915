"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var clickHandlers = new Map();
document.addEventListener("click", function (event) {
    clickHandlers.forEach(function (handler) {
        handler(event);
    });
});
var OnClickOutside = (function () {
    function OnClickOutside(ignoreElems, onClickOutside, $timeout, uniqueId) {
        this.onClickOutside = onClickOutside;
        this.$timeout = $timeout;
        this.handlerId = uniqueId();
        this.ignoreElemsSelectors = ignoreElems.join(",");
        this.startListening();
    }
    OnClickOutside.prototype.stopListening = function () {
        clickHandlers.delete(this.handlerId);
    };
    OnClickOutside.prototype.startListening = function () {
        var _this = this;
        clickHandlers.set(this.handlerId, function ($event) {
            if (_this.outsideIgnorableElems($event.target)) {
                return _this.$timeout(function () {
                    _this.stopListening();
                    return _this.onClickOutside($event);
                });
            }
        });
    };
    OnClickOutside.prototype.outsideIgnorableElems = function (target) {
        target = $(target);
        return !target.is(this.ignoreElemsSelectors) && target.parents(this.ignoreElemsSelectors).length === 0;
    };
    return OnClickOutside;
}());
exports.default = OnClickOutside;
