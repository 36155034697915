"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recurringFrequencyDisplayOrder = exports.mapRecurringFrequencyToStaticPerLabel = exports.mapRecurringFrequencyToPerLabel = exports.mapRecurringFrequencyToAdjectiveLabel = exports.createEmptyOneOffBillingSchedule = void 0;
var Types_1 = require("./Types");
var createEmptyOneOffBillingSchedule = function () {
    return {
        type: Types_1.BillingScheduleType.OneOff,
    };
};
exports.createEmptyOneOffBillingSchedule = createEmptyOneOffBillingSchedule;
var mapRecurringFrequencyToAdjectiveLabel = function (frequency) {
    var _a;
    return (_a = {},
        _a[Types_1.RecurringFrequency.Weekly] = Types_1.QuoteLabel.WeekAdjective,
        _a[Types_1.RecurringFrequency.Monthly] = Types_1.QuoteLabel.MonthAdjective,
        _a[Types_1.RecurringFrequency.Quarterly] = Types_1.QuoteLabel.QuarterAdjective,
        _a[Types_1.RecurringFrequency.Yearly] = Types_1.QuoteLabel.YearAdjective,
        _a)[frequency];
};
exports.mapRecurringFrequencyToAdjectiveLabel = mapRecurringFrequencyToAdjectiveLabel;
var mapRecurringFrequencyToPerLabel = function (frequency) {
    var _a;
    return (_a = {},
        _a[Types_1.RecurringFrequency.Weekly] = Types_1.QuoteLabel.WeekNoun,
        _a[Types_1.RecurringFrequency.Monthly] = Types_1.QuoteLabel.MonthNoun,
        _a[Types_1.RecurringFrequency.Quarterly] = Types_1.QuoteLabel.QuarterNoun,
        _a[Types_1.RecurringFrequency.Yearly] = Types_1.QuoteLabel.YearNoun,
        _a)[frequency];
};
exports.mapRecurringFrequencyToPerLabel = mapRecurringFrequencyToPerLabel;
var mapRecurringFrequencyToStaticPerLabel = function (frequency) {
    var _a;
    return (_a = {},
        _a[Types_1.RecurringFrequency.Weekly] = "week",
        _a[Types_1.RecurringFrequency.Monthly] = "month",
        _a[Types_1.RecurringFrequency.Quarterly] = "quarter",
        _a[Types_1.RecurringFrequency.Yearly] = "year",
        _a)[frequency];
};
exports.mapRecurringFrequencyToStaticPerLabel = mapRecurringFrequencyToStaticPerLabel;
exports.recurringFrequencyDisplayOrder = Object.freeze([
    null,
    Types_1.RecurringFrequency.Weekly,
    Types_1.RecurringFrequency.Monthly,
    Types_1.RecurringFrequency.Quarterly,
    Types_1.RecurringFrequency.Yearly,
]);
