// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (createUi, block, currency) {
    var base, base1, base2, base3, base4, base5;
    if ((base = block.settings).customLabels == null) {
      base.customLabels = {};
    }
    if ((base1 = block.settings.customLabels).total == null) {
      base1.total = "Total";
    }
    if ((base2 = block.settings.customLabels).subtotal == null) {
      base2.subtotal = "Subtotal";
    }
    if ((base3 = block.settings.customLabels).excludingTax == null) {
      base3.excludingTax = "Ex. Tax";
    }
    if ((base4 = block.settings.customLabels).per == null) {
      base4.per = "per";
    }
    if ((base5 = block.settings.customLabels).discount == null) {
      base5.discount = "Discount";
    }
    return (this.ui = createUi(require("Quote/Display/Totals/Totals.html"), {
      block: block,
      currency: function () {
        return block.settings.currency;
      },
      taxes: function () {
        return block.taxes;
      },
      config: block.settings.displayConfig,
      labels: block.settings.customLabels,
    }));
  };
}.call(this));
