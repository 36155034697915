// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function () {
    return {
      enabled: false,
      type: "fixed",
      units: 0,
    };
  };
}.call(this));
