// Generated by CoffeeScript 1.12.7
(function () {
  var OptionSelect, Template;

  Template = require("./OptionSelect.html");

  /*
  The optional line item selection button
   */

  module.exports = OptionSelect = (function () {
    function OptionSelect(createUi, itemAdapter, disableInteractives, block) {
      var updateTicked;
      updateTicked = (function (_this) {
        return function () {
          return (_this.ui.ticked = itemAdapter.isOptionalSelected());
        };
      })(this);
      this.ui = createUi(Template, {
        disableInteractives: disableInteractives,
        ticked: null,
        onClick: function () {
          if (!disableInteractives) {
            itemAdapter.setOptionalSelected(!itemAdapter.isOptionalSelected());
            return updateTicked();
          }
        },
        isSelected: function () {
          return itemAdapter.isOptionalSelected();
        },
        isOptional: function () {
          return itemAdapter.isOptional();
        },
      });
      updateTicked();
    }

    return OptionSelect;
  })();
}.call(this));
