// Generated by CoffeeScript 1.12.7
(function () {
  var Tooltip, TooltipTemplate, calculatePosition, getOffsets, repositionTooltip;

  TooltipTemplate = require("./Tooltip.html");

  getOffsets = require("./GetElementOffsets");

  module.exports = Tooltip = (function () {
    function Tooltip(di, $timeout, $interval, $compile, $rootScope, createUi, targetSelector, options, $q) {
      /*
      We create the ui lazily on the first show().
      @NOTE (som, 2018-01-29): otherwise, when there are many Tooltips (such as in the quote block
      line items), there is a noticable loading time if createUi is called on all the Tooltips at once
       */
      var OnClickOutside,
        addedTooltip,
        closeOnClickAway,
        containerSelector,
        currentlyShowing,
        hideAfter,
        hideAfterTimeout,
        holdHoverFor,
        hoverHide,
        hoverShow,
        ignoreElems,
        initUi,
        leavingTimeout,
        onHide,
        onShow,
        position,
        removedTooltip,
        showOnHover,
        showingTimeout,
        spacing,
        targetLastExtent,
        targetMoved,
        targetWatch,
        tooltipHtmlElement;
      this.ui = null;
      containerSelector = options.container || "body";
      hideAfter = options.hideAfter;
      position = options.position || "top";
      spacing = options.spacing || 0;
      closeOnClickAway = options.closeOnClickAway || false;
      showOnHover = options.showOnHover || false;
      holdHoverFor = options.holdHoverFor || 0;
      tooltipHtmlElement = null;
      addedTooltip = false;
      removedTooltip = false;
      currentlyShowing = false;
      onShow = null;
      onHide = null;
      targetLastExtent = null;
      if (closeOnClickAway && di.has("OnClickOutside")) {
        ignoreElems = [".q-tooltip"];
        if (options.ignoreClickAwayElems != null) {
          ignoreElems.push.apply(ignoreElems, options.ignoreClickAwayElems);
        }
        OnClickOutside = di.get("OnClickOutside");
        this.onClickOutside = di.make(OnClickOutside, {
          ignoreElems: ignoreElems,
          onClickOutside: (function (_this) {
            return function () {
              return _this.hide();
            };
          })(this),
        });
        this.onClickOutside.stopListening();
      }
      targetWatch = null;
      hideAfterTimeout = null;
      initUi = (function (_this) {
        return function () {
          return new Promise(function (resolve) {
            var compiledTooltip, templateElem, tooltipScope;
            _this.ui = createUi(TooltipTemplate, {
              customClass: options.customClass,
              customTheme: options.customTheme || "default-theme",
              hasComplexContents: options.contents != null,
              contents: options.contents,
              simpleContent: options.stringContent,
              position: position,
            });
            tooltipScope = $rootScope.$new(false);
            tooltipScope.include = function () {
              return _this.ui.$templateUrl;
            };
            tooltipScope.ui = _this.ui;
            templateElem = '<ng-include src="include()"></ng-include>';
            compiledTooltip = $compile(templateElem)(tooltipScope);
            return $timeout(function () {
              tooltipHtmlElement = compiledTooltip[0].nextElementSibling.childNodes[0];
              tooltipHtmlElement.style.setProperty("opacity", 0);
              if (showOnHover) {
                $(tooltipHtmlElement).on("mouseenter", hoverShow).on("mouseleave", hoverHide);
              }
              return resolve();
            });
          });
        };
      })(this);
      this.show = (function (_this) {
        return function (overrideHideAfter) {
          if (overrideHideAfter == null) {
            overrideHideAfter = false;
          }
          return $q
            .resolve()
            .then(function () {
              if (_this.ui == null && !removedTooltip) {
                return initUi();
              }
            })
            .then(function () {
              return $timeout(function () {
                var container, ref;
                if (typeof onShow === "function") {
                  onShow();
                }
                if (!addedTooltip) {
                  container = $(containerSelector);
                  container.append(tooltipHtmlElement);
                  addedTooltip = true;
                }
                tooltipHtmlElement.style.removeProperty("display");
                $timeout(function () {
                  return tooltipHtmlElement.style.setProperty("opacity", 1);
                });
                currentlyShowing = true;
                if ((ref = _this.onClickOutside) != null) {
                  ref.startListening();
                }
                _this.reposition();
                _this.cancelTimers();
                targetWatch = $interval(function () {
                  if (targetMoved()) {
                    return _this.reposition();
                  }
                }, 200);
                window.addEventListener("resize", _this.reposition);
                if (hideAfter != null && !overrideHideAfter) {
                  return (hideAfterTimeout = $timeout(_this.hide, hideAfter));
                }
              });
            });
        };
      })(this);
      this.remove = function () {
        if ((tooltipHtmlElement != null ? tooltipHtmlElement.parentElement : void 0) != null) {
          tooltipHtmlElement.parentElement.removeChild(tooltipHtmlElement);
        }
        return (removedTooltip = true);
      };
      this.isCurrentlyShowing = function () {
        return currentlyShowing;
      };
      this.reposition = (function (_this) {
        return function () {
          var target;
          target = $(targetSelector);
          repositionTooltip(target, tooltipHtmlElement, $(containerSelector), position, spacing);
          return (targetLastExtent = getOffsets(target));
        };
      })(this);
      this.hide = (function (_this) {
        return function (hideQuickly) {
          var ref;
          if (typeof onHide === "function") {
            onHide();
          }
          currentlyShowing = false;
          if (tooltipHtmlElement != null) {
            if (hideQuickly) {
              tooltipHtmlElement.style.setProperty("display", "none");
            } else {
              tooltipHtmlElement.style.setProperty("opacity", 0);
              $timeout(function () {
                return tooltipHtmlElement.style.setProperty("display", "none");
              }, 200);
            }
          }
          _this.cancelTimers();
          return (ref = _this.onClickOutside) != null ? ref.stopListening() : void 0;
        };
      })(this);
      this.hideQuickly = (function (_this) {
        return function () {
          return _this.hide(true);
        };
      })(this);
      this.cancelTimers = function () {
        window.removeEventListener("resize", this.reposition);
        if (targetWatch != null) {
          $interval.cancel(targetWatch);
          targetWatch = null;
        }
        if (hideAfterTimeout != null) {
          $timeout.cancel(hideAfterTimeout);
          return (hideAfterTimeout = null);
        }
      };
      this.setOnShow = function (func) {
        return (onShow = func);
      };
      this.setOnHide = function (func) {
        return (onHide = func);
      };

      /*
      Check whether the target element has moved since the last time the Tooltip was repositioned
       */
      targetMoved = (function (_this) {
        return function () {
          var currentExtent, key, target, val;
          target = $(targetSelector);
          if (target.length === 0) {
            _this.hide();
            return false;
          }
          if (targetLastExtent == null) {
            return true;
          }
          currentExtent = getOffsets(target);
          for (key in currentExtent) {
            val = currentExtent[key];
            if (val !== targetLastExtent[key]) {
              return true;
            }
          }
          return false;
        };
      })(this);
      if (showOnHover) {
        $timeout(
          (function (_this) {
            return function () {
              return $(targetSelector).on("mouseenter", hoverShow).on("mouseleave", hoverHide);
            };
          })(this),
        );
      }
      leavingTimeout = null;
      showingTimeout = null;
      hoverShow = (function (_this) {
        return function () {
          if (leavingTimeout != null) {
            $timeout.cancel(leavingTimeout);
          }
          if (removedTooltip) {
            return;
          }
          return (showingTimeout = $timeout(function () {
            if (!_this.isCurrentlyShowing()) {
              return _this.show(true);
            }
          }, holdHoverFor));
        };
      })(this);
      hoverHide = (function (_this) {
        return function () {
          if (leavingTimeout != null) {
            $timeout.cancel(leavingTimeout);
          }
          if (showingTimeout != null) {
            $timeout.cancel(showingTimeout);
          }
          if (!_this.isCurrentlyShowing()) {
            return;
          }
          return (leavingTimeout = $timeout(
            function () {
              _this.hide();
              return (leavingTimeout = null);
            },
            hideAfter != null ? hideAfter : 250,
          ));
        };
      })(this);
    }

    return Tooltip;
  })();

  calculatePosition = require("./TooltipPositioning");

  repositionTooltip = function (targetElement, tooltipElement, containerElement, position, spacing) {
    var arrowInset, arrowOutline, positioning, propertyName, ref, ref1, ref2, results, value;
    positioning = calculatePosition(targetElement, tooltipElement, containerElement, position, spacing);
    ref = positioning.tooltipPosition;
    for (propertyName in ref) {
      position = ref[propertyName];
      tooltipElement.style.setProperty(propertyName, position + "px");
    }
    arrowOutline = $(tooltipElement).find(".q-tooltip-arrow.q-tooltip-arrow-outline");
    ref1 = positioning.arrowOutlinePosition;
    for (propertyName in ref1) {
      value = ref1[propertyName];
      arrowOutline[0].style.setProperty(propertyName, value + "px");
    }
    arrowInset = $(tooltipElement).find(".q-tooltip-arrow.q-tooltip-arrow-inset");
    ref2 = positioning.arrowInsetPosition;
    results = [];
    for (propertyName in ref2) {
      value = ref2[propertyName];
      results.push(arrowInset[0].style.setProperty(propertyName, value + "px"));
    }
    return results;
  };
}.call(this));
