// Generated by CoffeeScript 1.12.7

/*
  View for single quote item
 */

(function () {
  var QuoteItem;

  module.exports = QuoteItem = (function () {
    function QuoteItem(
      useEditorControls,
      isMobile,
      createUi,
      componentUis,
      itemAdapter,
      quoteAdapter,
      itemViewId,
      invoke,
      discountService,
      block,
    ) {
      var formatCurrency;
      formatCurrency = invoke(require("Utils/CurrencyDecimalFormatter"));
      this.settingsButton = null;
      this.insertButton = null;
      this.addSettingsButton = (function (_this) {
        return function (settingsButton) {
          return (_this.ui.settingsButtonUi = settingsButton.ui);
        };
      })(this);
      this.addInsertButton = (function (_this) {
        return function (insertButton) {
          return (_this.ui.insertButtonUi = insertButton.ui);
        };
      })(this);
      this.refreshQuantity = (function (_this) {
        return function () {
          var ref;
          return (ref = _this.ui.quantity) != null
            ? typeof ref.updateFromAdapter === "function"
              ? ref.updateFromAdapter()
              : void 0
            : void 0;
        };
      })(this);
      this.ui = createUi(require("./QuoteItem.html"), {
        useEditorControls: useEditorControls,
        isMobile: isMobile,
        description: componentUis.description,
        itemRate: componentUis.itemRate,
        quantity: componentUis.quantity,
        unitName: componentUis.unitName,
        cost: componentUis.cost,
        discount: componentUis.discount,
        preDiscount: function () {
          return formatCurrency(itemAdapter.preDiscount());
        },
        optionSelect: componentUis.optionSelect,
        uniqueId: itemViewId,
        insertButtonUi: null,
        settingsButtonUi: null,
        getDiscountLabel: function () {
          return quoteAdapter.labelForField("discount");
        },
        getFormattedDiscount: function () {
          var model, savedDiscount;
          model = itemAdapter.getDiscountAdapter().getModel();
          if (!model.id) {
            if (model.type === "fixed") {
              return formatCurrency(model.units);
            } else {
              return model.units + "%";
            }
          }
          savedDiscount = discountService.findDiscountById(model.id);
          if (!!savedDiscount) {
            if (savedDiscount.type === "fixed") {
              return formatCurrency(savedDiscount.amount);
            }
            return savedDiscount.amount + "%";
          }
        },
        showDiscountLabel: function () {
          var ref;
          return (
            ((ref = itemAdapter.getDiscountAdapter()) != null ? ref.hasNonZeroDiscount() : void 0) &&
            (useEditorControls || itemAdapter.showCost())
          );
        },
        showQuantityNumber: function () {
          if (!useEditorControls) {
            return itemAdapter.showQuantity();
          }
          return true;
        },
        showLineItem: function () {
          return itemAdapter.showLineItems();
        },
        showRate: function () {
          return itemAdapter.showRate();
        },
        showQuantity: function () {
          return itemAdapter.showQuantity();
        },
        showCost: function () {
          return itemAdapter.showCost();
        },
      });
    }

    return QuoteItem;
  })();
}.call(this));
