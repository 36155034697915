"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHeadingsFromBlock = exports.mergeAgreementOverrides = void 0;
var BlockTokenTraverser_1 = require("@CommonFrontendBackend/Blocks/BlockTokenTraverser");
var lodash_1 = require("lodash");
var mergeAgreementOverrides = function (acceptSettings, overrides) {
    var incomingSettings = (0, lodash_1.cloneDeep)(acceptSettings);
    if (incomingSettings.button) {
        incomingSettings.button.customLabel = overrides.buttonText;
    }
    else {
        incomingSettings.button = {
            custom: false,
            customLabel: overrides.buttonText,
            customCompletionText: "Congratulations! The quote has been accepted.",
        };
    }
    return incomingSettings;
};
exports.mergeAgreementOverrides = mergeAgreementOverrides;
var getHeadingsFromBlock = function (block) {
    var e_1, _a, e_2, _b;
    var headings = [];
    var blockTokenTraverser = new BlockTokenTraverser_1.BlockTokenTraverser();
    var collectText = false;
    var contentAreas = blockTokenTraverser.traverseBlockForTokens(block, { depth: 0 });
    try {
        for (var contentAreas_1 = __values(contentAreas), contentAreas_1_1 = contentAreas_1.next(); !contentAreas_1_1.done; contentAreas_1_1 = contentAreas_1.next()) {
            var contentArea = contentAreas_1_1.value;
            try {
                for (var contentArea_1 = (e_2 = void 0, __values(contentArea)), contentArea_1_1 = contentArea_1.next(); !contentArea_1_1.done; contentArea_1_1 = contentArea_1.next()) {
                    var token = contentArea_1_1.value;
                    if (token.type === "block") {
                        if (["heading1", "heading2"].includes(token.subType)) {
                            headings.push({ content: token.content, idx: headings.length });
                            collectText = true;
                        }
                        else {
                            collectText = false;
                        }
                    }
                    if (collectText && token.subType === "text") {
                        var previousItem = headings[headings.length - 1];
                        if (previousItem && token.content) {
                            headings[headings.length - 1].content = [previousItem.content, token.content].join("");
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (contentArea_1_1 && !contentArea_1_1.done && (_b = contentArea_1.return)) _b.call(contentArea_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (contentAreas_1_1 && !contentAreas_1_1.done && (_a = contentAreas_1.return)) _a.call(contentAreas_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return headings.filter(function (item) { var _a; return !!item && ((_a = item.content) === null || _a === void 0 ? void 0 : _a.length) > 0; });
};
exports.getHeadingsFromBlock = getHeadingsFromBlock;
