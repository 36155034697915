// Generated by CoffeeScript 1.12.7

/*
  YouTube player wrapper

  This is a function designed to make loading a YouTube background player
  easy. It ensures the YouTube API is loaded, parses out the id, loads the
  player via the API and attaches the event listeners we need to loop and mute
  the video.
 */

(function () {
  var getYoutubeId;

  getYoutubeId = require("./GetYoutubeId")["default"];

  module.exports = function ($q) {
    var api, isMobile, loadScript, mobileFallback, youtube;
    isMobile = navigator.userAgent.match(/Mobile/) || navigator.userAgent.match(/Android/);
    loadScript = function (src) {
      var tag;
      tag = document.createElement("script");
      tag.src = src;
      return document.head.appendChild(tag);
    };
    api = function () {
      if (window.YT) {
        return $q.resolve(window.YT);
      } else {
        return $q(function (resolve) {
          var existingCallback, newCallback;
          loadScript("https://www.youtube.com/iframe_api");
          existingCallback = window.onYouTubeIframeAPIReady;
          newCallback = function () {
            return resolve(window.YT);
          };
          return (window.onYouTubeIframeAPIReady = existingCallback
            ? function () {
                existingCallback();
                return newCallback();
              }
            : newCallback);
        });
      }
    };
    mobileFallback = function (_el, id) {
      return $q.resolve(_el).then(function (el) {
        var img;
        img = document.createElement("img");
        img.className = el.className;
        img.src = "https://img.youtube.com/vi/" + id + "/maxresdefault.jpg";
        return el.parentNode.replaceChild(img, el);
      });
    };
    youtube = function (_el, url) {
      var id;
      id = getYoutubeId(url);
      if (!id) {
        return $q.reject(new Error("failed to parse url"));
      }
      if (isMobile) {
        return mobileFallback(_el, id);
      }
      return $q.all([$q.resolve(_el), api()]).then(function (arg) {
        var YT, el;
        (el = arg[0]), (YT = arg[1]);
        return new YT.Player(el, {
          videoId: id,
          playerVars: {
            autoplay: 1,
            autohide: 1,
            rel: 0,
            showinfo: 0,
            controls: 0,
            disablekb: 1,
            enablejsapi: 0,
            iv_load_policy: 3,
            mute: 1,
          },
          events: {
            onStateChange: (function (_this) {
              return function (e) {
                var base;
                if (e.data === YT.PlayerState.ENDED) {
                  return typeof (base = e.target).playVideo === "function" ? base.playVideo() : void 0;
                }
              };
            })(this),
          },
        });
      });
    };
    youtube.name = "youtube";
    youtube.match = /^https?:\/\/((www\.)?youtube.com|youtu.be)\//;
    return youtube;
  };
}.call(this));
