// Generated by CoffeeScript 1.12.7

/*
  Convert an integer to a letter. If the number is greater than 26, it will continue with 2 letters
  e.g. AA, AB, AC...
 */

(function () {
  var getLetter;

  getLetter = function (num) {
    var letter;
    if (num >= 26) {
      letter = getLetter(((num / 26) >> 0) - 1);
    } else {
      letter = "";
    }
    return letter + String.fromCharCode((num % 26 >> 0) + 65);
  };

  module.exports = getLetter;
}.call(this));
