"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paperSizeOptions = exports.pageLayoutOptions = exports.pageOrientationOptions = exports.paperSizeControlOptions = exports.pageSizeUSPaper = exports.pageSizeA4 = exports.isOrientationOption = exports.Orientation = exports.isPageSizeOption = exports.PdfDownloadOrigin = exports.PageSize = exports.PdfPreviewOption = exports.PDF_PREVIEW_DONE = void 0;
exports.PDF_PREVIEW_DONE = "PDF_PREVIEW_FORMATTING_DONE";
var PORTRAIT_A4_WIDTH = 595;
var PORTRAIT_A4_HEIGHT = 842;
var PORTRAIT_LETTER_WIDTH = 612;
var PORTRAIT_LETTER_HEIGHT = 792;
var PdfPreviewOption;
(function (PdfPreviewOption) {
    PdfPreviewOption["INCLUDE_EMBED"] = "includeEmbeddedContent";
    PdfPreviewOption["INCLUDE_PAGE_NUMBERS"] = "includePageNumbers";
    PdfPreviewOption["INCLUDE_VIDEOS"] = "includeVideos";
    PdfPreviewOption["USE_COMPACT_LAYOUT"] = "useCompactLayout";
})(PdfPreviewOption || (exports.PdfPreviewOption = PdfPreviewOption = {}));
var PageSize;
(function (PageSize) {
    PageSize["A4"] = "A4";
    PageSize["USPaper"] = "USPAPER";
})(PageSize || (exports.PageSize = PageSize = {}));
var PdfDownloadOrigin;
(function (PdfDownloadOrigin) {
    PdfDownloadOrigin["Download"] = "download";
    PdfDownloadOrigin["Url"] = "url";
})(PdfDownloadOrigin || (exports.PdfDownloadOrigin = PdfDownloadOrigin = {}));
var isPageSizeOption = function (optionKey) {
    return Object.values(PageSize).includes(optionKey);
};
exports.isPageSizeOption = isPageSizeOption;
var Orientation;
(function (Orientation) {
    Orientation["Portrait"] = "portrait";
    Orientation["Landscape"] = "landscape";
})(Orientation || (exports.Orientation = Orientation = {}));
var isOrientationOption = function (optionKey) {
    return Object.values(Orientation).includes(optionKey);
};
exports.isOrientationOption = isOrientationOption;
exports.pageSizeA4 = {
    label: PageSize.A4,
    value: "a4",
    portrait: {
        width: PORTRAIT_A4_WIDTH,
        height: PORTRAIT_A4_HEIGHT,
    },
    landscape: {
        width: PORTRAIT_A4_HEIGHT,
        height: PORTRAIT_A4_WIDTH,
    },
};
exports.pageSizeUSPaper = {
    label: PageSize.USPaper,
    value: "us letter",
    portrait: {
        width: PORTRAIT_LETTER_WIDTH,
        height: PORTRAIT_LETTER_HEIGHT,
    },
    landscape: {
        width: PORTRAIT_LETTER_HEIGHT,
        height: PORTRAIT_LETTER_WIDTH,
    },
};
exports.paperSizeControlOptions = [
    {
        value: PageSize.A4,
        label: "A4",
    },
    {
        value: PageSize.USPaper,
        label: "US Letter",
    },
];
exports.pageOrientationOptions = [
    {
        value: Orientation.Portrait,
        label: "Portrait",
    },
    {
        value: Orientation.Landscape,
        label: "Landscape",
    },
];
exports.pageLayoutOptions = [
    {
        value: true,
        label: "Compact",
        tooltipMessage: "Fits more than one block per page",
    },
    {
        value: false,
        label: "Presentation",
        tooltipMessage: "Breaks each block to a new page",
    },
];
exports.paperSizeOptions = {
    A4: exports.pageSizeA4,
    USPAPER: exports.pageSizeUSPaper,
};
