"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignatureTypeSwitcher = void 0;
var ESignV3_1 = require("@CommonFrontend/AcceptFlow/ESign/ESignV3");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var utils_1 = require("@qwilr/kaleidoscope/utils");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("../Esignature.css"));
var getFriendlyTabName = function (name) {
    var _a;
    return (_a = {}, _a[ESignV3_1.SignatureTab.Draw] = "Draw", _a[ESignV3_1.SignatureTab.Type] = "Type", _a[ESignV3_1.SignatureTab.Upload] = "Upload", _a)[name];
};
var SignatureTypeSwitcher = function (_a) {
    var onChange = _a.onChange, currentType = _a.currentType, isSimpleMode = _a.isSimpleMode;
    var _b = __read((0, react_1.useState)(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var options = [
        ESignV3_1.SignatureTab.Type,
        ESignV3_1.SignatureTab.Draw,
        ESignV3_1.SignatureTab.Upload,
    ];
    var clickHandlerRef = (0, react_1.useRef)();
    clickHandlerRef.current = new utils_1.OnClickOutside([".signature-options__menu", ".signature-options__button"], function () {
        setIsOpen(false);
    });
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (isOpen) {
            (_a = clickHandlerRef.current) === null || _a === void 0 ? void 0 : _a.startListening();
        }
        else {
            (_b = clickHandlerRef.current) === null || _b === void 0 ? void 0 : _b.stopListening();
        }
        return function () {
            var _a;
            (_a = clickHandlerRef.current) === null || _a === void 0 ? void 0 : _a.stopListening();
        };
    }, [isOpen]);
    return (react_1.default.createElement(kaleidoscope_1.OptionMenu, { autoStack: true, size: "small", isOpen: isOpen, className: "signature-options__menu", button: react_1.default.createElement(kaleidoscope_1.Button, { decorator: kaleidoscope_1.ButtonDecorator.Dropdown, type: kaleidoscope_1.ButtonType.Tertiary, onClick: function (event) {
                event.preventDefault();
                setIsOpen(!isOpen);
            }, className: "signature-options__button" }, getFriendlyTabName(currentType)) }, options.map(function (optionItem) { return (react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { key: optionItem, selected: optionItem === currentType, className: styles.optionMenuItem({ isSimpleMode: isSimpleMode }), onClick: function (event) {
            event.preventDefault();
            onChange(optionItem);
            setIsOpen(false);
        } }, getFriendlyTabName(optionItem))); })));
};
exports.SignatureTypeSwitcher = SignatureTypeSwitcher;
