"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepeatingTokenInput = exports.TokenInput = exports.LiteralInput = exports.DataSource = void 0;
var DataSource;
(function (DataSource) {
    DataSource["Literal"] = "Literal";
    DataSource["Token"] = "Token";
})(DataSource || (exports.DataSource = DataSource = {}));
var LiteralInput = (function () {
    function LiteralInput(value) {
        this.dataSource = DataSource.Literal;
        this.value = value;
    }
    return LiteralInput;
}());
exports.LiteralInput = LiteralInput;
var TokenInput = (function () {
    function TokenInput(name, label, substitutionToken) {
        this.dataSource = DataSource.Token;
        this.name = name;
        this.label = label;
        this.substitutionToken = substitutionToken;
    }
    return TokenInput;
}());
exports.TokenInput = TokenInput;
var RepeatingTokenInput = (function (_super) {
    __extends(RepeatingTokenInput, _super);
    function RepeatingTokenInput(name, label, childObjectType, childPropertyLabel, substitutionToken) {
        var _this = _super.call(this, name, label, substitutionToken) || this;
        _this.childObjectType = childObjectType;
        _this.childPropertyLabel = childPropertyLabel;
        return _this;
    }
    return RepeatingTokenInput;
}(TokenInput));
exports.RepeatingTokenInput = RepeatingTokenInput;
