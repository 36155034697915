// Generated by CoffeeScript 1.12.7

/*
The <ranged-input> angular directive for sliders

There is a limitation with Webkit/Chrome that causes the slider thumb to not update it's position
when the min/max of the <input> changes. We get around this by defining our own angular directive

Relevent reading:
https://github.com/angular/angular.js/issues/6726
https://stackoverflow.com/questions/22534837/input-range-thumb-do-not-refreshes-after-changed-max-value
 */

(function () {
  module.exports = angular.module("angular-range", []).directive("rangedInput", function () {
    return {
      replace: true,
      restrict: "E",
      scope: {
        value: "=ngModel",
        min: "=rangeMin",
        max: "=rangeMax",
        step: "=rangeStep",
      },
      template: "<input type='range'/>",
      link: function (scope, iElement, iAttrs) {
        var read, setValue;
        scope.$watch("min", function () {
          return setValue();
        });
        scope.$watch("max", function () {
          return setValue();
        });
        scope.$watch("step", function () {
          return setValue();
        });
        scope.$watch("value", function () {
          return setValue();
        });
        setValue = function () {
          if (
            angular.isDefined(scope.min) &&
            angular.isDefined(scope.max) &&
            angular.isDefined(scope.step) &&
            angular.isDefined(scope.value)
          ) {
            iElement.attr("min", scope.min);
            iElement.attr("max", scope.max);
            iElement.attr("step", scope.step);
            return iElement.val(scope.value);
          }
        };
        read = function () {
          return (scope.value = iElement.val());
        };
        return iElement.on("change", function () {
          return scope.$apply(read);
        });
      },
    };
  });
}.call(this));
