// Generated by CoffeeScript 1.12.7

/*
  This module creates an overlay card, which is a item inside an overlay stack.

  The purpose of the "Card" abstraction is to allow for overlays to share their
  generic UI between all overlay cards, but also allow for configurable UI and events.
  i.e. different labels or icons for button, different functionality for events etc.

  @param contentUi [UI Object] - the UI to display in the overlay.

  @param options [Object] - a map of options for the overlay card, these can include:
    `className` [String] - custom CSS class(es) name (for specific styling of the overlay card)
    `hideCloseButton` [Boolean] Default: false - flag whether to show the close button or not.
    `centerContent` [Boolean] Default: true - flag to indicate if we should center the provided UI inside the overlay
    `onBackgroundClick` [Function] - a function to invoke when the background DOM is clicked.
    `onClose` [Function] - function to invoke when the overlay is closed.
    `confirmClose` [Function] - sometimes we want the ability to confirm closing an overlay,
        and cancel closing, under particular circumstances (i.e. unsaved data for example).
        the confirmClose function must return either a Boolean value or a Promise future.


  Usage Example:

    make OverlayStackCard,
      contentUi: @ui
      options:
        className: "block-renderer content-editor"
        confirmClose: =>
          if @ui.unsavedChanges
            return no
          else
            return yes
 */

(function () {
  var OverlayMode;

  OverlayMode = require("OverlayStack/OverlayMode").OverlayMode;

  module.exports = function (overlayStack, options, contentUi, createUi, noop, $q) {
    var closeOverlayCard;
    if (options.onClose == null) {
      options.onClose = noop;
    }
    closeOverlayCard = function () {
      return overlayStack.closeCurrent();
    };
    if (options.centerContent == null) {
      options.centerContent = true;
    }
    this.ui = createUi(require("./OverlayCard.html"), {
      content: contentUi,
      centerContent: options.centerContent,
      hideCloseButton: options.hideCloseButton || false,
      closeLabel: options.closeLabel,
      className: options.className || "",
      fullPage: options.fullPage || false,
      overlayMode: options.overlayMode || OverlayMode.Full,
      close: function () {
        var confirmClose;
        if (options.confirmClose != null) {
          confirmClose = options.confirmClose();
          if (confirmClose.then != null) {
            return confirmClose.then(function (result) {
              if (result === true) {
                return closeOverlayCard();
              }
            });
          } else {
            if (confirmClose === true) {
              return closeOverlayCard();
            }
          }
        } else {
          return closeOverlayCard();
        }
      },
    });
    this.usingCustomCloseButton = false;

    /*
      @HACK (dylan, 2017-12-20) For more styling flexibility we make the custom class string
      public we can apply the class to the Overlay Stack as well.
    
      We need to do this because, historically, all overlays had solid white backgrounds,
      whose DOM & CSS rules are defined in the OverlayStack - but since we are now wanting
      to introduce partially transparent overlay backgrounds, and gradients etc. we need to custom
      styling classes to define each of these situations - hence piggy backing the Card option.
    
      @TODO we should make a more elegant/cleaner way of defining special background classes,
      probably by removing the generic white background DOM and styling from the OverlayStack,
      and bringing this down to be an OverlayCard level property.
     */
    this.styleClass = options.className || "";

    /*
      @TODO: Preferably, I'd prefer to implement onClose() to be called
      when the DOM for the card is removed, rather than through
      the ui.close() function as above - because (ideally) overlays can be closed from
      within the card's content UI and from outside the card context as well.
      This implementation looks like:
    
        $onElementRendered: (elem) ->
          elem.on "$destroy", options.onClose
    
      However, this approach doesn't work out of the box because when a stack
      of overlays are open the scope $destroy event isn't called until the parent
      scope is destroyed - i.e. until the entire stack is closed. So onClose() isn't
      called for each individual card, but only when all overlays in a stack have closed.
     */
    return (this.onClose = options.onClose);
  };
}.call(this));
