"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseSectionModel = exports.QuoteItems = void 0;
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var lodash_1 = require("lodash");
var BillingScheduleOrchestrator_1 = require("QuoteV2/BillingSchedule/BillingScheduleOrchestrator");
var ItemTypes_1 = require("./ItemTypes");
var itemFactory = new ItemTypes_1.ItemFactory();
var billingSchedule = new BillingScheduleOrchestrator_1.BillingScheduleOrchestrator();
var DefaultQuoteItem = function () {
    return itemFactory.templateQuoteItem(Types_1.QuoteItemType.FixedCost);
};
var QuoteItems = function (items, defaultItem) {
    if (defaultItem === void 0) { defaultItem = DefaultQuoteItem(); }
    return !(0, lodash_1.isUndefined)(items) && !(0, lodash_1.isEmpty)(items) ? items : [defaultItem];
};
exports.QuoteItems = QuoteItems;
var BaseSectionModel = function (displayMode, items) {
    if (displayMode === void 0) { displayMode = Types_1.QuoteSectionDisplayMode.Table; }
    var isPlanCardSection = displayMode === Types_1.QuoteSectionDisplayMode.PlanCards;
    var defaultItem = DefaultQuoteItem();
    var showFeatureList = false;
    if (isPlanCardSection) {
        billingSchedule.setRecurringFrequency(defaultItem, Types_1.RecurringFrequency.Monthly);
        if (defaultItem.interactive) {
            defaultItem.interactive.isOptional = true;
        }
        showFeatureList = true;
    }
    return {
        description: {
            content: '<h2 placeholder="true"><br></h2>',
            widgets: {},
        },
        items: (0, exports.QuoteItems)(items, defaultItem),
        viewSettings: {
            itemsViewable: true,
            itemsCollapsed: false,
            showRateAndQuantity: true,
            showCost: true,
            showRate: true,
            showQuantity: true,
            showSubtotal: true,
            showFeatureList: showFeatureList,
            groupItemsByBillingSchedule: false,
        },
        discount: null,
        displayMode: displayMode,
        itemSingleSelect: isPlanCardSection ? true : false,
    };
};
exports.BaseSectionModel = BaseSectionModel;
