// Generated by CoffeeScript 1.12.7

/*
  The label that shows the discount applied to a section or quote total
  e.g "10% OFF"
 */

(function () {
  var DiscountLabel;

  module.exports = DiscountLabel = (function () {
    function DiscountLabel(createUi, invoke, discountAdapter, quoteAdapter, discountService, block) {
      var formatCurrency, formatDiscount;
      formatCurrency = invoke(require("Utils/CurrencyDecimalFormatter"));
      this.ui = createUi(
        require("./DiscountLabel.html"),
        {
          offLabelText: function () {
            return quoteAdapter.labelForField("percentageOff");
          },
          hasDiscount: function () {
            return discountAdapter.hasNonZeroDiscount();
          },
          getDiscount: function () {
            var model, savedDiscount;
            model = discountAdapter.getModel();
            if (!model.id) {
              return formatDiscount(model.units, model.type);
            } else {
              savedDiscount = discountService.findDiscountById(model.id);
              if (savedDiscount) {
                return formatDiscount(savedDiscount.amount, savedDiscount.type);
              }
            }
          },
        },
        (formatDiscount = (function (_this) {
          return function (units, type) {
            if (type === "fixed") {
              return formatCurrency(units);
            } else {
              return units + "%";
            }
          };
        })(this)),
      );
    }

    return DiscountLabel;
  })();
}.call(this));
