"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INTEGRATION_METADATA = exports.PLATFORM_SUPPORTED_PROVIDER_TYPES = exports.SpecialTokens = exports.PrimaryContactName = exports.PrimaryContactApiName = exports.ContactName = exports.ContactApiName = exports.CompanyName = exports.CompanyApiName = exports.ProductToken = exports.MAX_TOKEN_SUBSTITUTION_VALUE_LENGTH = exports.MAX_CUSTOM_TOKEN_NAME_LENGTH = exports.MAX_CUSTOM_TOKENS_PER_ACCOUNT = exports.isTokenListFilter = exports.TokenDefinitionType = exports.TokenDataType = exports.getReplacementForDeprecatedProviderType = exports.getCrmObjects = exports.ProviderType = exports.SalesforceObjectType = exports.HubspotObjectType = exports.TokenType = void 0;
var zod_1 = require("zod");
var TokenType;
(function (TokenType) {
    TokenType["Qwilr"] = "qwilr";
    TokenType["Custom"] = "custom";
    TokenType["Crm"] = "crm";
})(TokenType || (exports.TokenType = TokenType = {}));
var HubspotObjectType;
(function (HubspotObjectType) {
    HubspotObjectType["Company"] = "company";
    HubspotObjectType["Contact"] = "contact";
    HubspotObjectType["Deal"] = "deal";
    HubspotObjectType["LineItem"] = "lineItem";
})(HubspotObjectType || (exports.HubspotObjectType = HubspotObjectType = {}));
var SalesforceObjectType;
(function (SalesforceObjectType) {
    SalesforceObjectType["Contact"] = "contact";
    SalesforceObjectType["Account"] = "account";
    SalesforceObjectType["Lead"] = "lead";
    SalesforceObjectType["Opportunity"] = "opportunity";
    SalesforceObjectType["OpportunityLineItem"] = "opportunityLineItem";
})(SalesforceObjectType || (exports.SalesforceObjectType = SalesforceObjectType = {}));
var ProviderType;
(function (ProviderType) {
    ProviderType["Hubspot"] = "hubspot";
    ProviderType["Salesforce"] = "salesforce";
    ProviderType["Custom"] = "custom";
    ProviderType["Zoho"] = "zoho";
    ProviderType["Pipedrive"] = "pipedrive";
    ProviderType["LegitRamen"] = "legitRamen";
    ProviderType["Sfdc"] = "sfdc";
    ProviderType["Unknown"] = "unknown";
})(ProviderType || (exports.ProviderType = ProviderType = {}));
var getCrmObjects = function (crmProvider) {
    if (crmProvider === ProviderType.Hubspot) {
        return Object.values(HubspotObjectType);
    }
    else if (crmProvider === ProviderType.Salesforce || crmProvider === ProviderType.Sfdc) {
        return Object.values(SalesforceObjectType);
    }
    else {
        throw new Error("CRM Provider: ".concat(crmProvider, " is not supported"));
    }
};
exports.getCrmObjects = getCrmObjects;
var getReplacementForDeprecatedProviderType = function (providerType) {
    if (providerType === ProviderType.Sfdc) {
        return ProviderType.Salesforce;
    }
    return providerType;
};
exports.getReplacementForDeprecatedProviderType = getReplacementForDeprecatedProviderType;
var TokenDataType;
(function (TokenDataType) {
    TokenDataType["String"] = "string";
    TokenDataType["Number"] = "number";
    TokenDataType["Object"] = "object";
    TokenDataType["List"] = "list";
    TokenDataType["ProductList"] = "list<product>";
})(TokenDataType || (exports.TokenDataType = TokenDataType = {}));
var TokenDefinitionType;
(function (TokenDefinitionType) {
    TokenDefinitionType["Standard"] = "standard";
    TokenDefinitionType["Custom"] = "custom";
})(TokenDefinitionType || (exports.TokenDefinitionType = TokenDefinitionType = {}));
var TokenListFilterSchema = zod_1.z.object({
    providerType: zod_1.z.string().min(1),
    objectType: zod_1.z.string().optional(),
    query: zod_1.z.string().optional(),
    start: zod_1.z.number().optional(),
    limit: zod_1.z.number().optional(),
    parentId: zod_1.z.string().optional(),
    excludeReadOnly: zod_1.z.boolean().optional(),
});
var isTokenListFilter = function (data) {
    var parsedData = TokenListFilterSchema.safeParse(data);
    return parsedData.success;
};
exports.isTokenListFilter = isTokenListFilter;
exports.MAX_CUSTOM_TOKENS_PER_ACCOUNT = 1000;
exports.MAX_CUSTOM_TOKEN_NAME_LENGTH = 50;
exports.MAX_TOKEN_SUBSTITUTION_VALUE_LENGTH = 128 * 1024;
exports.ProductToken = "products";
exports.CompanyApiName = "company";
exports.CompanyName = "Company";
exports.ContactApiName = "contact";
exports.ContactName = "Contact";
exports.PrimaryContactApiName = "primaryContact";
exports.PrimaryContactName = "Primary Contact";
exports.SpecialTokens = [exports.ProductToken];
exports.PLATFORM_SUPPORTED_PROVIDER_TYPES = new Set([
    ProviderType.Zoho,
    ProviderType.Pipedrive,
    ProviderType.LegitRamen,
]);
exports.INTEGRATION_METADATA = new Map([
    [ProviderType.Zoho, { displayName: "Zoho CRM", hasCustomFields: true }],
    [ProviderType.Pipedrive, { displayName: "Pipedrive", hasCustomFields: false }],
    [ProviderType.Hubspot, { displayName: "HubSpot", hasCustomFields: false }],
    [ProviderType.Salesforce, { displayName: "SalesForce", hasCustomFields: false }],
]);
