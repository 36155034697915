"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageStateChangeSource = exports.EngagementTriggerType = exports.ProjectSubStatus = exports.isPageLocked = exports.ProjectActions = exports.ProjectStatus = void 0;
var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["Draft"] = "";
    ProjectStatus["Live"] = "live";
    ProjectStatus["Disabled"] = "disabled";
    ProjectStatus["Accepted"] = "accepted";
    ProjectStatus["Declined"] = "declined";
})(ProjectStatus || (exports.ProjectStatus = ProjectStatus = {}));
var ProjectActions;
(function (ProjectActions) {
    ProjectActions["Delete"] = "delete";
    ProjectActions["Edit"] = "edit";
    ProjectActions["PublicPageView"] = "publicPageView";
})(ProjectActions || (exports.ProjectActions = ProjectActions = {}));
var isPageLocked = function (status) {
    return [ProjectStatus.Accepted, ProjectStatus.Declined].includes(status);
};
exports.isPageLocked = isPageLocked;
var ProjectSubStatus;
(function (ProjectSubStatus) {
    ProjectSubStatus["AcceptedPage"] = "AcceptedPage";
    ProjectSubStatus["AcceptedManual"] = "AcceptedManual";
    ProjectSubStatus["DeclinedExpired"] = "DeclinedExpired";
    ProjectSubStatus["DeclinedManual"] = "DeclinedManual";
})(ProjectSubStatus || (exports.ProjectSubStatus = ProjectSubStatus = {}));
var EngagementTriggerType;
(function (EngagementTriggerType) {
    EngagementTriggerType["PAGE_ACTIVITY"] = "PAGE_ACTIVITY";
    EngagementTriggerType["SCHEDULED_CALCULATION"] = "SCHEDULED_CALCULATION";
    EngagementTriggerType["PAGE_NEWLY_MONITORED"] = "PAGE_NEWLY_MONITORED";
    EngagementTriggerType["ALGORITHM_UPDATED"] = "ALGORITHM_UPDATED";
})(EngagementTriggerType || (exports.EngagementTriggerType = EngagementTriggerType = {}));
var PageStateChangeSource;
(function (PageStateChangeSource) {
    PageStateChangeSource["PageCreation"] = "PageCreation";
    PageStateChangeSource["InAppAction"] = "InAppAction";
    PageStateChangeSource["PublicPageAction"] = "PublicPageAction";
    PageStateChangeSource["PublicApi"] = "PublicApi";
    PageStateChangeSource["CRMTemplateConversion"] = "CRMTemplateConversion";
    PageStateChangeSource["Scheduled"] = "Scheduled";
    PageStateChangeSource["Unspecified"] = "Unspecified";
})(PageStateChangeSource || (exports.PageStateChangeSource = PageStateChangeSource = {}));
