"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addBoomrVar = exports.addAllPerformanceMarks = exports.addPerformanceTimer = exports.getCommonBoomrOptions = exports.BoomerangHelper = void 0;
var BoomerangHelperEvents;
(function (BoomerangHelperEvents) {
    BoomerangHelperEvents["ClientPageLoadComplete"] = "projectLoaded";
})(BoomerangHelperEvents || (BoomerangHelperEvents = {}));
var PAGE_URL_REGEX = /.*\/page\/([0-9a-fA-F]{24})$/;
var BoomerangHelper = (function () {
    function BoomerangHelper() {
    }
    BoomerangHelper.routeChangeWaitFilter = function () {
        if (BoomerangHelper.isClientPageLoad()) {
            var pageId_1 = BoomerangHelper.getPageId();
            window.addEventListener(BoomerangHelperEvents.ClientPageLoadComplete, function (event) {
                var _a, _b;
                if (window.BOOMR && event.detail.pageId === pageId_1) {
                    var BOOMR = window.BOOMR;
                    var navComplete = (_b = (_a = BOOMR === null || BOOMR === void 0 ? void 0 : BOOMR.plugins) === null || _a === void 0 ? void 0 : _a.SPA) === null || _b === void 0 ? void 0 : _b.markNavigationComplete;
                    if (navComplete) {
                        navComplete();
                    }
                }
            }, {
                once: true,
            });
            return true;
        }
        return false;
    };
    BoomerangHelper.notifyClientPageLoaded = function (pageId) {
        var projectLoadedEvent = new CustomEvent(BoomerangHelperEvents.ClientPageLoadComplete, {
            detail: { pageId: pageId },
        });
        window.dispatchEvent(projectLoadedEvent);
    };
    BoomerangHelper.isClientPageLoad = function () {
        var _a, _b;
        var match = (_b = (_a = window.location) === null || _a === void 0 ? void 0 : _a.href) === null || _b === void 0 ? void 0 : _b.match(PAGE_URL_REGEX);
        return match != null;
    };
    BoomerangHelper.getPageId = function () {
        var _a, _b;
        var match = (_b = (_a = window.location) === null || _a === void 0 ? void 0 : _a.href) === null || _b === void 0 ? void 0 : _b.match(PAGE_URL_REGEX);
        return match === null || match === void 0 ? void 0 : match[1];
    };
    return BoomerangHelper;
}());
exports.BoomerangHelper = BoomerangHelper;
function getCommonBoomrOptions(sendTimingData) {
    return {
        ResourceTiming: {
            enabled: sendTimingData,
        },
        NavigationTiming: {
            enabled: true,
        },
        PaintTiming: {
            enabled: true,
        },
        Continuity: {
            enabled: true,
            monitorLongTasks: true,
            monitorPageBusy: true,
            monitorInteractions: true,
            monitorStats: true,
            sendTimeline: sendTimingData,
        },
    };
}
exports.getCommonBoomrOptions = getCommonBoomrOptions;
function addPerformanceTimer(timerName) {
    var BOOMR = window.BOOMR;
    if (BOOMR) {
        BOOMR.plugins.RT.endTimer(timerName, BOOMR.now());
    }
    else {
        performance.mark(timerName);
    }
}
exports.addPerformanceTimer = addPerformanceTimer;
function addBoomrVar(name, value) {
    var BOOMR = window.BOOMR;
    if (BOOMR) {
        BOOMR.addVar("vars.".concat(name), value, true);
    }
}
exports.addBoomrVar = addBoomrVar;
function addAllPerformanceMarks() {
    var e_1, _a;
    var BOOMR = window.BOOMR;
    if (!BOOMR) {
        throw new Error("addAllPerformanceMarks was called before Boomerang was initialised");
    }
    var marks = performance.getEntriesByType("mark");
    try {
        for (var marks_1 = __values(marks), marks_1_1 = marks_1.next(); !marks_1_1.done; marks_1_1 = marks_1.next()) {
            var mark = marks_1_1.value;
            var startTime = Math.round(mark.startTime);
            BOOMR.plugins.RT.setTimer(mark.name, startTime);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (marks_1_1 && !marks_1_1.done && (_a = marks_1.return)) _a.call(marks_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    performance.clearMarks();
}
exports.addAllPerformanceMarks = addAllPerformanceMarks;
