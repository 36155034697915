"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TaxService = (function () {
    function TaxService(availableTaxes) {
        var _this = this;
        this.getTaxes = function () { return _this.availableTaxes; };
        this.taxesFromBlockSettings = function (settings) {
            var enabledTaxes = settings === null || settings === void 0 ? void 0 : settings.enabledTaxes;
            if (!enabledTaxes) {
                return [];
            }
            var taxes = [];
            for (var id in enabledTaxes) {
                var tax = _this.getTaxForId(id);
                if (enabledTaxes[id] && tax) {
                    taxes.push(tax);
                }
            }
            return taxes;
        };
        this.getTaxForId = function (id) { return _this.availableTaxes.find(function (tax) { return tax.id === id; }); };
        this.availableTaxes = availableTaxes;
    }
    return TaxService;
}());
exports.default = TaxService;
