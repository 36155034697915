"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.text = void 0;
var MarkType_1 = require("ContentTokens/Models/MarkType");
exports.text = {
    createToken: function (content, styles, href) {
        var token = {
            type: "inline",
            subType: "text",
            content: content,
        };
        if (styles) {
            token.style = styles;
        }
        if (href != null) {
            token.href = href;
        }
        return token;
    },
    createSlateJson: function (content, styles) {
        if (content === void 0) { content = ""; }
        var marks = [];
        if (styles) {
            if (styles.bold) {
                marks.push({
                    data: {},
                    type: MarkType_1.MarkType.Bold,
                });
            }
            if (styles.italic) {
                marks.push({
                    data: {},
                    type: MarkType_1.MarkType.Italic,
                });
            }
            if (styles.strikethrough) {
                marks.push({
                    data: {},
                    type: MarkType_1.MarkType.Strikethrough,
                });
            }
        }
        return {
            text: content,
            marks: marks,
        };
    },
};
