// Generated by CoffeeScript 1.12.7

/*
  This function calculates the totals for a quote section.
  It factors in discounts, taxes and tax exemption.
 */

(function () {
  module.exports = function (block) {
    var getItemTotal;
    getItemTotal = require("./Item");
    return function (section) {
      var applyItemDiscount,
        calculateTaxes,
        discounts,
        ensureNonNegative,
        i,
        isDisabledOptionalItem,
        item,
        len,
        ref,
        taxableTotal,
        total;
      total = 0;
      discounts = 0;
      taxableTotal = 0;
      ensureNonNegative = function () {
        if (taxableTotal < 0) {
          taxableTotal = 0;
        }
        if (total < 0) {
          return (total = 0);
        }
      };

      /*
        Apply an item's discount to the section totals.
        @param item [Object] the item whose discount you want to apply.
       */
      applyItemDiscount = function (item) {
        total = total - item.discount;
        discounts += item.discount;
        if (!item.taxExempt) {
          return (taxableTotal = taxableTotal - item.discount);
        }
      };
      isDisabledOptionalItem = function (item) {
        var ref, ref1;
        if (((ref = item.interactive) != null ? ref.type : void 0) == null) {
          return false;
        }
        if (item.interactive.type !== "optional") {
          return false;
        }
        if (((ref1 = item.interactive.settings) != null ? ref1.enabled : void 0) === true) {
          return false;
        }
        return true;
      };
      calculateTaxes = function () {
        var i, len, ref, results, tax, value;
        section.$taxes = {};
        ref = block.taxes;
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
          tax = ref[i];
          value = section.$taxableTotal * (tax.percent / 100);
          results.push((section.$taxes[tax.name] = value));
        }
        return results;
      };
      ref = section.items;
      for (i = 0, len = ref.length; i < len; i++) {
        item = ref[i];
        item.cost = getItemTotal(item);
        if (isDisabledOptionalItem(item)) {
          continue;
        }
        total = total + item.cost;
        if (item.discount > 0) {
          applyItemDiscount(item);
        }
        if (!item.taxExempt) {
          taxableTotal = taxableTotal + item.cost;
        }
      }
      ensureNonNegative();
      section.total = total;
      section.$totalDiscounts = discounts;
      section.$taxableTotal = taxableTotal;
      calculateTaxes();
      return section;
    };
  };
}.call(this));
