// Generated by CoffeeScript 1.12.7

/*
  Displays a quote.
 */

(function () {
  var getDecimalPlaces;

  module.exports = function (createUi, make, block, projectState, $rootScope, MOBILE_BREAKPOINT) {
    var config, totals;
    if (projectState.status === "accepted") {
      block.sections = _.map(block.sections, function (section) {
        section.items = _.filter(section.items, function (item) {
          var ref;
          return !(
            ((ref = item.interactive) != null ? ref.type : void 0) === "optional" &&
            item.interactive.settings.enabled === false
          );
        });
        return section;
      });
    }
    block.sections = _.map(block.sections, function (section) {
      var i, item, len, ref, ref1;
      ref = section.items;
      for (i = 0, len = ref.length; i < len; i++) {
        item = ref[i];
        if (((ref1 = item.interactive) != null ? ref1.type : void 0) === "optional") {
          section.$hasOptionalItems = true;
          return section;
        }
      }
      return section;
    });
    totals = make(require("./Totals/Totals"));
    config = block.settings.displayConfig;
    this.ui = createUi(require("./Display.html"), {
      sections: block.sections,
      totals: totals.ui,
      labels: block.settings.customLabels,
      config: config,
      accept: block.settings.accept,
      isTextItemsOnly: function (section) {
        var i, item, len, ref;
        ref = section.items;
        for (i = 0, len = ref.length; i < len; i++) {
          item = ref[i];
          if (item.type !== "text") {
            return false;
          }
        }
        return true;
      },
      currencySymbol: function () {
        return block.settings.currency.symbol;
      },
      unitPriceFractionSize: function (item) {
        var ref, unitDecimals;
        if (((ref = item.rate) != null ? ref.rate : void 0) != null) {
          unitDecimals = Math.min(getDecimalPlaces(item.rate.rate), 10);
        } else {
          unitDecimals = 0;
        }
        return Math.max(unitDecimals, 2);
      },

      /*
        Determines the display configuration of a line item.
        Quotes are highly configurable with options to show / hide
        combinations of the unit name, number of units, unit price etc.
        @param item [Object] the item to determine the configuration for.
       */
      configuration: function (item) {
        if (config.hideItems) {
          return "blank";
        } else if (item.type === "text") {
          return "text";
        } else if (!config.units && !config.unitPrice) {
          return "standard";
        } else if (config.units && !config.unitPrice) {
          return "with-units";
        } else if (config.unitPrice && !config.units) {
          return "with-unit-price";
        } else if (config.units && config.unitPrice) {
          return "with-units-and-price";
        }
      },
      showQuantity: function (item) {
        var ref;
        return (
          projectState.status !== "accepted" && ((ref = item.interactive) != null ? ref.type : void 0) === "quantity"
        );
      },
      showOption: function (item) {
        var ref;
        return (
          projectState.status !== "accepted" && ((ref = item.interactive) != null ? ref.type : void 0) === "optional"
        );
      },
      showCost: (function (_this) {
        return function (configuration) {
          return configuration !== "blank" && configuration !== "text";
        };
      })(this),
      hideLeftInteractiveInput: (function (_this) {
        return function (item) {
          if (!_this.ui.showOption(item) && !_this.ui.showQuantity(item)) {
            return true;
          }
          if (!_this.ui.showOption(item) && _this.ui.configuration(item) !== "standard") {
            return true;
          }
        };
      })(this),
      getColspan: function (itemType) {
        if (itemType === "text") {
          return 6;
        } else {
          return 1;
        }
      },

      /*
        Ensure the inputs from the accepter of the quote repects any
        minimum or maximum bounds the creator of the quote has put
        in place.
       */
      ensureBounds: (function (_this) {
        return function (item, event) {
          var value;
          item.$focused = false;
          if (item.units == null) {
            value = parseInt($(event.target).val());
            if (_.isNumber(value)) {
              item.units = value;
            } else {
              item.units = 0;
            }
          }
          if (item.interactive.settings.min != null && item.units < item.interactive.settings.min) {
            item.units = item.interactive.settings.min;
          }
          if (item.interactive.settings.max != null && item.units > item.interactive.settings.max) {
            return (item.units = item.interactive.settings.max);
          }
        };
      })(this),
      hasBounds: (function (_this) {
        return function (item) {
          return _this.ui.hasMinBoundary(item) || _this.ui.hasMaxBoundary(item);
        };
      })(this),
      hasMinBoundary: function (item) {
        var ref, ref1;
        return (
          ((ref = item.interactive) != null ? ((ref1 = ref.settings) != null ? ref1.min : void 0) : void 0) != null
        );
      },
      hasMaxBoundary: function (item) {
        var ref, ref1;
        return (
          ((ref = item.interactive) != null ? ((ref1 = ref.settings) != null ? ref1.max : void 0) : void 0) != null
        );
      },
      getBoundariesMessage: (function (_this) {
        return function (item) {
          var hasMax, hasMin, makeHtml, max, min;
          hasMin = _this.ui.hasMinBoundary(item);
          hasMax = _this.ui.hasMaxBoundary(item);
          makeHtml = function (label, value) {
            return "<span class='label'>" + label + ":</span> <span class='value'>" + value + "</span>";
          };
          if (hasMin && !hasMax) {
            return makeHtml("Minimum", item.interactive.settings.min);
          } else if (!hasMin && hasMax) {
            return makeHtml("Maximum", item.interactive.settings.max);
          } else if (hasMin && hasMax) {
            min = makeHtml("Min", item.interactive.settings.min);
            max = makeHtml("Max", item.interactive.settings.max);
            return "<span class='dual-cell'>" + min + "</span> <span class='dual-cell'>" + max + "</span>";
          }
        };
      })(this),
    });
    this.ui.isMobile = $(window).width() < MOBILE_BREAKPOINT;
    return $(window).on(
      "resize",
      (function (_this) {
        return function () {
          var originalValue;
          originalValue = _this.ui.isMobile;
          _this.ui.isMobile = $(window).width() < MOBILE_BREAKPOINT;
          if (_this.ui.isMobile !== originalValue) {
            return $rootScope.$apply();
          }
        };
      })(this),
    );
  };

  getDecimalPlaces = function (number) {
    var strRepr;
    if (Math.floor(number) === number) {
      return 0;
    }
    strRepr = number.toString().split(".")[1];
    if (strRepr == null) {
      return 0;
    } else {
      return strRepr.length;
    }
  };
}.call(this));
