"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deriveAcceptButtonColor = exports.legacyEmerald = void 0;
exports.legacyEmerald = "#00857b";
var deriveAcceptButtonColor = function (acceptBlock) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return {
        abs: (_d = (_c = (_b = (_a = acceptBlock === null || acceptBlock === void 0 ? void 0 : acceptBlock.style) === null || _a === void 0 ? void 0 : _a.acceptButton) === null || _b === void 0 ? void 0 : _b.color) === null || _c === void 0 ? void 0 : _c.abs) !== null && _d !== void 0 ? _d : exports.legacyEmerald,
        themeIndex: (_h = (_g = (_f = (_e = acceptBlock === null || acceptBlock === void 0 ? void 0 : acceptBlock.style) === null || _e === void 0 ? void 0 : _e.acceptButton) === null || _f === void 0 ? void 0 : _f.color) === null || _g === void 0 ? void 0 : _g.themeIndex) !== null && _h !== void 0 ? _h : null,
    };
};
exports.deriveAcceptButtonColor = deriveAcceptButtonColor;
