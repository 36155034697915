// Generated by CoffeeScript 1.12.7
(function () {
  var CostLabel, CurrencyDecimalFormatter, LabelTemplate;

  LabelTemplate = require("./CostLabel.html");

  CurrencyDecimalFormatter = require("Utils/CurrencyDecimalFormatter");

  module.exports = CostLabel = (function () {
    function CostLabel(createUi, itemAdapter, invoke) {
      var formatCurrency;
      formatCurrency = invoke(CurrencyDecimalFormatter);
      this.ui = createUi(LabelTemplate, {
        getCost: function () {
          return formatCurrency(itemAdapter.cost());
        },
        isUnused: function () {
          return itemAdapter.isOptional() && !itemAdapter.isOptionalSelected();
        },
      });
    }

    return CostLabel;
  })();
}.call(this));
