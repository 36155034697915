"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Sentry = __importStar(require("@sentry/browser"));
exports.default = (function (Button, $q, $timeout, utils, speechBubble) {
    return (function (_super) {
        __extends(AsyncButton, _super);
        function AsyncButton(click, text, options, pointFinger, getErrorMessage) {
            var _this = this;
            if (options == null) {
                options = {};
            }
            if (options.initialState == null) {
                options.initialState = "disabled";
            }
            pointFinger = pointFinger || options.pointFinger;
            getErrorMessage = getErrorMessage || options.getErrorMessage;
            var asyncClick = function () {
                return $q.resolve().then(function () {
                    if (me.state !== "idle") {
                        return;
                    }
                    me.state = "waiting";
                    return $q
                        .all([click(), utils.delay(500)])
                        .then(function () {
                        if (!(options === null || options === void 0 ? void 0 : options.skipSuccessState)) {
                            me.state = "success";
                            $timeout(function () {
                                if (me.state === "success") {
                                    me.state = options === null || options === void 0 ? void 0 : options.initialState;
                                }
                            }, 3500);
                        }
                        else {
                            me.state = options === null || options === void 0 ? void 0 : options.initialState;
                        }
                    })
                        .catch(function (error) {
                        Sentry.captureException(error);
                        me.state = "idle";
                        if (!getErrorMessage) {
                            return;
                        }
                        var targetSelector = pointFinger != null ? pointFinger(error) : null;
                        if (targetSelector == null) {
                            targetSelector = ".button--id-".concat(me.id);
                        }
                        speechBubble.alert({
                            targetSelector: targetSelector,
                            message: getErrorMessage(error),
                        });
                    });
                });
            };
            _this = _super.call(this, asyncClick, text, options) || this;
            var me = _this;
            return _this;
        }
        return AsyncButton;
    }(Button));
});
