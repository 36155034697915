"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi, utils) {
    return (function () {
        function Button(click, text, options) {
            var _a;
            options = options || {};
            var theme = options.theme || "primary";
            var size = options.size || "medium";
            var initialState = options.initialState || "idle";
            var decoration = options.decoration;
            var icon = options.icon;
            var width = options.width || "normal";
            var href = options.href;
            var center = (_a = options.center) !== null && _a !== void 0 ? _a : true;
            var me = this;
            this.state = initialState;
            this._text = text;
            this._id = utils.randomString();
            if (href) {
                this._ui = createUi(require("./AButton.html"), {
                    click: click,
                    getText: function () {
                        return me._text;
                    },
                    theme: theme,
                    size: size,
                    width: width,
                    decoration: decoration,
                    href: href,
                    id: me.id,
                    center: center,
                });
            }
            else {
                this._ui = createUi(require("./Button.html"), {
                    click: click,
                    getText: function () {
                        return me._text;
                    },
                    theme: theme,
                    size: size,
                    icon: icon,
                    width: width,
                    decoration: decoration,
                    id: me.id,
                    center: center,
                    getState: function () {
                        return me.state;
                    },
                    showIcon: function () {
                        return me.state === "waiting" || me.state === "success";
                    },
                    showText: function () {
                        return me.state !== "waiting" && me.state !== "success";
                    },
                });
            }
        }
        Object.defineProperty(Button.prototype, "ui", {
            get: function () {
                return this._ui;
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(Button.prototype, "id", {
            get: function () {
                return this._id;
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(Button.prototype, "text", {
            set: function (newText) {
                this._text = newText;
            },
            enumerable: false,
            configurable: true
        });
        Object.defineProperty(Button.prototype, "phantomClick", {
            get: function () {
                return this._ui.click;
            },
            enumerable: false,
            configurable: true
        });
        return Button;
    }());
});
