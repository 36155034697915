// Generated by CoffeeScript 1.12.7
(function () {
  var calculateOverhang, getOffsets, horizontalCentering, verticalCentering;

  getOffsets = require("./GetElementOffsets");

  module.exports = function (targetElement, tooltipElement, containerElement, position, spacing) {
    var arrowInsetPosition,
      arrowOutlinePosition,
      borderingOffset,
      containerExtent,
      containerOffset,
      overhang,
      paddingOffset,
      targetExtent,
      targetOffset,
      tooltipExtent,
      tooltipPosition;
    targetExtent = targetElement[0].getBoundingClientRect();
    targetOffset = getOffsets(targetElement);
    containerExtent = containerElement[0].getBoundingClientRect();
    containerOffset = getOffsets(containerElement);
    borderingOffset = 5;
    paddingOffset = 25;
    tooltipExtent = tooltipElement.getBoundingClientRect();
    tooltipPosition = {};
    arrowOutlinePosition = {};
    arrowInsetPosition = {};
    if (position === "top") {
      tooltipPosition["bottom"] =
        containerOffset.bottom - targetOffset.bottom + targetExtent.height + borderingOffset + spacing;
      tooltipPosition["left"] = verticalCentering(targetExtent, tooltipExtent, containerOffset, targetOffset);
      overhang = calculateOverhang(
        tooltipPosition["left"] - paddingOffset,
        containerOffset.left,
        tooltipExtent.width,
        $(document).width() - paddingOffset * 2,
      );
      tooltipPosition["left"] += -overhang;
      arrowInsetPosition["bottom"] = -5;
      arrowInsetPosition["left"] = tooltipExtent.width / 2 + overhang;
      arrowOutlinePosition["bottom"] = arrowInsetPosition["bottom"] - 1;
      arrowOutlinePosition["left"] = arrowInsetPosition["left"];
    } else if (position === "bottom") {
      tooltipPosition["top"] = targetOffset.top - containerOffset.top + targetExtent.height + borderingOffset + spacing;
      tooltipPosition["left"] = verticalCentering(targetExtent, tooltipExtent, containerOffset, targetOffset);
      overhang = calculateOverhang(
        tooltipPosition["left"] - paddingOffset,
        containerOffset.left,
        tooltipExtent.width,
        $(document).width() - paddingOffset * 2,
      );
      tooltipPosition["left"] += -overhang;
      arrowInsetPosition["top"] = -5;
      arrowInsetPosition["left"] = tooltipExtent.width / 2 + overhang;
      arrowOutlinePosition["top"] = arrowInsetPosition["top"] - 1;
      arrowOutlinePosition["left"] = arrowInsetPosition["left"];
    } else if (position === "left") {
      tooltipPosition["right"] =
        containerOffset.right - targetOffset.right + targetExtent.width + borderingOffset + spacing;
      tooltipPosition["top"] = horizontalCentering(targetExtent, tooltipExtent, containerOffset, targetOffset);
      overhang = calculateOverhang(
        tooltipPosition["top"],
        containerOffset.top,
        tooltipExtent.height,
        $(document).height(),
      );
      tooltipPosition["top"] -= overhang;
      arrowInsetPosition["right"] = -5;
      arrowInsetPosition["top"] = tooltipExtent.height / 2 + overhang;
      arrowOutlinePosition["right"] = arrowInsetPosition["right"] - 1;
      arrowOutlinePosition["top"] = arrowInsetPosition["top"];
    } else if (position === "right") {
      tooltipPosition["left"] =
        targetOffset.left - containerOffset.left + targetExtent.width + borderingOffset + spacing;
      tooltipPosition["top"] = horizontalCentering(targetExtent, tooltipExtent, containerOffset, targetOffset);
      overhang = calculateOverhang(
        tooltipPosition["top"],
        containerOffset.top,
        tooltipExtent.height,
        $(document).height(),
      );
      tooltipPosition["top"] -= overhang;
      arrowInsetPosition["left"] = -5;
      arrowInsetPosition["top"] = tooltipExtent.height / 2 + overhang;
      arrowOutlinePosition["left"] = arrowInsetPosition["left"] - 1;
      arrowOutlinePosition["top"] = arrowInsetPosition["top"];
    } else {
      throw new Error("Invalid tooltip position provided");
    }
    return {
      tooltipPosition: tooltipPosition,
      arrowInsetPosition: arrowInsetPosition,
      arrowOutlinePosition: arrowOutlinePosition,
    };
  };

  verticalCentering = function (targetExtent, tooltipExtent, containerOffset, targetOffset) {
    var centerRelativeToTarget, positionWithinContainer;
    positionWithinContainer = targetOffset.left - containerOffset.left;
    centerRelativeToTarget = (targetExtent.width - tooltipExtent.width) / 2;
    return positionWithinContainer + centerRelativeToTarget;
  };

  horizontalCentering = function (targetExtent, tooltipExtent, containerOffset, targetOffset) {
    var centerRelativeToTarget, positionWithinContainer;
    positionWithinContainer = targetOffset.top - containerOffset.top;
    centerRelativeToTarget = (targetExtent.height - tooltipExtent.height) / 2;
    return positionWithinContainer + centerRelativeToTarget;
  };

  calculateOverhang = function (position, containerOffset, tooltipLength, documentLength) {
    var leftPosition, rightPosition;
    if (containerOffset < 0) {
      return 0;
    }
    leftPosition = position + containerOffset;
    rightPosition = position + containerOffset + tooltipLength;
    if (leftPosition < containerOffset) {
      return leftPosition - containerOffset;
    } else if (rightPosition - documentLength > 0) {
      return rightPosition - documentLength;
    } else {
      return 0;
    }
  };
}.call(this));
