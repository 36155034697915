"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fontSizeMappings = void 0;
exports.fontSizeMappings = {
    h1: {
        small: 50,
        medium: 65,
        large: 85,
    },
    h2: {
        small: 35,
        medium: 40,
        large: 60,
    },
    p: {
        small: 16,
        medium: 20,
        large: 25,
    },
    blockQuote: {
        small: 22,
        medium: 27,
        large: 34,
    },
};
