"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GetCustomClass_1 = require("../GetCustomClass");
var lodash_1 = require("lodash");
exports.default = (function (Tooltip, make, di, createUi) {
    return function (options) {
        var targetSelector = options.targetSelector, ui = options.ui, position = options.position, deleteFn = options.deleteFn, isDeleteDisabled = options.isDeleteDisabled;
        var contents = createUi(require("./PanelInner.html"), {
            panelInner: ui,
            isDeleteDisabled: isDeleteDisabled || lodash_1.noop,
            deleteFn: deleteFn,
        });
        var tooltipOptions = {
            closeOnClickAway: true,
            customTheme: "panel",
            contents: contents,
            position: position || "top",
            customClass: (0, GetCustomClass_1.getCustomClass)(position),
            spacing: 10,
        };
        return make(Tooltip, {
            targetSelector: targetSelector,
            options: tooltipOptions,
        });
    };
});
