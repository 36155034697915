"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Media = void 0;
var Media;
(function (Media) {
    Media["Image"] = "image";
    Media["Video"] = "video";
    Media["GoogleMaps"] = "googleMaps";
    Media["Iframe"] = "iframe";
})(Media || (exports.Media = Media = {}));
