"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineType = exports.BlockType = void 0;
var BlockType;
(function (BlockType) {
    BlockType["Any"] = "any";
    BlockType["GoogleMaps"] = "googleMaps";
    BlockType["MediaVariable"] = "mediaVariable";
    BlockType["Caption"] = "caption";
})(BlockType || (exports.BlockType = BlockType = {}));
var InlineType;
(function (InlineType) {
    InlineType["Any"] = "any";
    InlineType["Link"] = "link";
    InlineType["Variable"] = "variable";
    InlineType["LineBreak"] = "lineBreak";
    InlineType["TokenWidget"] = "tokenWidget";
})(InlineType || (exports.InlineType = InlineType = {}));
