"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
function randomString(length) {
    if (length === void 0) { length = 10; }
    var text = "";
    for (var i = 0; i <= length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
exports.default = randomString;
