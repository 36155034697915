"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAbbreviatedCurrency = exports.formatStringWithCurrency = exports.parseCurrency = exports.formatCurrency = void 0;
var currency_formatter_1 = require("currency-formatter");
var lodash_1 = require("lodash");
var ParseIntlCurrency_1 = require("./ParseIntlCurrency/ParseIntlCurrency");
var formatCurrency = function (amount, currencyCode, precision, locale) {
    if (locale) {
        var noNegativeZeroAmount = Object.is(amount, -0) ? 0 : amount;
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        }).format(noNegativeZeroAmount);
    }
    var formatted = (0, currency_formatter_1.format)(amount, { code: currencyCode, precision: precision });
    if (currencyCode === "CHF" && formatted.substring(formatted.length - 4) === "\xA0CHF") {
        var numberOnly = formatted.substring(0, formatted.length - 4);
        formatted = "CHF\u00A0".concat(numberOnly);
    }
    if (currencyCode === "DKK") {
        formatted = amount.toLocaleString("da-DK", { style: "currency", currency: "DKK" });
    }
    return formatted;
};
exports.formatCurrency = formatCurrency;
var parseCurrency = function (value, currencyCode, _a) {
    var _b = _a === void 0 ? {} : _a, decimals = _b.decimals, locale = _b.locale;
    if (locale) {
        return (0, ParseIntlCurrency_1.parseIntlCurrency)(value, locale, currencyCode);
    }
    var parsed = (0, currency_formatter_1.unformat)(value, { code: currencyCode });
    if (!(0, lodash_1.isUndefined)(decimals)) {
        parsed = Number(parsed.toFixed(2));
    }
    return parsed;
};
exports.parseCurrency = parseCurrency;
var formatStringWithCurrency = function (string, currencyCode) {
    var currency = currency_formatter_1.currencies.find(function (f) { return f.code === currencyCode; });
    if (!currency) {
        return string;
    }
    if (currency.symbolOnLeft) {
        return "".concat(currency.symbol).concat(string);
    }
    return "".concat(string, " ").concat(currency.symbol);
};
exports.formatStringWithCurrency = formatStringWithCurrency;
var ABBREVIATION_SYMBOL_MAPPING = Object.freeze([
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
]);
var formatAbbreviatedCurrency = function (amount, currencyCode, precision, abbreviation) {
    var number = amount;
    var abbreviationSymbol = "";
    if (abbreviation && number > 1) {
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = ABBREVIATION_SYMBOL_MAPPING.slice()
            .reverse()
            .find(function (item) {
            return amount >= item.value;
        });
        number = item ? Number((amount / item.value).toFixed(precision).replace(rx, "$1")) : 0;
        abbreviationSymbol = item ? item.symbol : "";
    }
    var formatted = (0, exports.formatCurrency)(number, currencyCode, precision);
    return formatted.replace(/([0-9])([^0-9]*)$/, "$1".concat(abbreviationSymbol, "$2"));
};
exports.formatAbbreviatedCurrency = formatAbbreviatedCurrency;
