// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qcheckbox", []).directive("qCheckbox", function () {
    var templateUrl;
    templateUrl = require("./Checkbox.html");
    return {
      restrict: "EA",
      scope: {
        model: "=",
        label: "@",
        onChange: "=?",
        disabled: "=?",
        color: "=?",
      },
      templateUrl: templateUrl,
      controller: function ($scope, $timeout) {
        if ($scope.onChange == null) {
          $scope.onChange = function () {};
        }
        $scope.getStyle = function (color) {
          if (color != null && $scope.model) {
            return {
              "background-color": color,
              "box-shadow": "0 0 0 1px " + color,
            };
          } else {
            return "";
          }
        };
        return ($scope.toggle = _.throttle(
          function () {
            if ($scope.disabled) {
              return;
            }
            $scope.model = !$scope.model;
            return $timeout(function () {
              return $scope.onChange($scope.model);
            });
          },
          200,
          {
            leading: true,
            trailing: false,
          },
        ));
      },
    };
  });
}.call(this));
