// Generated by CoffeeScript 1.12.7
(function () {
  var QuantityInput, QuantityTooltip, Template;

  Template = require("./QuantityInput.html");

  QuantityTooltip = require("./QuantityTooltip");

  /*
  Input box for entering quantities and handling quantity ranges on Client and Public
  Uses the ItemAdapter to handle range validation
  
  @NOTE (som, 2018-01-29): since the item quantity will be modified both by this number input
  and the Tooltip slider, it seems to make sense to let the ItemAdapter do range validation
  instead of relying on setting the min/max in html
   */

  module.exports = QuantityInput = (function () {
    function QuantityInput(
      createUi,
      make,
      block,
      Tooltip,
      itemAdapter,
      itemViewId,
      sectionViewId,
      disableInteractives,
    ) {
      var initTooltip, quantityTooltip, tooltip;
      tooltip = null;
      quantityTooltip = null;
      initTooltip = (function (_this) {
        return function () {
          var targetSelector;
          quantityTooltip = make(QuantityTooltip, {
            refreshTextInput: _this.ui.updateFromAdapter,
            itemAdapter: itemAdapter,
          });
          targetSelector = "#" + sectionViewId + " #" + itemViewId + " .quantity";
          return (tooltip = make(Tooltip, {
            targetSelector: targetSelector,
            options: {
              container: "#" + sectionViewId,
              customClass: "quantity-tooltip",
              customTheme: "",
              position: "top",
              contents: quantityTooltip.ui,
              spacing: 7,
              closeOnClickAway: true,
              ignoreClickAwayElems: [targetSelector],
            },
          }));
        };
      })(this);
      this.ui = createUi(Template, {
        quantity: itemAdapter.getQuantity(),
        disableInteractives: disableInteractives,
        writeToAdapter: (function (_this) {
          return function () {
            if (_this.ui.quantity != null) {
              itemAdapter.setQuantity(_this.ui.quantity);
              return quantityTooltip != null ? quantityTooltip.refreshSlider() : void 0;
            }
          };
        })(this),
        updateFromAdapter: (function (_this) {
          return function () {
            return (_this.ui.quantity = itemAdapter.getQuantity());
          };
        })(this),
        isInvalid: (function (_this) {
          return function () {
            return _this.ui.quantity !== itemAdapter.getQuantity();
          };
        })(this),
        showRangeTooltip: (function (_this) {
          return function () {
            if (itemAdapter.isQuantityOptional()) {
              if (tooltip == null) {
                initTooltip();
              }
              if (!tooltip.isCurrentlyShowing()) {
                quantityTooltip.onShow();
                return tooltip.show();
              }
            }
          };
        })(this),
      });
    }

    return QuantityInput;
  })();
}.call(this));
