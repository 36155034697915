// Generated by CoffeeScript 1.12.7

/*
  This module calculates the total for a quote line item.
 */

(function () {
  module.exports = function (item) {
    var applyDiscount, cost, ref;
    applyDiscount = function () {
      if (item.discount != null && typeof item.discount === "number") {
        if (item.discount < 0) {
          return (item.discount = item.discount * -1);
        }
      }
    };
    applyDiscount();
    cost = item.units * ((ref = item.rate) != null ? ref.rate : void 0) || 0;
    return cost;
  };
}.call(this));
