"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkType = void 0;
var MarkType;
(function (MarkType) {
    MarkType["Any"] = "any";
    MarkType["Bold"] = "bold";
    MarkType["Italic"] = "italic";
    MarkType["Strikethrough"] = "strikethrough";
    MarkType["Style"] = "style";
    MarkType["Comment"] = "comment";
    MarkType["Color"] = "color";
})(MarkType || (exports.MarkType = MarkType = {}));
