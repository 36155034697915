"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomClass = void 0;
var getCustomClass = function (position) {
    if (position === "bottom") {
        return "fade-in-down";
    }
    else {
        return "fade-in-up";
    }
};
exports.getCustomClass = getCustomClass;
