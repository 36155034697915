"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var FeatureFlag_1 = require("@CommonFrontendBackend/FeatureFlag");
var DefaultCurrency_1 = require("./DefaultCurrency");
var ProjectCurrencies = (function () {
    function ProjectCurrencies(usingFeature, quoteBlocksList) {
        this.usingFeature = usingFeature;
        this.quoteBlocksList = quoteBlocksList;
    }
    ProjectCurrencies.prototype.allowingMultipleCurrencies = function () {
        return this.usingFeature(FeatureFlag_1.FeatureFlag.MultipleCurrencies);
    };
    ProjectCurrencies.prototype.hasMultipleCurrencies = function () {
        var quoteBlocks = this.quoteBlocksList.get();
        return ProjectCurrencies.hasMultipleCurrencies(quoteBlocks);
    };
    ProjectCurrencies.hasMultipleCurrencies = function (quoteBlocksList) {
        var e_1, _a;
        if (quoteBlocksList.length > 0) {
            var firstCurrency = quoteBlocksList[0].settings.currency;
            try {
                for (var quoteBlocksList_1 = __values(quoteBlocksList), quoteBlocksList_1_1 = quoteBlocksList_1.next(); !quoteBlocksList_1_1.done; quoteBlocksList_1_1 = quoteBlocksList_1.next()) {
                    var quoteBlock = quoteBlocksList_1_1.value;
                    if (quoteBlock.settings.currency !== firstCurrency) {
                        return true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (quoteBlocksList_1_1 && !quoteBlocksList_1_1.done && (_a = quoteBlocksList_1.return)) _a.call(quoteBlocksList_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return false;
    };
    ProjectCurrencies.prototype.getProjectCurrencies = function () {
        var e_2, _a;
        var quoteBlocks = this.quoteBlocksList.get();
        var currenciesSet = new Set();
        try {
            for (var quoteBlocks_1 = __values(quoteBlocks), quoteBlocks_1_1 = quoteBlocks_1.next(); !quoteBlocks_1_1.done; quoteBlocks_1_1 = quoteBlocks_1.next()) {
                var quoteBlock = quoteBlocks_1_1.value;
                currenciesSet.add(quoteBlock.settings.currency);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (quoteBlocks_1_1 && !quoteBlocks_1_1.done && (_a = quoteBlocks_1.return)) _a.call(quoteBlocks_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return Array.from(currenciesSet);
    };
    ProjectCurrencies.prototype.getSingleCurrency = function () {
        return ProjectCurrencies.getSingleCurrency(this.quoteBlocksList.get());
    };
    ProjectCurrencies.getSingleCurrency = function (quoteBlocksList) {
        if (this.hasMultipleCurrencies(quoteBlocksList)) {
            throw new Error("Cannot get single currency from a multiple currency project");
        }
        return quoteBlocksList[0].settings.currency;
    };
    ProjectCurrencies.prototype.getNewBlockCurrency = function () {
        if (this.allowingMultipleCurrencies() || this.quoteBlocksList.get().length === 0) {
            return (0, DefaultCurrency_1.getDefaultCurrency)();
        }
        else {
            return this.getSingleCurrency();
        }
    };
    return ProjectCurrencies;
}());
exports.default = ProjectCurrencies;
