"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var cleanJson = function (obj) {
    var e_1, _a, e_2, _b;
    var cleaned;
    if (obj instanceof Array) {
        cleaned = [];
        try {
            for (var obj_1 = __values(obj), obj_1_1 = obj_1.next(); !obj_1_1.done; obj_1_1 = obj_1.next()) {
                var elem = obj_1_1.value;
                cleaned.push(cleanJson(elem));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (obj_1_1 && !obj_1_1.done && (_a = obj_1.return)) _a.call(obj_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return cleaned;
    }
    else if (obj && typeof obj === "object") {
        cleaned = {};
        try {
            for (var _c = __values(Object.keys(obj)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var key = _d.value;
                switch (key.charAt(0)) {
                    case "$":
                    case "_":
                        continue;
                }
                cleaned[key] = cleanJson(obj[key]);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return cleaned;
    }
    else {
        return obj;
    }
};
exports.default = cleanJson;
