"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUsingNewBrandSetUp = exports.isUsingTCDC = exports.FeatureFlag = void 0;
var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["Accept"] = "accept";
    FeatureFlag["Quote"] = "quote";
    FeatureFlag["AdvancedStyleEditor"] = "advancedStyleEditor";
    FeatureFlag["PersistenceSafetyNet"] = "persistenceSafetyNet";
    FeatureFlag["UseRightToLeftBeta"] = "useRightToLeftBeta";
    FeatureFlag["Teams"] = "teams";
    FeatureFlag["AcceptCustomForms"] = "acceptCustomForms";
    FeatureFlag["Esign"] = "esign";
    FeatureFlag["ImageFrames"] = "imageFrames";
    FeatureFlag["IpFiltering"] = "ipFiltering";
    FeatureFlag["Domain"] = "domain";
    FeatureFlag["Subdomain"] = "subdomain";
    FeatureFlag["ViralAcceptFlow"] = "viralAcceptFlow";
    FeatureFlag["BluePrintApi"] = "bluePrintApi";
    FeatureFlag["QwilrApi"] = "qwilr-api";
    FeatureFlag["ZapierIntegration"] = "zapier integration";
    FeatureFlag["LegacyCustomDomain"] = "legacyCustomDomain";
    FeatureFlag["Emoji"] = "emoji";
    FeatureFlag["WhiteLabelAcceptFlow"] = "whiteLabelAcceptFlow";
    FeatureFlag["StylePermission"] = "stylePermission";
    FeatureFlag["ProjectSearchV2"] = "projectSearchV2";
    FeatureFlag["NewEditorFancyColumns"] = "newEditor-fancyColumns";
    FeatureFlag["NotificationsWebhook"] = "notificationsWebhook";
    FeatureFlag["IdentityMapping"] = "identityMapping";
    FeatureFlag["PublicClonableProjects"] = "publicClonableProjects";
    FeatureFlag["AdvancedSecurity"] = "advanced security";
    FeatureFlag["MultipleCurrencies"] = "multipleCurrencies";
    FeatureFlag["Security"] = "security";
    FeatureFlag["LinkPreview"] = "linkPreview";
    FeatureFlag["LinkPreviewDefaultsOverride"] = "linkPreviewDefaultsOverride";
    FeatureFlag["WhiteLabelledEmails"] = "whiteLabelledEmails";
    FeatureFlag["NewEditor"] = "newEditor";
    FeatureFlag["DisableBeacon"] = "disableBeacon";
    FeatureFlag["AuditTrail"] = "audit trail";
    FeatureFlag["ActiveUsers"] = "activeUsers";
    FeatureFlag["BasicAnalytics"] = "basic analytics";
    FeatureFlag["OnboardingDocConversion"] = "onboardingDocConversion";
    FeatureFlag["OptOutTCDC"] = "optOutTCDC";
    FeatureFlag["OptOutNewEditor"] = "optOutNewEditor";
    FeatureFlag["NewBrandSetup"] = "newBrandSetup";
    FeatureFlag["TCDCPlayground"] = "TCDCPlayground";
    FeatureFlag["DocConverter"] = "docConverter";
    FeatureFlag["LiveChatIntegrations"] = "liveChatIntegrations";
    FeatureFlag["PublicApi"] = "publicApi";
    FeatureFlag["Sidebar"] = "sidebar";
    FeatureFlag["SideBySideResizing"] = "sideBySideResizing";
    FeatureFlag["BoomerangTimingData"] = "boomerangTimingData";
    FeatureFlag["DisableImgix"] = "disableImgix";
    FeatureFlag["OptOutPrintImageOptimisation"] = "optOutPrintImageOptimisation";
    FeatureFlag["SidebarImages"] = "sidebarImages";
    FeatureFlag["Accordion"] = "accordionWidget";
    FeatureFlag["AccordionVisual"] = "accordionVisual";
    FeatureFlag["PastingURLLinks"] = "pastingURLLinks";
    FeatureFlag["ImageFolders"] = "imageFolders";
    FeatureFlag["PreviewAccept"] = "previewAccept";
    FeatureFlag["CanEditQuoteV1"] = "canEditQuoteV1";
    FeatureFlag["SendEmailsFromQwilrOnly"] = "sendEmailsFromQwilrOnly";
    FeatureFlag["PostAcceptExperience"] = "postAcceptExperience";
    FeatureFlag["OptOutAcceptExperienceSurvey"] = "optOutAcceptExperienceSurvey";
    FeatureFlag["PlatformTokens"] = "platformTokens";
    FeatureFlag["TemplatePageSettings"] = "templatePageSettings";
    FeatureFlag["OptionalLoginMethods"] = "optionalLoginMethods";
    FeatureFlag["HideFavicon"] = "hideFavicon";
    FeatureFlag["XeroIntegration"] = "xeroIntegration";
    FeatureFlag["AdvancedEngagementAlgorithms"] = "advancedEngagementAlgorithms";
    FeatureFlag["CollaboratorComment"] = "collaboratorComment";
    FeatureFlag["PipedriveV2"] = "pipedriveV2";
    FeatureFlag["OptOutPipedriveV2"] = "optOutPipedriveV2";
    FeatureFlag["ConsumerVirality"] = "consumerVirality";
    FeatureFlag["OptOutSpringboardEngagementMenu"] = "optOutSpringboardEngagementMenu";
    FeatureFlag["UserManagementService"] = "userManagementService";
    FeatureFlag["DisableClerkOnPublicPages"] = "disableClerkOnPublicPages";
    FeatureFlag["RefreshedNavigationMenu"] = "refreshedNavigationMenu";
    FeatureFlag["SpringboardAcceptedPages"] = "springboardAcceptedPages";
    FeatureFlag["SpringboardAcceptDiscovery"] = "springboardAcceptDiscovery";
    FeatureFlag["HubspotSyncEssentials"] = "hubspotSyncEssentials";
    FeatureFlag["CurrencyLocales"] = "currencyLocales";
    FeatureFlag["AcceptBlockPrintMode"] = "acceptBlockPrintMode";
    FeatureFlag["EditorRegistryWidgets"] = "editorRegistryWidgets";
    FeatureFlag["EditorDebugLogging"] = "dev:editorDebugLogging";
    FeatureFlag["DisableTmsFetching"] = "dev:disableTmsFetching";
    FeatureFlag["DisableEditorSavingIndicator"] = "dev:disableEditorSavingIndicator";
    FeatureFlag["AgreementViewBasic"] = "agreementViewBasic";
    FeatureFlag["AgreementViewWidget"] = "agreementViewWidget";
    FeatureFlag["MakeQwilrYourOwn"] = "makeQwilrYourOwn";
    FeatureFlag["TailoredPages"] = "tailoredPages";
    FeatureFlag["SlackAutomation"] = "slackAutomation";
    FeatureFlag["LatestNotificationConfigUI"] = "latestNotificationConfigUI";
    FeatureFlag["AgreementFeaturePromptEnabled"] = "agreementFeaturePromptEnabled";
    FeatureFlag["PromotionBar"] = "promotionBar";
    FeatureFlag["ErgonomicsDragging"] = "ergonomicsDragging";
    FeatureFlag["NewSidebarExploreSearch"] = "newSidebarExploreSearch";
    FeatureFlag["NewTextToolbar"] = "newTextToolbar";
    FeatureFlag["WidgetStyleOverrides"] = "widgetStyleOverrides";
    FeatureFlag["TextBlockBackgroundStylingMenu"] = "textBlockBackgroundStylingMenu";
    FeatureFlag["AdvancedAnalyticsLimits"] = "advancedAnalyticsLimits";
    FeatureFlag["LayoutWidget"] = "layoutWidget";
    FeatureFlag["AllowBlockMigration"] = "allowBlockMigration";
    FeatureFlag["ReadFromBlockV2"] = "readFromBlockV2";
    FeatureFlag["ReplaceSideBySideWithLayout"] = "replaceSideBySideWithLayout";
    FeatureFlag["UpdatePlanSeatsInStripe"] = "updatePlanSeatsInStripe";
})(FeatureFlag || (exports.FeatureFlag = FeatureFlag = {}));
var isUsingTCDC = function (usingFeature) {
    return !usingFeature(FeatureFlag.OptOutTCDC) && !usingFeature(FeatureFlag.OptOutNewEditor);
};
exports.isUsingTCDC = isUsingTCDC;
var isUsingNewBrandSetUp = function (usingFeature) {
    return (0, exports.isUsingTCDC)(usingFeature) && usingFeature(FeatureFlag.NewBrandSetup);
};
exports.isUsingNewBrandSetUp = isUsingNewBrandSetUp;
exports.default = FeatureFlag;
