// Generated by CoffeeScript 1.12.7

/*
  This module monitors the current window size as a proxy for
  the device form factor (i.e. mobile, tablet, desktop etc.)
  and emits an app-level event on a change in device form factor.
 */

(function () {
  module.exports = function (appEvents, isMobile, $timeout) {
    var THROTTLE, getFormFactor, onResize, previousFormFactor;
    THROTTLE = 250;
    getFormFactor = function () {
      if (isMobile()) {
        return "mobile";
      } else {
        return "desktop";
      }
    };
    previousFormFactor = getFormFactor();
    onResize = function () {
      var currentFormFactor;
      currentFormFactor = getFormFactor();
      if (currentFormFactor !== previousFormFactor) {
        previousFormFactor = currentFormFactor;
        return $timeout(function () {
          return appEvents.emit("changedFormFactor", {
            formFactor: currentFormFactor,
          });
        });
      }
    };
    return $(window).on(
      "resize",
      _.throttle(onResize, THROTTLE, {
        trailing: true,
      }),
    );
  };
}.call(this));
