"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bemBuilder = function (block) { return function (element, modifier) {
    var className = block;
    if (element) {
        className += "__".concat(element);
    }
    if (modifier) {
        className += "--".concat(modifier);
    }
    return className;
}; };
exports.default = bemBuilder;
