"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SubstitutionTokenIdFactory = (function () {
    function SubstitutionTokenIdFactory() {
    }
    SubstitutionTokenIdFactory.createId = function (providerType, components) {
        var encodedComponents = components.map(encodeURIComponent).join(":");
        return "token:".concat(providerType, ":").concat(encodedComponents);
    };
    return SubstitutionTokenIdFactory;
}());
exports.default = SubstitutionTokenIdFactory;
