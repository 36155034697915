"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var DiscountService = (function () {
    function DiscountService(accountQuoteSettings) {
        var _this = this;
        this.addNewDiscount = function (discount) {
            _this.accountQuoteSettings.discounts = __spreadArray(__spreadArray([], __read(_this.accountQuoteSettings.discounts), false), [discount], false);
        };
        this.getAllLiveDiscounts = function () {
            return _this.accountQuoteSettings.discounts.filter(function (discount) { return !discount.isDeleted; });
        };
        this.removeDiscountById = function (id) {
            var discount = _this.accountQuoteSettings.discounts.find(function (discount) { return discount.id === id; });
            discount.isDeleted = true;
        };
        this.findDiscountById = function (id) {
            return (0, lodash_1.find)(_this.accountQuoteSettings.discounts, { id: id });
        };
        this.updateDiscountByPropName = function (id, prop, val) {
            var discount = _this.findDiscountById(id);
            discount[prop] = val;
        };
        this.accountQuoteSettings = accountQuoteSettings;
        if (!this.accountQuoteSettings.discounts) {
            this.accountQuoteSettings.discounts = [];
        }
    }
    return DiscountService;
}());
exports.default = DiscountService;
