import "../CommonFrontend/AgreementView/AgreementModal.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AgreementView/AgreementModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81US27bMBDd5xTTRQCrDQ3JseNG3bToAQr0AgYlUtbEFCmQlMOg8N0LkpIl2e5nVRTaiMP5vTePsyw2W1sUK/hxB1DQ8rDXqpMshyPVC0JKJZQmptMVLflulxVi+/KS7ZNP3ltpxjXRlGFnhoBo/B5sV3FZ3Qc6YmrK1OsQFU+EC36kFpV8HEOqEPKKzNY5ZGl67481x31th/PpbhlRPAYUw2VJRbnwHkDgadW6kIihaQV9y6ES3E1C1yHUG3PIvKM6cl0J9UrecqCdVYPNlFoJQQpe0yMqnUOppKUoJ7k2IdeQIIcaGeNTh6fggEaJgDbvf7mvgdJwe+alpSXKPXFmJOQQcLTKYIyt0HHmTS+dsVi9EcNFlUPJpeX6BmSAhjoyELpep62bMDwlbfG7JuA9rJIkptN7lANLZ5DbKKkokkJZq5ocstaBUQLZTF/RacyeJtMh+9lN8n6cDflCAc/hssficfrpcGkvYW961KPiYytnGYdsvo8xdV77kfaoRgWn4XtsHUQwDboFSjB6XzxcZINVev8AVlNpWqq5tAm8w6ZV2lLf4rkUDUXGEdPCKNFFfeiIez4aMXInA3dWtf0sPXvw4cJ/MkmRTEGu4Ezk8iCI5c6GXm6Qk82bvyV4AJ+AjBdcCGwNmnnFSumGHFe7nf+ZHE1XNGhJ0Vmr5H/SRi6VXSzjPyFoCENDC8FZcrFB/6inv674z2R33VFZ8/Lgq/ZLru9jeLfeqpX4UvplM0pqc416jMFSyW/y681Idzla39znhjOksGhQDs93m6ati4TPNurfbLHZUxmX2C8W79XDOo01n/uak72yTvu9cro7/QSCQDtSWgcAAA==\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var modalHeaderHeight = '62px';
export var modalInset = 'var(--spacing-xs__1bl7jj11k)';
export var navButtonColor = 'var(--b57tbb0)';
export var navButtonTextColor = 'var(--b57tbb1)';
export var container = 'b57tbb2';
export var body = 'b57tbb3';
export var content = 'b57tbb4';
export var headerTitle = 'b57tbb5';
export var wrapper = 'b57tbb6';
export var header = 'b57tbb7';
export var spinner = 'b57tbb8';
export var formNavButton = 'b57tbb9';
export var notification = 'b57tbba';