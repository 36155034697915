"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROICalculatorViewStore = void 0;
var mobx_1 = require("mobx");
var ROICalculatorViewStore = (function () {
    function ROICalculatorViewStore(initialPayload) {
        var _this = this;
        this.updateInputValue = function (id, newValue) {
            _this._inputs[id].value = newValue;
        };
        this.updateSlider = function (value) {
            _this._sliderValue = value;
        };
        this._inputs = initialPayload.inputs;
        this._sliderValue = initialPayload.slider.initialValue;
    }
    Object.defineProperty(ROICalculatorViewStore.prototype, "inputs", {
        get: function () {
            return this._inputs;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ROICalculatorViewStore.prototype, "sliderValue", {
        get: function () {
            return this._sliderValue;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Object)
    ], ROICalculatorViewStore.prototype, "_inputs", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], ROICalculatorViewStore.prototype, "_sliderValue", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ROICalculatorViewStore.prototype, "inputs", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], ROICalculatorViewStore.prototype, "sliderValue", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ROICalculatorViewStore.prototype, "updateInputValue", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ROICalculatorViewStore.prototype, "updateSlider", void 0);
    return ROICalculatorViewStore;
}());
exports.ROICalculatorViewStore = ROICalculatorViewStore;
