"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var mobx_1 = require("mobx");
var ItemDiscountStore = (function () {
    function ItemDiscountStore(_discountModel, _preDiscount, discountService, formatCurrency) {
        var _this = this;
        this.discountService = discountService;
        this.formatCurrency = formatCurrency;
        this.formatDiscount = function (amount, type) {
            if (type === Types_1.DiscountType.Fixed) {
                return _this.formatCurrency(amount);
            }
            else {
                return "".concat(amount, "%");
            }
        };
        this.isSavedDiscount = function (model) {
            if (model.id != undefined) {
                return true;
            }
            else {
                return false;
            }
        };
        this.discountModel = _discountModel;
        this.preDiscount = _preDiscount;
    }
    ItemDiscountStore.prototype.updateDiscountModelFromAngular = function (_discountModel) {
        this.discountModel = _discountModel;
    };
    ItemDiscountStore.prototype.updatePreDiscountFromAngular = function (_preDiscount) {
        this.preDiscount = _preDiscount;
    };
    Object.defineProperty(ItemDiscountStore.prototype, "formattedPreDiscount", {
        get: function () {
            return this.formatCurrency(this.preDiscount);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemDiscountStore.prototype, "formattedDiscount", {
        get: function () {
            var _a;
            if (this.isSavedDiscount(this.discountModel)) {
                var savedDiscount = this.discountService.findDiscountById(this.discountModel.id);
                if (!!savedDiscount) {
                    return this.formatDiscount(savedDiscount.amount, savedDiscount.type);
                }
                else {
                    return "";
                }
            }
            else {
                return this.formatDiscount((_a = this.discountModel.units) !== null && _a !== void 0 ? _a : 0, this.discountModel.type);
            }
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Object)
    ], ItemDiscountStore.prototype, "discountModel", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], ItemDiscountStore.prototype, "preDiscount", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ItemDiscountStore.prototype, "updateDiscountModelFromAngular", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], ItemDiscountStore.prototype, "updatePreDiscountFromAngular", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ItemDiscountStore.prototype, "formattedPreDiscount", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ItemDiscountStore.prototype, "formattedDiscount", null);
    return ItemDiscountStore;
}());
exports.default = ItemDiscountStore;
