// Generated by CoffeeScript 1.12.7
(function () {
  var getDecimalPlaces;

  getDecimalPlaces = require("./GetDecimalPlaces").getDecimalPlaces;

  /*
    Formats a cost amount according to the currency and decimal display settings
  
    @NOTE (som, 2018-02-21): we don't want the 'display decimals' setting to hide information,
    so if a cost has a precision that requires decimal places, we will show the decimal places
    regardless of the setting
   */

  module.exports = function (quoteAdapter, quoteCurrencyFormatter) {
    var format;
    format = function (amount) {
      var currencyDecimals, roundedAmount;
      if (amount == null) {
        return;
      }
      if (quoteAdapter.showDecimalPlaces()) {
        return quoteCurrencyFormatter.format(amount);
      } else {
        currencyDecimals = quoteCurrencyFormatter.idealDecimalPlaces();
        roundedAmount = +amount.toFixed(currencyDecimals);
        if (getDecimalPlaces(roundedAmount) > 0) {
          return quoteCurrencyFormatter.format(amount);
        } else {
          return quoteCurrencyFormatter.format(amount, 0);
        }
      }
    };
    return format;
  };
}.call(this));
