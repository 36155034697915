"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var CalculateProject_1 = require("./Calculator/CalculateProject");
var ProjectTotal = (function () {
    function ProjectTotal(quoteBlocksList, accountQuoteSettings, variableService, discountService, billingScheduleOrchestrator) {
        var _this = this;
        this.quoteBlocksList = quoteBlocksList;
        this.accountQuoteSettings = accountQuoteSettings;
        this.variableService = variableService;
        this.discountService = discountService;
        this.billingScheduleOrchestrator = billingScheduleOrchestrator;
        this.hasVisibleQuoteTotal = function () {
            var e_1, _a;
            if (_this.quoteBlocksList && _this.quoteBlocksList.getAdapters) {
                try {
                    for (var _b = __values(_this.quoteBlocksList.getAdapters()), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var adapter = _c.value;
                        if (adapter.showQuoteTotals()) {
                            return true;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return false;
            }
            else {
                throw new Error("quoteBlocksList.getAdapters does not exist. hasVisibleQuoteTotal() is only meant to be used in Public");
            }
        };
        this.calculatePayTodaySingleCurrency = function () {
            var totals = (0, lodash_1.mapValues)(_this.calculateProject(), function (f) { return f.postTax; });
            return totals[Object.keys(totals)[0]];
        };
        this.calculateProject = function () {
            var _a;
            var taxes = (_a = _this.accountQuoteSettings.taxes) !== null && _a !== void 0 ? _a : [];
            var blocks = _this.quoteBlocksList.get();
            return (0, CalculateProject_1.calculateProject)(blocks, taxes, _this.variableService, _this.discountService, _this.billingScheduleOrchestrator);
        };
    }
    return ProjectTotal;
}());
exports.default = ProjectTotal;
