(function () {
  var AcceptButton,
    FeatureFlag,
    AcceptButton = require("./AcceptButton")["default"];

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = angular.module("acceptButtonv2", []).directive("acceptButtonV2", function ($rootScope, $timeout) {
    return {
      restrict: "E",
      template: '<div ui="acceptButton.ui"></div>',
      scope: {
        label: "@",
        editable: "=?",
        onChange: "=?",
        click: "=?",
        accepters: "=",
        blockStyle: "=",
        settings: "=?",
        isDisabled: "=?",
        hideMultiAccepters: "=?",
        hasEditAccess: "=?",
        buildEditMenu: "=?",
        isInNavigation: "=?",
        agreementBlock: "=?",
        savedAgreement: "=?",
      },
      link: function (scope, elem, attrs) {
        var childScope,
          di,
          di = $rootScope.di;
        childScope = di.child();
        if (scope.accepters != null && _.isString(scope.accepters)) {
          scope.accepters = JSON.parse(scope.accepters);
        }
        if (scope.settings != null && _.isString(scope.settings)) {
          scope.settings = JSON.parse(scope.settings);
        } else {
          if (scope.settings == null) {
            scope.settings = {};
          }
        }
        if (scope.isDisabled == null) {
          scope.isDisabled = function () {
            return false;
          };
        }
        const isPrintMode = $rootScope.di.get("isPrintMode");
        return (scope.acceptButton = childScope.make(AcceptButton, {
          label: function () {
            if (scope.label) {
              return scope.label;
            }
            if (scope.agreementBlock || scope.savedAgreement) {
              return "View agreement";
            }
            return "Accept";
          },
          click: scope.click,
          blockStyle: scope.blockStyle || {},
          accepters: scope.accepters,
          settings: scope.settings,
          isDisabled: scope.isDisabled,
          hideMultiAccepters: scope.hideMultiAccepters || false,
          buildEditMenu:
            scope.buildEditMenu ||
            (function (_this) {
              return function () {
                return {};
              };
            })(this),
          isInNavigation: scope.isInNavigation || false,
          withAgreementBlock: !!scope.agreementBlock || false,
          isPrintMode: isPrintMode,
        }));
      },
    };
  });
}.call(this));
