"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserIconColorByName = exports.DEFAULT_USER_ICON_COLORS = exports.USER_ICON_COLORS = void 0;
exports.USER_ICON_COLORS = [
    { text: "#81A2B2", background: "#DFE7EB" },
    { text: "#60C032", background: "#D7EFCC" },
    { text: "#0FBCB2", background: "#C3EEEB" },
    { text: "#2DA5F3", background: "#CAE8FC" },
    { text: "#C88E4B", background: "#F1E3D2" },
    { text: "#F0B316", background: "#FBECC5" },
    { text: "#E97D40", background: "#F9DECF" },
    { text: "#4F5FEF", background: "#D3D7FB" },
    { text: "#A55AE0", background: "#E8D5F7" },
    { text: "#D15CB8", background: "#F3D6ED" },
];
exports.DEFAULT_USER_ICON_COLORS = { text: "#242C39", background: "#C8CACD" };
var generateHash = function (input) {
    return input.split("").reduce(function (prevHash, currVal) {
        return ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0;
    }, 0);
};
var generateColorIndexByUserName = function (userName) {
    var hashNumber = generateHash(userName + userName.length);
    var colorRange = exports.USER_ICON_COLORS.length;
    return ((hashNumber % colorRange) + colorRange) % colorRange;
};
var getUserIconColorByName = function (userName) {
    if (!userName) {
        return exports.DEFAULT_USER_ICON_COLORS;
    }
    var index = generateColorIndexByUserName(userName);
    return exports.USER_ICON_COLORS[index];
};
exports.getUserIconColorByName = getUserIconColorByName;
