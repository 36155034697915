"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateInjection = void 0;
var ValidateStrings_1 = require("@CommonFrontendBackend/Utils/ValidateStrings");
function validateInjection(value, options) {
    var injectionCheck = (0, ValidateStrings_1.checkBasicText)(value, options);
    if (injectionCheck.valid === false) {
        var errorMsg = injectionCheck.error;
        return errorMsg[0].toUpperCase() + errorMsg.slice(1);
    }
}
exports.validateInjection = validateInjection;
