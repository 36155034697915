"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useUniqueKey(key) {
    var lastKey = (0, react_1.useRef)(key);
    var lastTimestamp = (0, react_1.useRef)();
    if (key !== lastKey.current) {
        lastKey.current = key;
        lastTimestamp.current = new Date().getTime().toString();
    }
    if (!lastTimestamp.current) {
        return key;
    }
    return "".concat(key, "-").concat(lastTimestamp.current);
}
exports.default = useUniqueKey;
