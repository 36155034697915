"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageSrcSet = exports.getImageSrc = exports.getMaxWidthForPrint = void 0;
var EditorDataModel_1 = require("ContentTokens/DataModels/EditorDataModel");
var QWILR_CLOUDFRONT_URL = "https://d2cankni8sodj9.cloudfront.net/";
var JPG_EXTENSION = ".jpg";
var JPEG_EXTENSION = ".jpeg";
var PNG_EXTENSION = ".png";
var WEBP_EXTENSION = ".webp";
var getMaxWidthForPrint = function (orientation) {
    return orientation == EditorDataModel_1.PrintOrientation.Portrait ? 2550 : 3525;
};
exports.getMaxWidthForPrint = getMaxWidthForPrint;
var getImageSrc = function (url, disableImgix, options) {
    if (shouldConvert(url, disableImgix)) {
        return convertToImgixUrl(url, options);
    }
    return url;
};
exports.getImageSrc = getImageSrc;
var getImageSrcSet = function (url, maxWidths, disableImgix) {
    var e_1, _a;
    var srcSetStr = "";
    if (shouldConvert(url, disableImgix)) {
        try {
            for (var maxWidths_1 = __values(maxWidths), maxWidths_1_1 = maxWidths_1.next(); !maxWidths_1_1.done; maxWidths_1_1 = maxWidths_1.next()) {
                var w = maxWidths_1_1.value;
                var imageCdnUrl = (0, exports.getImageSrc)(url, disableImgix, {
                    maxWidth: w,
                });
                srcSetStr += "".concat(imageCdnUrl, " ").concat(w, "w, ");
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (maxWidths_1_1 && !maxWidths_1_1.done && (_a = maxWidths_1.return)) _a.call(maxWidths_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return srcSetStr;
};
exports.getImageSrcSet = getImageSrcSet;
var convertToImgixUrl = function (url, options) {
    var imgixEndpoint = url.replace(QWILR_CLOUDFRONT_URL, "https://qwilr.imgix.net/");
    var urlParams = new URLSearchParams();
    if (!(options === null || options === void 0 ? void 0 : options.keepOriginalFormat)) {
        urlParams.append("auto", "format");
    }
    if (options === null || options === void 0 ? void 0 : options.maxWidth) {
        urlParams.append("fit", "max");
        urlParams.append("w", "".concat(options.maxWidth));
    }
    return "".concat(imgixEndpoint, "?").concat(urlParams.toString());
};
var shouldConvert = function (url, disableImgix) {
    return disableImgix !== true && !!url && url.startsWith(QWILR_CLOUDFRONT_URL) && isSafeExtensions(url);
};
var isSafeExtensions = function (url) {
    var allowedExtensions = [JPEG_EXTENSION, JPG_EXTENSION, PNG_EXTENSION, WEBP_EXTENSION];
    return allowedExtensions.some(function (ext) {
        return url.endsWith(ext);
    });
};
