"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGenericCommonConfigByType = exports.getCommonConfigByType = void 0;
var CommonWidgetRegistry_1 = require("../CommonWidgetRegistry");
var getCommonConfigByType = function (type) {
    var e_1, _a;
    try {
        for (var CommonWidgetRegistryConfigs_1 = __values(CommonWidgetRegistry_1.CommonWidgetRegistryConfigs), CommonWidgetRegistryConfigs_1_1 = CommonWidgetRegistryConfigs_1.next(); !CommonWidgetRegistryConfigs_1_1.done; CommonWidgetRegistryConfigs_1_1 = CommonWidgetRegistryConfigs_1.next()) {
            var config = CommonWidgetRegistryConfigs_1_1.value;
            if (config.type === type) {
                return config;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (CommonWidgetRegistryConfigs_1_1 && !CommonWidgetRegistryConfigs_1_1.done && (_a = CommonWidgetRegistryConfigs_1.return)) _a.call(CommonWidgetRegistryConfigs_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    throw new Error("Config with type ".concat(type, " not registered"));
};
exports.getCommonConfigByType = getCommonConfigByType;
var getGenericCommonConfigByType = function (type) {
    try {
        return (0, exports.getCommonConfigByType)(type);
    }
    catch (_a) {
        return undefined;
    }
};
exports.getGenericCommonConfigByType = getGenericCommonConfigByType;
