"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var omitByDeep = function (data, omitFn) {
    if ((0, lodash_1.isArray)(data)) {
        return data.map(function (v) { return omitByDeep(v, omitFn); });
    }
    if ((0, lodash_1.isObject)(data)) {
        return Object.entries(data).reduce(function (obj, _a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            if (!omitFn(key, value)) {
                obj[key] = omitByDeep(value, omitFn);
            }
            return obj;
        }, {});
    }
    return data;
};
exports.default = omitByDeep;
