// Generated by CoffeeScript 1.12.7
(function () {
  var DiscountAdapter,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  module.exports = DiscountAdapter = (function () {
    function DiscountAdapter(model) {
      var discountTypes;
      discountTypes = ["percent", "fixed"];
      this.getDiscountUnits = function () {
        return model.units;
      };
      this.hasDiscount = (function (_this) {
        return function () {
          return model != null && model.enabled;
        };
      })(this);
      this.hasNonZeroDiscount = (function (_this) {
        return function () {
          return _this.hasDiscount() && (!!model.id || (model.units != null && model.units !== 0));
        };
      })(this);
      this.setDiscountEnabled = (function (_this) {
        return function (enabled) {
          return (model.enabled = enabled);
        };
      })(this);
      this.getModel = (function (_this) {
        return function () {
          return model;
        };
      })(this);
      this.getDiscountType = function () {
        return model.type;
      };
      this.setDiscountType = (function (_this) {
        return function (discountType) {
          if (indexOf.call(discountTypes, discountType) >= 0) {
            return (model.type = discountType);
          }
        };
      })(this);
    }

    return DiscountAdapter;
  })();
}.call(this));
