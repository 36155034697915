// Generated by CoffeeScript 1.12.7

/*
  The Overlay Stack is responsible for maintaining a "stack"
  of overlay cards.

  This makes creating a "cycle through the screens" type experience much
  easier to implement, since each card can maintain its particular state,
  while other overlays are opened.
 */

(function () {
  var OverlayMode, OverlayStack;

  OverlayMode = require("OverlayStack/OverlayMode").OverlayMode;

  module.exports = OverlayStack = (function () {
    function OverlayStack(createUi, OverlayStackCard, di, make, appEvents, $timeout, $window) {
      var ESCAPE_KEY_CODE, cleanUpOnEmpty, domFrame, onEmptyStack, onStackVisible, setCloseButtonVisibility, stack;
      ESCAPE_KEY_CODE = 27;
      stack = [];
      this.ui = createUi(require("./OverlayStack.html"), {
        stack: stack,
        isCurrentElementModalMode: function () {
          return stack.length > 0 && _.last(stack).ui.overlayMode === OverlayMode.Modal;
        },
        onClickOverlay: (function (_this) {
          return function () {
            var current;
            current = _.last(stack);
            if (current) {
              return current.ui.close();
            }
          };
        })(this),
      });
      if (di.has("domFrame")) {
        domFrame = di.get("domFrame");
        domFrame.add(this.ui);
      }

      /*
        Close all overlays (i.e. clear the stack)
        @api public
       */
      this.closeAll = function () {
        stack.length = 0;
        return cleanUpOnEmpty();
      };

      /*
        Remove an overlay at a particular index in the stack.
        @param index [Integer] - the index of the overlay to remove.
        @api public
       */
      this.closeByIndex = function (index) {
        stack.splice(index, 1);
        return cleanUpOnEmpty();
      };

      /*
        Remove the "top most" overlay - i.e. the overlay currently showing.
        @param index [Integer] - the index of the overlay to remove.
        @api public
       */
      this.closeCurrent = function () {
        var current;
        current = stack.pop();
        if (current != null) {
          current.onClose();
          return cleanUpOnEmpty();
        }
      };

      /*
        Add an overlay to the stack.
        @param stackCardConfig [Object] - this can be either a full configuration object for the OverlayStackCard,
        OR as a shorthand, just the UI object, in which case the OverlayStackCard is constructed with
        default options.
        @api public
       */
      this.add = function (stackCardConfig) {
        var stackCard;
        if (stackCardConfig.$init != null && stackCardConfig.$templateUrl != null) {
          stackCard = make(OverlayStackCard, {
            contentUi: stackCardConfig,
            options: {},
          });
        } else {
          stackCard = make(OverlayStackCard, stackCardConfig);
        }
        stack.push(stackCard);
        if (stack.length === 1) {
          return onStackVisible();
        }
      };

      /*
        Get the current overlay element.
        @returns Jquery Object
      
        We have this function so that we can stay DRY and keep the DOM selector
        for the current overlay centralised in this class.
       */
      this.getCurrentOverlayElem = function () {
        if (stack.length === 0) {
          return null;
        } else {
          return $(".overlay-stack .current-overlay");
        }
      };

      /*
        Get the overlay stack element.
        @returns Jquery Object.
      
        We have this function so that we can stay DRY and keep the DOM selector
        for the overlay stack centralised in this class.
       */
      this.getOverlayStackElem = function () {
        return $(".overlay-stack");
      };
      this.isVisible = function () {
        return stack.length > 0;
      };

      /*
        Show/hide the close button at any time
        e.g Allows the close button to show/hide while an overlay card is already open
        You should use options.hideCloseButton instead of this where possible
       */
      setCloseButtonVisibility = function (show) {
        if (stack.length > 0) {
          return (_.last(stack).ui.hideCloseButton = !show);
        }
      };
      this.showCloseButton = function () {
        return setCloseButtonVisibility(true);
      };
      this.hideCloseButton = function () {
        return setCloseButtonVisibility(false);
      };

      /*
        Hide the close button but notify the OverlayStack that there is still a close button
       */
      this.usingCustomCloseButton = (function (_this) {
        return function () {
          _this.hideCloseButton();
          if (stack.length > 0) {
            return (_.last(stack).usingCustomCloseButton = true);
          }
        };
      })(this);

      /*
        Used for setting a custom background class
       */
      this.setCustomClass = function (className) {
        if (stack.length > 0) {
          _.last(stack).ui.className = className;
          return (_.last(stack).styleClass = className);
        }
      };

      /*
        Get length of the stack
       */
      this.getStackLength = (function (_this) {
        return function () {
          return stack.length;
        };
      })(this);

      /*
        Events and DOM setup when the stack becomes visible.
        @api private
       */
      onStackVisible = (function (_this) {
        return function () {
          $("body").css("overflow", "hidden");
          $(document).on("keyup.overlay", function (e) {
            var code, last;
            code = e.keyCode || e.which;
            if (code === ESCAPE_KEY_CODE) {
              last = _.last(stack);
              if (!last.ui.hideCloseButton || last.usingCustomCloseButton) {
                return $timeout(last.ui.close);
              }
            }
          });
          return appEvents.emit("overlay.opened");
        };
      })(this);

      /*
        Events and clean-up when the stack disappears from the DOM.
        @api private
       */
      onEmptyStack = function () {
        $("body").css("overflow", "auto");
        $(document).off("keyup.overlay");
        return appEvents.emit("overlay.closed");
      };

      /*
        Check if the stack is empty and clean-up if it is.
        @api private
       */
      cleanUpOnEmpty = function () {
        if (stack.length === 0) {
          return onEmptyStack();
        }
      };
    }

    return OverlayStack;
  })();
}.call(this));
