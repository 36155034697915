// Generated by CoffeeScript 1.12.7
(function () {
  var DiscountAdapter,
    ItemAdapter,
    ItemFactory,
    SectionAdapter,
    createDiscountModel,
    createItemModel,
    getDescriptionHtml;

  ItemFactory = require("../Models/ItemTypes").ItemFactory;

  createItemModel = new ItemFactory().templateQuoteItem;

  getDescriptionHtml = require("./GetDescriptionHtml")["default"];

  ItemAdapter = require("./ItemAdapter");

  DiscountAdapter = require("./DiscountAdapter");

  createDiscountModel = require("../Models/Discount");

  module.exports = SectionAdapter = (function () {
    function SectionAdapter(model, labelForField, variableService) {
      var init, rebuildItemAdapters;
      this.model = model;
      this.itemAdapters = [];
      this.discountAdapter = null;
      init = (function (_this) {
        return function () {
          if (model.items == null || model.items.length === 0) {
            return _this.createItem(0);
          } else {
            return rebuildItemAdapters();
          }
        };
      })(this);
      this.descriptionModel = (function (_this) {
        return function () {
          return _this.model.description;
        };
      })(this);
      this.descriptionContent = (function (_this) {
        return function () {
          return getDescriptionHtml(_this.model.description);
        };
      })(this);
      this.getItemAdapters = (function (_this) {
        return function () {
          if (_this.itemCount() !== _this.model.items.length) {
            rebuildItemAdapters();
          }
          return _this.itemAdapters;
        };
      })(this);
      rebuildItemAdapters = (function (_this) {
        return function () {
          var i, itemModel, len, ref, results;
          _this.itemAdapters = [];
          ref = model.items;
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            itemModel = ref[i];
            results.push(_this.itemAdapters.push(new ItemAdapter(itemModel, _this)));
          }
          return results;
        };
      })(this);
      this.getSubTotal = (function (_this) {
        return function () {
          return model.$cost;
        };
      })(this);
      this.getPreDiscount = (function (_this) {
        return function () {
          return model.$preDiscount;
        };
      })(this);
      this.showRateAndQuantity = (function (_this) {
        return function () {
          return _this.model.viewSettings.showRateAndQuantity;
        };
      })(this);
      this.setShowRateAndQuantity = (function (_this) {
        return function (show) {
          return (_this.model.viewSettings.showRateAndQuantity = show);
        };
      })(this);
      this.setShowRate = (function (_this) {
        return function (show) {
          return (_this.model.viewSettings.showRate = show);
        };
      })(this);
      this.showRate = (function (_this) {
        return function () {
          if (_this.model.viewSettings.showRate != null) {
            return _this.model.viewSettings.showRate;
          } else {
            return _this.showRateAndQuantity();
          }
        };
      })(this);
      this.setShowQuantity = (function (_this) {
        return function (show) {
          return (_this.model.viewSettings.showQuantity = show);
        };
      })(this);
      this.showQuantity = (function (_this) {
        return function () {
          if (_this.model.viewSettings.showQuantity != null) {
            return _this.model.viewSettings.showQuantity;
          } else {
            return _this.showRateAndQuantity();
          }
        };
      })(this);
      this.showCost = (function (_this) {
        return function () {
          return _this.model.viewSettings.showCost;
        };
      })(this);
      this.setShowCost = (function (_this) {
        return function (show) {
          return (_this.model.viewSettings.showCost = show);
        };
      })(this);
      this.showSubtotal = (function (_this) {
        return function () {
          return _this.model.viewSettings.showSubtotal;
        };
      })(this);
      this.setShowSubtotal = (function (_this) {
        return function (show) {
          return (_this.model.viewSettings.showSubtotal = show);
        };
      })(this);
      this.itemsViewable = (function (_this) {
        return function () {
          return _this.model.viewSettings.itemsViewable;
        };
      })(this);
      this.setItemsViewable = (function (_this) {
        return function (viewable) {
          return (_this.model.viewSettings.itemsViewable = viewable);
        };
      })(this);
      this.itemsCollapsed = (function (_this) {
        return function () {
          return _this.model.viewSettings.itemsCollapsed;
        };
      })(this);
      this.setItemsCollapsed = (function (_this) {
        return function (collapsed) {
          return (_this.model.viewSettings.itemsCollapsed = collapsed);
        };
      })(this);
      this.isSelected = (function (_this) {
        return function () {
          return _this.model.selected;
        };
      })(this);
      this.isMandatory = (function (_this) {
        return function () {
          return _this.model.mandatory;
        };
      })(this);
      this.setMandatory = (function (_this) {
        return function (mandatory) {
          return (_this.model.mandatory = mandatory);
        };
      })(this);
      this.createItem = (function (_this) {
        return function (index, type) {
          var defaultVariableId, itemAdapter, m;
          if (type == null) {
            type = "fixedCost";
          }
          m = createItemModel(type);
          itemAdapter = _this.createItemFromModel(index, m);
          if (type === "variable") {
            defaultVariableId = variableService.getDefaultVariableId();
            if (defaultVariableId != null) {
              itemAdapter.setVariableId(defaultVariableId);
            }
          }
          return itemAdapter;
        };
      })(this);
      this.createItemFromSaved = (function (_this) {
        return function (index, item) {
          var itemModel;
          itemModel = createItemModel(item.type, item);
          return _this.createItemFromModel(index, itemModel);
        };
      })(this);
      this.createItemFromModel = (function (_this) {
        return function (index, itemModel) {
          var adapter;
          model.items.splice(index, 0, itemModel);
          adapter = new ItemAdapter(itemModel, _this);
          _this.itemAdapters.splice(index, 0, adapter);
          return adapter;
        };
      })(this);
      this.deleteItem = (function (_this) {
        return function (index) {
          if (_this.itemCount() > 1) {
            model.items.splice(index, 1);
            return _this.itemAdapters.splice(index, 1);
          } else {
            throw new Error("Quote section must have at least one item");
          }
        };
      })(this);

      /*
        Swap the positions of two items. Used for moving items up/down
       */
      this.swapItems = (function (_this) {
        return function (indexA, indexB) {
          var itemToMove, modelToMove;
          itemToMove = _this.itemAdapters[indexA];
          _this.itemAdapters[indexA] = _this.itemAdapters[indexB];
          _this.itemAdapters[indexB] = itemToMove;
          modelToMove = model.items[indexA];
          model.items[indexA] = model.items[indexB];
          return (model.items[indexB] = modelToMove);
        };
      })(this);
      this.itemCount = (function (_this) {
        return function () {
          return _this.itemAdapters.length;
        };
      })(this);
      this.hasMismatchedLengths = (function (_this) {
        return function () {
          return _this.itemAdapters.length !== model.items.length;
        };
      })(this);
      this.getDiscountAdapter = (function (_this) {
        return function () {
          if (_this.model.discount == null) {
            _this.model.discount = createDiscountModel();
          }
          if (_this.discountAdapter == null) {
            _this.discountAdapter = new DiscountAdapter(_this.model.discount);
          }
          return _this.discountAdapter;
        };
      })(this);
      this.labelForField = (function (_this) {
        return function (field) {
          return labelForField(field);
        };
      })(this);
      init();
    }

    return SectionAdapter;
  })();
}.call(this));
