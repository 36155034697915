"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var Token_1 = require("ContentTokens/Token");
var ItemStore = (function () {
    function ItemStore(itemObject, variableService) {
        var _this = this;
        this.variableService = variableService;
        this.updateFixedCostRate = function (newRate) {
            if (_this.isTextItem) {
                throw new Error("Cannot update fixed cost rate for text item");
            }
            else if (_this.isVariableItem) {
                throw new Error("Cannot update fixed cost rate for variable item");
            }
            if (_this.isFixedCostRate(_this._itemObject)) {
                _this._itemObject.rate.rate = newRate;
            }
            if (_this.isFixedCostRate(_this.baseItemObject)) {
                _this.baseItemObject.rate.rate = newRate;
            }
        };
        this.updateVariableRate = function (variable) {
            if (_this.isTextItem) {
                throw new Error("Cannot update variable rate for text item");
            }
            else if (_this.isFixedCostItem) {
                throw new Error("Cannot update variable rate for fixed cost item");
            }
            if (_this.isQuoteVariableRate(_this._itemObject)) {
                _this._itemObject.rate.variableId = variable.getId();
            }
            if (_this.isQuoteVariableRate(_this.baseItemObject)) {
                _this.baseItemObject.rate.variableId = variable.getId();
            }
        };
        this.updateQuantity = function (newQuantity) {
            if (!(0, Types_1.isPricedQuoteItem)(_this._itemObject) || !(0, Types_1.isPricedQuoteItem)(_this.baseItemObject)) {
                throw new Error("Trying to update quantity on a ".concat(_this._itemObject.type, " line item"));
            }
            _this._itemObject.quantity = newQuantity;
            _this.baseItemObject.quantity = newQuantity;
        };
        this.baseItemObject = itemObject;
        this._itemObject = itemObject;
        if ((0, Types_1.isPricedQuoteItem)(this._itemObject)) {
            this._itemObject.rate = this._itemObject.rate || {};
        }
    }
    Object.defineProperty(ItemStore.prototype, "variableRateId", {
        get: function () {
            if (this.isQuoteVariableRate(this._itemObject)) {
                if ((0, Types_1.isVariableRate)(this._itemObject.rate)) {
                    return this._itemObject.rate.variableId;
                }
            }
            else {
                throw new Error("Cannot get variable id of non-variable line item");
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "descriptionModel", {
        get: function () {
            return this.baseItemObject.description;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "rateAmount", {
        get: function () {
            var _a, _b;
            if (this.isFixedCostRate(this._itemObject)) {
                return this._itemObject.rate.rate;
            }
            else if (this.isQuoteVariableRate(this._itemObject)) {
                return ((_b = (_a = this.variableService.getVariable(this._itemObject.rate.variableId)) === null || _a === void 0 ? void 0 : _a.defaultAmount) !== null && _b !== void 0 ? _b : 0);
            }
            else {
                throw new Error("Cannot get rate amount for text item");
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "type", {
        get: function () {
            return this._itemObject.type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "quantity", {
        get: function () {
            var _a;
            if ("quantity" in this._itemObject) {
                return (_a = this._itemObject.quantity) !== null && _a !== void 0 ? _a : 0;
            }
            else {
                return undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "isQuantityOptional", {
        get: function () {
            return this._itemObject.interactive.isQuantityOptional;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "unitName", {
        get: function () {
            if (this.isTextItem) {
                throw new Error("Trying to get unit name for a text line item");
            }
            if (this.isQuoteVariableRate(this._itemObject)) {
                return this.variableService.getVariable(this._itemObject.rate.variableId).unitName;
            }
            else if (this.isFixedCostRate(this._itemObject)) {
                return this._itemObject.rate.unit;
            }
        },
        enumerable: false,
        configurable: true
    });
    ItemStore.prototype.updateUnitName = function (newUnitName) {
        if (this.isFixedCostRate(this._itemObject) && this.isFixedCostRate(this.baseItemObject)) {
            this._itemObject.rate.unit = newUnitName;
            this.baseItemObject.rate.unit = newUnitName;
        }
        else {
            throw new Error("Trying to update unit name for line item that isn't fixed cost");
        }
    };
    Object.defineProperty(ItemStore.prototype, "isTextItem", {
        get: function () {
            return this._itemObject.type === Types_1.QuoteItemType.Text;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "isVariableItem", {
        get: function () {
            return this._itemObject.type === Types_1.QuoteItemType.Variable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemStore.prototype, "isFixedCostItem", {
        get: function () {
            return this._itemObject.type === Types_1.QuoteItemType.FixedCost;
        },
        enumerable: false,
        configurable: true
    });
    ItemStore.prototype.isQuoteVariableRate = function (item) {
        return item.type === Types_1.QuoteItemType.Variable;
    };
    ItemStore.prototype.isFixedCostRate = function (item) {
        return item.type === Types_1.QuoteItemType.FixedCost;
    };
    var _a, _b, _c;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", typeof (_a = typeof Types_1.QuoteItem !== "undefined" && Types_1.QuoteItem) === "function" ? _a : Object)
    ], ItemStore.prototype, "_itemObject", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "variableRateId", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_b = typeof Token_1.IContentModel !== "undefined" && Token_1.IContentModel) === "function" ? _b : Object),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "descriptionModel", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "rateAmount", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ItemStore.prototype, "updateFixedCostRate", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ItemStore.prototype, "updateVariableRate", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_c = typeof Types_1.QuoteItemType !== "undefined" && Types_1.QuoteItemType) === "function" ? _c : Object),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "type", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "quantity", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ItemStore.prototype, "updateQuantity", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "isQuantityOptional", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "unitName", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ItemStore.prototype, "updateUnitName", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "isTextItem", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "isVariableItem", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ItemStore.prototype, "isFixedCostItem", null);
    return ItemStore;
}());
exports.default = ItemStore;
