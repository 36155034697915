"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOptimisedRoiBackgroundImageUrl = exports.calculateRoiSliderValue = exports.getPlainTextFromRoiRichText = exports.roundAndFormatRoiResult = void 0;
var ImageCdnHelper_1 = require("@CommonFrontendBackend/Utils/ImageCdnHelper");
var lodash_1 = require("lodash");
var roundAndFormatRoiResult = function (result) {
    if (Number.isNaN(result) || !Number.isFinite(result)) {
        return "--";
    }
    return Math.round(result).toLocaleString();
};
exports.roundAndFormatRoiResult = roundAndFormatRoiResult;
var getPlainTextFromRoiRichText = function (richText) {
    return richText.plain;
};
exports.getPlainTextFromRoiRichText = getPlainTextFromRoiRichText;
var calculateRoiSliderValue = function (input, min, max, increment) {
    var value = (0, lodash_1.clamp)(input, min, max);
    if ((value - min) % increment === 0 || value === min || value === max) {
        return value;
    }
    else {
        var nextIncrement = Math.ceil((value - min) / increment) * increment + min;
        return (0, lodash_1.clamp)(nextIncrement, min, max);
    }
};
exports.calculateRoiSliderValue = calculateRoiSliderValue;
var getOptimisedRoiBackgroundImageUrl = function (_a) {
    var backgroundImageUrl = _a.backgroundImageUrl, disableImgix = _a.disableImgix, isPrintMode = _a.isPrintMode;
    return (0, ImageCdnHelper_1.getImageSrc)(backgroundImageUrl, disableImgix, {
        maxWidth: 2550,
        keepOriginalFormat: !!isPrintMode,
    });
};
exports.getOptimisedRoiBackgroundImageUrl = getOptimisedRoiBackgroundImageUrl;
