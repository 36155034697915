"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnimationDelayValue = exports.getCombinedGroupedSize = exports.getCombinedColor = exports.getCombinedColorThemeIndex = exports.getCombinedAlignment = void 0;
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var ColorUtils_1 = require("./ColorUtils");
var defaultElementTypes = [
    commonTypes_1.TextElementType.H1,
    commonTypes_1.TextElementType.H2,
    commonTypes_1.TextElementType.P,
    commonTypes_1.TextElementType.Blockquote,
    "acceptButton",
];
var defaultTextElementTypes = [commonTypes_1.TextElementType.H1, commonTypes_1.TextElementType.H2, commonTypes_1.TextElementType.P, commonTypes_1.TextElementType.Blockquote];
var getCombinedProperty = function (style, getProperty, elementTypes) {
    if (elementTypes === void 0) { elementTypes = defaultElementTypes; }
    var allProperties = elementTypes
        .map(function (elementType) {
        return getProperty(style[elementType]);
    })
        .filter(function (property) {
        return typeof property !== "undefined";
    });
    if (allProperties) {
        var allEqual = allProperties.every(function (val) {
            return val === allProperties[0];
        });
        if (allEqual) {
            return allProperties[0];
        }
    }
    return null;
};
var getCombinedAlignment = function (style, elementTypes) {
    return getCombinedProperty(style, function (elementStyle) {
        return elementStyle && elementStyle.alignment;
    }, elementTypes);
};
exports.getCombinedAlignment = getCombinedAlignment;
var getCombinedColorThemeIndex = function (style, elementTypes) {
    if (elementTypes === void 0) { elementTypes = defaultTextElementTypes; }
    return getCombinedProperty(style, function (elementStyle) {
        return elementStyle && elementStyle.color && elementStyle.color.themeIndex;
    }, elementTypes);
};
exports.getCombinedColorThemeIndex = getCombinedColorThemeIndex;
var getCombinedColor = function (style, elementTypes) {
    if (elementTypes === void 0) { elementTypes = defaultTextElementTypes; }
    return getCombinedProperty(style, function (elementStyle) {
        return (elementStyle && elementStyle.color && (0, ColorUtils_1.isValidColor)(elementStyle.color.abs) && (0, ColorUtils_1.getRgba)(elementStyle.color.abs));
    }, elementTypes);
};
exports.getCombinedColor = getCombinedColor;
var getCombinedGroupedSize = function (style, elementTypes) {
    return getCombinedProperty(style, function (elementStyle) {
        return elementStyle && elementStyle.size && elementStyle.size.themeRef;
    }, elementTypes);
};
exports.getCombinedGroupedSize = getCombinedGroupedSize;
var getAnimationDelayValue = function (speed, index) {
    switch (speed) {
        case commonTypes_1.AnimationSpeed.Slow:
            return getAnimationDelayWithDecreasingGap(0.5, index);
        case commonTypes_1.AnimationSpeed.Medium:
            return getAnimationDelayWithDecreasingGap(0.25, index);
        case commonTypes_1.AnimationSpeed.Fast:
            return getAnimationDelayWithDecreasingGap(0.1, index);
    }
};
exports.getAnimationDelayValue = getAnimationDelayValue;
var getAnimationDelayWithDecreasingGap = function (maxDelay, i) {
    var lastVariableIndex = 10;
    var minDelay = 0.1;
    if (i < lastVariableIndex) {
        return getArithmeticSum(maxDelay, (minDelay - maxDelay) / lastVariableIndex, i);
    }
    else {
        return (getArithmeticSum(maxDelay, (minDelay - maxDelay) / lastVariableIndex, lastVariableIndex) +
            (i - lastVariableIndex) * minDelay);
    }
};
var getArithmeticSum = function (firstValue, difference, numberOfElements) {
    return (numberOfElements / 2) * (2 * firstValue + (numberOfElements - 1) * difference);
};
