// Generated by CoffeeScript 1.12.7

/*
  This module makes UserIcons given a user.
  @param getUserIndex [Function] (user, userList) -> userIndex [Integer] : Given a user and list
  of users, return the index of the corresponding user.

  Also generate user icon color by user name
 */

(function () {
  var UserIconFactory, getInitials, getUserIconColorByName, mountReactComponent, mountUserIcon;

  getUserIconColorByName = require("UserIconColor/UserIconColor").getUserIconColorByName;

  getInitials = require("Utils/GetInitials").getInitialsFromUser;

  mountReactComponent = require("MountReactComponent/MountReactComponent")["default"];

  mountUserIcon = require("./ReactUserIcon").mountUserIcon;

  module.exports = UserIconFactory = (function () {
    function UserIconFactory(make, createUi) {
      var create;
      this.create = function (user, options) {
        return create(user, options);
      };
      this.createEmpty = function (options) {
        return create(null, options);
      };
      this.createReactIcon = function (user) {
        var firstName, lastName;
        firstName = !!user ? user.firstName : "";
        lastName = !!user ? user.lastName : "";
        return mountUserIcon(mountReactComponent(createUi), {
          firstName: firstName,
          lastName: lastName,
        });
      };
      create = function (user, options) {
        var initials, userIconColor, userName;
        if (options == null) {
          options = {};
        }
        initials = getInitials(user);
        userName = "";
        if (!!user) {
          userName = user.firstName + user.lastName;
        }
        userIconColor = getUserIconColorByName(userName);
        return createUi(require("./UserIcon.html"), {
          getInitials: function () {
            return initials;
          },
          isEmpty: function () {
            return !initials || (user != null ? user.isPlayground : void 0);
          },
          getBackgroundColor: function () {
            return userIconColor.background;
          },
          getColor: function () {
            return userIconColor.text;
          },
          className: options.className || "user-icon--small",
        });
      };
    }

    return UserIconFactory;
  })();
}.call(this));
