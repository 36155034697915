// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function ($rootScope) {
    var bodyClass, mediaQueryFlag, printModeFlag, ref;
    bodyClass = $("body").hasClass("print");
    printModeFlag = $rootScope != null ? ((ref = $rootScope.initialData) != null ? ref.printMode : void 0) : void 0;
    mediaQueryFlag = window.matchMedia != null && window.matchMedia("print").matches;
    return bodyClass || printModeFlag || mediaQueryFlag;
  };
}.call(this));
