import "../CommonFrontend/AcceptFlow/ESign/ESignV3.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ESign/ESignV3.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW227cNhB991ewMQrYTbiV9hLbMhC0SNAPaD/AoMiRRJsiGXJka1P43wNRuytRq3Wc5CFPuxBnhmfOnJnh4i6tn2Tltyn5/4wQIb1VbJuRQkF7e0bCLxXSAUdpdEa4UU2tu5OS2Yw8MndBqbeMS13Stm3V3V2aq6v7+zT9fNmZ1cyVUtPcIJr62w7PZ4s9omVA9KL/yFnHzuv5dO4bj7LYUm40gsaMdJGA5oBPADqKsAkRcsYfSmcaLSg3yriMPFUSoYuVGyfAUceEbPweWf/x3/At3OLMCGR5OThmJLUt8UZJQc6vU7bMl+skQvC+R3BsXRQcNqvI9irYViDLCjPCmeIXPaAdhv/kF6D1AckyuSRvSRrgWCZEIBMKPCqQH8A/BOsjSpmSpaYSofYZ8egAefUaqg8E7kt7TIeYFGSBLPch0SC+ZO747k5qITlD404U8DxJrjdX+bwzspwq6ZFKrcFFpKZJ8vtsYhw0goviXQfPHbPHqh+xeh/r9qZ3NF72/eZAMZSPQXDMW+BIHUNpMrJcrG7InySN3Flwjw1Xi3R1ZCgm97DcG9X0wpbaA07phZczGoncxjopnRRj2o6VEikIdFz1Itz7JAVWQwn2PZG8RkbXyUy3JrcvZBOJniS9sWlQSQ0Z0Ub3/X9Q1vCtMBppwWqpthn5xGpp9DvCG+d3JQznXn6BjKzWNvTPXpXL9fLj6ibOPSsMb/xoDBw0vL7arDaC84l9ZhXjUBklduL9CUCigCvIyW+ytsYh0xhY6DjCbTaRUxmP256O0Xn1bbnFNRhGVVpHQ3NpWyKYryCamj8xjRFapEGCQyd/1yI8OQ8myh4+z6zOSb4j6uRc+dPk5uafj7fz8607LPgqXhD35AP5Y26jxm3+MNdu8QQcWatgfdBE31RGdnlSeASNfkYL+kecTOwUq8/OL/sflsSOITS2y9m2t7NrGB3T3jIHfWeEovbWI2ifv2OH92nQvo57bH/zTjcDws38Att1u4I2kDNs9ZDEJu7sMDw+TTq7Gwrd6a4Uk+gfiD2eJ284UzJ3QBWUjG/fvCOeaU89OFlMoo/mTPretpPTbrDSg8oW66PLe+WOW9UjcxiFmbpkinmkvJJKjHfXIPxT3snhX7pgnINFWhhXU/Cy1AwbB2QRoEhtG+weHLbBcZdGS+alXbU86GH/WOsqXwHrgB5qDtEzTep+DSUnMe//rV6FXrEc1FDbXY2mOn4NJ4enwvSVsV+zpyGfCDnE3Pwafv+qQUhGPHcAmjAtyEXNWrqbj+tNYtvLHTKxzbTBi3PrpMZLMnk4EZJkpNECCqkhvIeeX/QqTngRkh7Fef4Kew59WsUNAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = _7a468({defaultClassName:'_1mwihsy0',variantClassNames:{visualMode:{simple:'_1mwihsy1',standard:'_1mwihsy2'},isPrintMode:{true:'_1mwihsy3'}},defaultVariants:{},compoundVariants:[]});
export var header = '_1mwihsy4';
export var card = _7a468({defaultClassName:'_1mwihsy5',variantClassNames:{invalid:{true:'_1mwihsy6'}},defaultVariants:{},compoundVariants:[]});
export var cardHeader = '_1mwihsy7';
export var actions = '_1mwihsy8';
export var tabPanel = _7a468({defaultClassName:'_1mwihsy9',variantClassNames:{mode:{type:'_1mwihsya',draw:'_1mwihsyb',upload:'_1mwihsyc'}},defaultVariants:{},compoundVariants:[]});
export var inputWrapper = '_1mwihsye _1mwihsyd';
export var input = '_1mwihsyf';
export var uploadWrapper = _7a468({defaultClassName:'_1mwihsyd',variantClassNames:{hasUploaded:{true:'_1mwihsyg'}},defaultVariants:{},compoundVariants:[]});
export var upload = _7a468({defaultClassName:'_1mwihsyh',variantClassNames:{draggedOver:{true:'_1mwihsyi'}},defaultVariants:{},compoundVariants:[]});
export var uploadDescription = '_1mwihsyj';
export var canvas = _7a468({defaultClassName:'_1mwihsyk _1mwihsyd',variantClassNames:{mode:{type:'_1mwihsyl',draw:'_1mwihsym',upload:'_1mwihsyn'},hasUploaded:{true:'_1mwihsyo'}},defaultVariants:{},compoundVariants:[]});
export var disclaimer = _7a468({defaultClassName:'_1mwihsyp',variantClassNames:{invalid:{true:'_1mwihsyq'}},defaultVariants:{},compoundVariants:[]});
export var disclaimerText = '_1mwihsyr';