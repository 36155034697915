"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSocialLinks = exports.SocialShareUtmMedium = void 0;
var SocialShareUtmMedium;
(function (SocialShareUtmMedium) {
    SocialShareUtmMedium["InAppShare"] = "app-social-sharing-buttons";
    SocialShareUtmMedium["PublicPageShare"] = "qwilr-social-sharing-buttons";
})(SocialShareUtmMedium || (exports.SocialShareUtmMedium = SocialShareUtmMedium = {}));
function getSocialLinks(projectName, shareUrl, utmMedium) {
    var encodedName = encodeURIComponent(projectName);
    var shareUrlWithParams = encodeURIComponent("".concat(shareUrl, "?utm_source=qwilr&utm_medium=").concat(utmMedium, "&utm_campaign="));
    return {
        twitter: "https://twitter.com/intent/tweet?text=".concat(encodedName, "&url=").concat(shareUrlWithParams, "twitter"),
        facebook: "https://www.facebook.com/sharer/sharer.php?u=".concat(shareUrlWithParams, "facebook"),
        linkedin: "https://www.linkedin.com/shareArticle?url=".concat(shareUrlWithParams, "&title=").concat(encodedName, "linkedin"),
    };
}
exports.getSocialLinks = getSocialLinks;
