"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compactLayout = exports.videoOption = exports.pageNumberOption = exports.embeddedContentOption = exports.DEBUG_PRINT = exports.STORED_PAGE_DATA_ID = exports.AGREEMENT_VIEW = exports.USE_COMPACT_LAYOUT = exports.REMOVE_VIDEOS = exports.PAGE_SIZE = exports.ORIENTATION = exports.REMOVE_PAGE_NUMBERS = exports.REMOVE_EMBEDDED_CONTENT = void 0;
exports.REMOVE_EMBEDDED_CONTENT = "removeEmbeddedContent";
exports.REMOVE_PAGE_NUMBERS = "removePageNumbers";
exports.ORIENTATION = "orientation";
exports.PAGE_SIZE = "pageSize";
exports.REMOVE_VIDEOS = "removeVideos";
exports.USE_COMPACT_LAYOUT = "compactLayout";
exports.AGREEMENT_VIEW = "agreementView";
exports.STORED_PAGE_DATA_ID = "storedPageDataId";
exports.DEBUG_PRINT = "debugPrint";
exports.embeddedContentOption = {
    id: "include-embedded-content",
    value: "include-embedded-content",
    label: "include embedded content",
};
exports.pageNumberOption = {
    id: "include-page-numbers",
    value: "include-page-numbers",
    label: "include page numbers",
};
exports.videoOption = {
    id: "include-videos",
    value: "include-videos",
    label: "include videos",
};
exports.compactLayout = {
    id: "compact-layout",
    value: "compactLayout",
    label: "use compact layout",
};
