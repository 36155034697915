// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (fullName) {
    var firstName, lastName, names, subStrings;
    subStrings = fullName.split(" ");
    firstName = null;
    lastName = null;
    return (names =
      subStrings[1] != null && subStrings.length === 2
        ? {
            firstName: subStrings[0],
            lastName: subStrings[1],
          }
        : subStrings[0] != null && subStrings[1] == null
        ? {
            firstName: subStrings[0],
            lastName: "",
          }
        : subStrings.length >= 2
        ? ((firstName = subStrings[0]),
          delete subStrings[0],
          (lastName = _.trimStart(subStrings.join(" "))),
          {
            firstName: firstName,
            lastName: lastName,
          })
        : void 0);
  };
}.call(this));
