"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GOLDILOCKS_COLORS = exports.allDefaultColors = exports.defaultBorderColors = exports.DEFAULT_SLATE = exports.DEFAULT_GREY25 = exports.DEFAULT_GREY = exports.DEFAULT_MIDNIGHT = exports.DEFAULT_GREY10 = exports.DEFAULT_WHITE = void 0;
var DefaultColorLabel;
(function (DefaultColorLabel) {
    DefaultColorLabel["White"] = "#FFFFFF";
    DefaultColorLabel["Grey10"] = "#F2F6F7";
    DefaultColorLabel["Grey25"] = "#E0E8EC";
    DefaultColorLabel["Midnight"] = "#242C39";
    DefaultColorLabel["Grey"] = "#81A2B2";
    DefaultColorLabel["Black"] = "#000000";
    DefaultColorLabel["Slate"] = "#47535d";
})(DefaultColorLabel || (DefaultColorLabel = {}));
exports.DEFAULT_WHITE = {
    value: DefaultColorLabel.White,
    themeIndex: -1,
};
exports.DEFAULT_GREY10 = {
    value: DefaultColorLabel.Grey10,
    themeIndex: -2,
};
exports.DEFAULT_MIDNIGHT = {
    value: DefaultColorLabel.Midnight,
    themeIndex: -3,
};
exports.DEFAULT_GREY = {
    value: DefaultColorLabel.Grey,
    themeIndex: -4,
};
exports.DEFAULT_GREY25 = {
    value: DefaultColorLabel.Grey25,
    themeIndex: -5,
};
exports.DEFAULT_SLATE = {
    value: DefaultColorLabel.Slate,
    themeIndex: -15,
};
var defaultColors = [exports.DEFAULT_MIDNIGHT, exports.DEFAULT_GREY10, exports.DEFAULT_WHITE];
exports.defaultBorderColors = [exports.DEFAULT_GREY, exports.DEFAULT_MIDNIGHT, exports.DEFAULT_WHITE];
exports.allDefaultColors = [
    exports.DEFAULT_GREY25,
    exports.DEFAULT_GREY,
    exports.DEFAULT_MIDNIGHT,
    exports.DEFAULT_GREY10,
    exports.DEFAULT_WHITE,
];
exports.GOLDILOCKS_COLORS = [exports.DEFAULT_SLATE, exports.DEFAULT_MIDNIGHT, exports.DEFAULT_WHITE];
exports.default = defaultColors;
