// Generated by CoffeeScript 1.12.7
(function () {
  var DiscountAdapter,
    ItemAdapter,
    getDescriptionHtml,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  DiscountAdapter = require("./DiscountAdapter");

  getDescriptionHtml = require("./GetDescriptionHtml")["default"];

  module.exports = ItemAdapter = (function () {
    function ItemAdapter(model, sectionAdapter) {
      this.model = model;
      this.sectionAdapter = sectionAdapter;
      this.getModel = bind(this.getModel, this);
      this.getDiscountAdapter = bind(this.getDiscountAdapter, this);
      this.setVariableId = bind(this.setVariableId, this);
      this.getVariableId = bind(this.getVariableId, this);
      this.setQuantityRangeMax = bind(this.setQuantityRangeMax, this);
      this.setQuantityRangeMin = bind(this.setQuantityRangeMin, this);
      this.getQuantityRangeMax = bind(this.getQuantityRangeMax, this);
      this.getQuantityRangeMin = bind(this.getQuantityRangeMin, this);
      this.setQuantityOptional = bind(this.setQuantityOptional, this);
      this.isQuantityOptional = bind(this.isQuantityOptional, this);
      this.setOptionalSelected = bind(this.setOptionalSelected, this);
      this.isOptionalSelected = bind(this.isOptionalSelected, this);
      this.setOptional = bind(this.setOptional, this);
      this.isOptional = bind(this.isOptional, this);
      this.setTaxExempt = bind(this.setTaxExempt, this);
      this.isTaxExempt = bind(this.isTaxExempt, this);
      this.clipQuantityToRange = bind(this.clipQuantityToRange, this);
      this.setQuantity = bind(this.setQuantity, this);
      this.getQuantity = bind(this.getQuantity, this);
      this.isOnlyItem = bind(this.isOnlyItem, this);
      this.showCost = bind(this.showCost, this);
      this.showQuantity = bind(this.showQuantity, this);
      this.showRate = bind(this.showRate, this);
      this.showLineItems = bind(this.showLineItems, this);
      this.preDiscount = bind(this.preDiscount, this);
      this.cost = bind(this.cost, this);
      this.rate = bind(this.rate, this);
      this.type = bind(this.type, this);
      this.descriptionContentModel = bind(this.descriptionContentModel, this);
      this.description = bind(this.description, this);
      this.discountAdapter = null;
    }

    ItemAdapter.prototype.description = function () {
      return getDescriptionHtml(this.model.description);
    };

    ItemAdapter.prototype.descriptionContentModel = function () {
      return this.model.description;
    };

    ItemAdapter.prototype.type = function () {
      return this.model.type;
    };

    ItemAdapter.prototype.rate = function () {
      return this.model.rate;
    };

    ItemAdapter.prototype.cost = function () {
      return this.model.$cost;
    };

    ItemAdapter.prototype.preDiscount = function () {
      return this.model.$preDiscount;
    };

    /*
    Front-end view settings for this item
    @TODO (som, 2018-02-09): should probably pull these out of ItemAdapter, since it's clear
    that they are all section-level settings
     */

    ItemAdapter.prototype.showLineItems = function () {
      return this.sectionAdapter.itemsViewable();
    };

    ItemAdapter.prototype.showRate = function () {
      return this.sectionAdapter.showRate();
    };

    ItemAdapter.prototype.showQuantity = function () {
      return this.sectionAdapter.showQuantity();
    };

    ItemAdapter.prototype.showCost = function () {
      return this.sectionAdapter.showCost();
    };

    ItemAdapter.prototype.isOnlyItem = function () {
      return this.sectionAdapter.itemCount() === 1;
    };

    /*
    Item quantity
     */

    ItemAdapter.prototype.getQuantity = function () {
      return this.model.quantity;
    };

    ItemAdapter.prototype.setQuantity = function (quantity) {
      this.model.quantity = quantity;
      if (this.isQuantityOptional()) {
        return this.clipQuantityToRange();
      }
    };

    ItemAdapter.prototype.clipQuantityToRange = function () {
      var max, min;
      min = this.model.interactive.quantityRange.min;
      max = this.model.interactive.quantityRange.max;
      return (this.model.quantity = Math.min(max, Math.max(min, this.model.quantity)));
    };

    /*
    Tax exemption for this line item
     */

    ItemAdapter.prototype.isTaxExempt = function () {
      return this.model.isTaxExempt;
    };

    ItemAdapter.prototype.setTaxExempt = function (isExempt) {
      return (this.model.isTaxExempt = isExempt);
    };

    /*
    Optional line items
     */

    ItemAdapter.prototype.isOptional = function () {
      return this.model.interactive.isOptional;
    };

    ItemAdapter.prototype.setOptional = function (isOptional) {
      return (this.model.interactive.isOptional = isOptional);
    };

    ItemAdapter.prototype.isOptionalSelected = function () {
      return this.model.interactive.isOptionalSelected;
    };

    ItemAdapter.prototype.setOptionalSelected = function (selected) {
      return (this.model.interactive.isOptionalSelected = selected);
    };

    /*
    Optional item quantities
     */

    ItemAdapter.prototype.isQuantityOptional = function () {
      return this.model.interactive.isQuantityOptional;
    };

    ItemAdapter.prototype.setQuantityOptional = function (isOptional) {
      var initialMax;
      this.model.interactive.isQuantityOptional = isOptional;
      if (isOptional && this.getQuantityRangeMin() === 0 && this.getQuantityRangeMax() === 0) {
        initialMax = this.model.quantity || 0;
        return this.setQuantityRangeMax(initialMax);
      }
    };

    ItemAdapter.prototype.getQuantityRangeMin = function () {
      return this.model.interactive.quantityRange.min || 0;
    };

    ItemAdapter.prototype.getQuantityRangeMax = function () {
      return this.model.interactive.quantityRange.max || 0;
    };

    ItemAdapter.prototype.setQuantityRangeMin = function (amount) {
      var range;
      if (amount < 0) {
        amount = 0;
        throw new Error("Quantity must be more than 0");
      }
      range = this.model.interactive.quantityRange;
      range.min = amount;
      if (range.max < amount) {
        range.max = amount;
      }
      return this.clipQuantityToRange();
    };

    ItemAdapter.prototype.setQuantityRangeMax = function (amount) {
      var range;
      if (amount < 0) {
        amount = 0;
        throw new Error("Quantity must be more than 0");
      }
      range = this.model.interactive.quantityRange;
      range.max = amount;
      if (range.min > amount) {
        range.min = amount;
      }
      return this.clipQuantityToRange();
    };

    /*
    Variable id getter/setter
     */

    ItemAdapter.prototype.getVariableId = function () {
      var ref;
      return (ref = this.model.rate) != null ? ref.variableId : void 0;
    };

    ItemAdapter.prototype.setVariableId = function (variableId) {
      if (this.model.type === "variable") {
        return (this.model.rate.variableId = variableId);
      } else {
        throw new Error("Cannot set variable id of a non-variable line item");
      }
    };

    /*
    Get the discount adapter, lazy-creating it if necessary
     */

    ItemAdapter.prototype.getDiscountAdapter = function () {
      if (this.discountAdapter == null) {
        this.discountAdapter = new DiscountAdapter(this.model.discount);
      }
      return this.discountAdapter;
    };

    /*
    Get the raw model. We should only use this when necessary
     */

    ItemAdapter.prototype.getModel = function () {
      return this.model;
    };

    return ItemAdapter;
  })();
}.call(this));
