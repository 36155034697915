// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (block) {
    var calculateSectionTotal;
    calculateSectionTotal = require("./Section")(block);
    return function () {
      var applyTotalDiscount,
        calculateQuoteTotal,
        calculateSectionFractions,
        calculateTaxTotals,
        discountTotal,
        i,
        j,
        len,
        len1,
        ref,
        ref1,
        ref2,
        section,
        tax,
        taxAmountTotal,
        taxableTotal,
        total;
      total = 0;
      discountTotal = 0;
      taxableTotal = 0;
      taxAmountTotal = 0;
      ref = block.taxes;
      for (i = 0, len = ref.length; i < len; i++) {
        tax = ref[i];
        if (tax.$total == null) {
          tax.$total = 0;
        }
      }
      applyTotalDiscount = function () {
        var percent, taxableTotalDiscount, totalDiscount;
        totalDiscount = 0;
        taxableTotalDiscount = 0;
        switch (block.discount.type) {
          case "percent":
            percent = block.discount.amount / 100;
            totalDiscount = total * percent;
            taxableTotalDiscount = taxableTotal * percent;
            break;
          case "fixed":
            totalDiscount = block.discount.amount;
            taxableTotalDiscount = block.discount.amount;
        }
        discountTotal += totalDiscount;
        total = total - totalDiscount;
        return (taxableTotal = taxableTotal - taxableTotalDiscount);
      };
      calculateSectionFractions = function (section) {
        var quoteTotalWithDiscounts, sectionTotalWithDiscounts;
        sectionTotalWithDiscounts = section.total + section.$totalDiscounts;
        quoteTotalWithDiscounts = block.total + block.$totalDiscounts;
        return (section.$fractionOfTotal = sectionTotalWithDiscounts / quoteTotalWithDiscounts || 0);
      };

      /*
        Calculates the totals for a quote, including per-tax totals.
       */
      calculateQuoteTotal = function () {
        var j, len1, ref1, results, section;
        ref1 = block.sections;
        results = [];
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          section = ref1[j];
          calculateSectionTotal(section);
          total += section.total;
          discountTotal += section.$totalDiscounts;
          results.push((taxableTotal += section.$taxableTotal));
        }
        return results;
      };

      /*
        Calculates the total amount that each tax contributes to the post-tax total.
       */
      calculateTaxTotals = function () {
        var j, len1, ref1, results;
        ref1 = block.taxes;
        results = [];
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          tax = ref1[j];
          tax.$total = taxableTotal * (tax.percent / 100);
          results.push((taxAmountTotal += tax.$total));
        }
        return results;
      };
      calculateQuoteTotal();
      if ((ref1 = block.discount) != null ? ref1.amount : void 0) {
        applyTotalDiscount();
      }
      calculateTaxTotals();
      block.subtotal = total + discountTotal;
      block.total = total;
      block.totalWithTaxes = total + taxAmountTotal;
      block.$taxableTotal = taxableTotal;
      block.$totalDiscounts = discountTotal;
      ref2 = block.sections;
      for (j = 0, len1 = ref2.length; j < len1; j++) {
        section = ref2[j];
        calculateSectionFractions(section);
      }
      return block;
    };
  };
}.call(this));
