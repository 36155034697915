"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetBlockType = void 0;
var WidgetBlockType;
(function (WidgetBlockType) {
    WidgetBlockType["Table"] = "table";
    WidgetBlockType["SideBySide"] = "sideBySide";
    WidgetBlockType["Button"] = "button";
    WidgetBlockType["ContentLoop"] = "contentLoop";
    WidgetBlockType["Text"] = "text";
    WidgetBlockType["Variable"] = "variable";
})(WidgetBlockType || (exports.WidgetBlockType = WidgetBlockType = {}));
