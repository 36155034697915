// Generated by CoffeeScript 1.12.7
(function () {
  var QuantityTooltip, Template;

  Template = require("./QuantityTooltip.html");

  module.exports = QuantityTooltip = (function () {
    function QuantityTooltip(
      createUi,
      make,
      Slider,
      quoteAdapter,
      refreshTextInput,
      itemAdapter,
      variableService,
      quoteCurrencyFormatter,
    ) {
      var slider, variable;
      variable = null;
      slider = make(Slider, {
        defaultValue: itemAdapter.getQuantity(),
        readout: null,
        getMin: itemAdapter.getQuantityRangeMin,
        getMax: itemAdapter.getQuantityRangeMax,
        onChange: function (newValue) {
          itemAdapter.setQuantity(newValue);
          return refreshTextInput();
        },
      });
      this.refreshSlider = function () {
        return (slider.ui.value = itemAdapter.getQuantity());
      };
      this.onShow = function () {
        if (itemAdapter.type() === "variable") {
          return (variable = variableService.getVariable(itemAdapter.getVariableId()));
        }
      };
      this.ui = createUi(Template, {
        sliderUi: slider.ui,
        showCost: function () {
          return variable != null ? variable.hasThreshold() : void 0;
        },
        cost: function () {
          if (variable != null) {
            return quoteCurrencyFormatter.format(variable.getAmount(itemAdapter.getQuantity()));
          }
        },
        labelForPer: function () {
          return quoteAdapter.labelForField("per");
        },
        unitName: function () {
          return variable.unitName;
        },
      });
    }

    return QuantityTooltip;
  })();
}.call(this));
