"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingScheduleOrchestrator = void 0;
var Recurrence_1 = require("@CommonFrontendBackend/QuoteV2/Recurrence");
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var BillingScheduleOrchestrator = (function () {
    function BillingScheduleOrchestrator() {
        this.setRecurringFrequency = function (lineItem, frequency) {
            lineItem.billingSchedule = {
                type: Types_1.BillingScheduleType.Recurring,
                frequency: frequency,
            };
        };
        this.setOneOffBillingSchedule = function (lineItem) {
            lineItem.billingSchedule = (0, Recurrence_1.createEmptyOneOffBillingSchedule)();
        };
        this.getBillingSchedule = function (lineItem) {
            if (lineItem.billingSchedule) {
                return lineItem.billingSchedule;
            }
            return (0, Recurrence_1.createEmptyOneOffBillingSchedule)();
        };
    }
    return BillingScheduleOrchestrator;
}());
exports.BillingScheduleOrchestrator = BillingScheduleOrchestrator;
