"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDecimalPlaces = void 0;
var getDecimalPlaces = function (amount) {
    if (amount == null || Math.floor(amount) === amount) {
        return 0;
    }
    var strRepr = amount.toString().split(".")[1];
    if (strRepr == null) {
        return 0;
    }
    else {
        return strRepr.length;
    }
};
exports.getDecimalPlaces = getDecimalPlaces;
