"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectShareViewedFields = exports.ProjectOpenedFields = exports.ProjectCreatedFields = exports.ProjectShareViewedOrigin = exports.ProjectOpenedOrigin = exports.ProjectCreatedCloneOrigin = exports.TelemetryEventName = void 0;
var TelemetryEventName_1 = require("@CommonFrontendBackend/Telemetry/TelemetryEventName");
Object.defineProperty(exports, "TelemetryEventName", { enumerable: true, get: function () { return TelemetryEventName_1.TelemetryEventName; } });
var TelemetryParameterValue_1 = require("./TelemetryParameterValue");
Object.defineProperty(exports, "ProjectCreatedCloneOrigin", { enumerable: true, get: function () { return TelemetryParameterValue_1.ProjectCreatedCloneOrigin; } });
Object.defineProperty(exports, "ProjectOpenedOrigin", { enumerable: true, get: function () { return TelemetryParameterValue_1.ProjectOpenedOrigin; } });
Object.defineProperty(exports, "ProjectShareViewedOrigin", { enumerable: true, get: function () { return TelemetryParameterValue_1.ProjectShareViewedOrigin; } });
var TelemetryParameterKey_1 = require("./TelemetryParameterKey");
Object.defineProperty(exports, "ProjectCreatedFields", { enumerable: true, get: function () { return TelemetryParameterKey_1.ProjectCreatedFields; } });
Object.defineProperty(exports, "ProjectOpenedFields", { enumerable: true, get: function () { return TelemetryParameterKey_1.ProjectOpenedFields; } });
Object.defineProperty(exports, "ProjectShareViewedFields", { enumerable: true, get: function () { return TelemetryParameterKey_1.ProjectShareViewedFields; } });
