"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPricedQuoteItem = exports.isQuoteSelectionType = exports.isVariableRate = exports.isVariableRateItem = exports.ProductFilterNumberOperation = exports.ProductFilterEnumOperation = exports.ProductFilterStringOperation = exports.ProductFilterType = exports.isQuoteFixedDiscount = exports.isQuoteVariableDiscount = exports.isRecurringFrequency = exports.isRecurringSchedule = exports.DiscountType = exports.BillingScheduleType = exports.RecurringFrequency = exports.QuoteItemType = exports.QuoteSectionDisplayMode = exports.QuoteLabel = exports.QuoteSelectionType = exports.ProductAbstractSubfield = exports.ProductMappedField = void 0;
var lodash_1 = require("lodash");
var ProductMappedField;
(function (ProductMappedField) {
    ProductMappedField["Rate"] = "rate";
    ProductMappedField["Quantity"] = "quantity";
    ProductMappedField["BillingFrequency"] = "billingFrequency";
    ProductMappedField["Discount"] = "discount";
    ProductMappedField["DiscountType"] = "discountType";
})(ProductMappedField || (exports.ProductMappedField = ProductMappedField = {}));
var ProductAbstractSubfield;
(function (ProductAbstractSubfield) {
    ProductAbstractSubfield["Name"] = "name";
    ProductAbstractSubfield["Description"] = "description";
})(ProductAbstractSubfield || (exports.ProductAbstractSubfield = ProductAbstractSubfield = {}));
var QuoteSelectionType;
(function (QuoteSelectionType) {
    QuoteSelectionType["Combined"] = "combined";
    QuoteSelectionType["Multi"] = "multi";
    QuoteSelectionType["Single"] = "single";
})(QuoteSelectionType || (exports.QuoteSelectionType = QuoteSelectionType = {}));
var QuoteLabel;
(function (QuoteLabel) {
    QuoteLabel["Name"] = "name";
    QuoteLabel["Section"] = "section";
    QuoteLabel["Total"] = "total";
    QuoteLabel["SubTotal"] = "subTotal";
    QuoteLabel["ExcludingTax"] = "excludingTax";
    QuoteLabel["Discount"] = "discount";
    QuoteLabel["PercentageOff"] = "percentageOff";
    QuoteLabel["Description"] = "description";
    QuoteLabel["Item"] = "item";
    QuoteLabel["Quantity"] = "quantity";
    QuoteLabel["Price"] = "price";
    QuoteLabel["Per"] = "per";
    QuoteLabel["DueToday"] = "dueToday";
    QuoteLabel["DueTodayExcludingTax"] = "dueTodayExcludingTax";
    QuoteLabel["OneOff"] = "oneOff";
    QuoteLabel["OneOffAdjective"] = "oneOffAdjective";
    QuoteLabel["Recurring"] = "recurring";
    QuoteLabel["WeekNoun"] = "weekNoun";
    QuoteLabel["WeekAdjective"] = "weekAdjective";
    QuoteLabel["MonthNoun"] = "monthNoun";
    QuoteLabel["MonthAdjective"] = "monthAdjective";
    QuoteLabel["QuarterNoun"] = "quarterNoun";
    QuoteLabel["QuarterAdjective"] = "quarterAdjective";
    QuoteLabel["YearNoun"] = "yearNoun";
    QuoteLabel["YearAdjective"] = "yearAdjective";
    QuoteLabel["Select"] = "select";
    QuoteLabel["Selected"] = "selected";
    QuoteLabel["Recommended"] = "recommended";
    QuoteLabel["GrandTotal"] = "grandTotal";
})(QuoteLabel || (exports.QuoteLabel = QuoteLabel = {}));
var QuoteSectionDisplayMode;
(function (QuoteSectionDisplayMode) {
    QuoteSectionDisplayMode["Table"] = "table";
    QuoteSectionDisplayMode["PlanCards"] = "plans";
})(QuoteSectionDisplayMode || (exports.QuoteSectionDisplayMode = QuoteSectionDisplayMode = {}));
var QuoteItemType;
(function (QuoteItemType) {
    QuoteItemType["Text"] = "text";
    QuoteItemType["FixedCost"] = "fixedCost";
    QuoteItemType["Variable"] = "variable";
    QuoteItemType["Product"] = "product";
})(QuoteItemType || (exports.QuoteItemType = QuoteItemType = {}));
var RecurringFrequency;
(function (RecurringFrequency) {
    RecurringFrequency["Weekly"] = "weekly";
    RecurringFrequency["Monthly"] = "monthly";
    RecurringFrequency["Quarterly"] = "quarterly";
    RecurringFrequency["Yearly"] = "yearly";
})(RecurringFrequency || (exports.RecurringFrequency = RecurringFrequency = {}));
var BillingScheduleType;
(function (BillingScheduleType) {
    BillingScheduleType["Recurring"] = "recurring";
    BillingScheduleType["OneOff"] = "one-off";
})(BillingScheduleType || (exports.BillingScheduleType = BillingScheduleType = {}));
var DiscountType;
(function (DiscountType) {
    DiscountType["Percent"] = "percent";
    DiscountType["Fixed"] = "fixed";
})(DiscountType || (exports.DiscountType = DiscountType = {}));
var isRecurringSchedule = function (billingSchedule) {
    return (billingSchedule === null || billingSchedule === void 0 ? void 0 : billingSchedule.type) === BillingScheduleType.Recurring;
};
exports.isRecurringSchedule = isRecurringSchedule;
var isRecurringFrequency = function (frequency) {
    return !!frequency && Object.values(RecurringFrequency).indexOf(frequency) >= 0;
};
exports.isRecurringFrequency = isRecurringFrequency;
var isQuoteVariableDiscount = function (discount) {
    return !!(discount === null || discount === void 0 ? void 0 : discount.id);
};
exports.isQuoteVariableDiscount = isQuoteVariableDiscount;
var isQuoteFixedDiscount = function (discount) { return !!(discount === null || discount === void 0 ? void 0 : discount.type); };
exports.isQuoteFixedDiscount = isQuoteFixedDiscount;
var ProductFilterType;
(function (ProductFilterType) {
    ProductFilterType["String"] = "string";
    ProductFilterType["Enum"] = "enum";
    ProductFilterType["Number"] = "number";
})(ProductFilterType || (exports.ProductFilterType = ProductFilterType = {}));
var ProductFilterStringOperation;
(function (ProductFilterStringOperation) {
    ProductFilterStringOperation["Equals"] = "equals";
    ProductFilterStringOperation["NotEquals"] = "notEquals";
    ProductFilterStringOperation["Contains"] = "contains";
    ProductFilterStringOperation["NotContains"] = "notContains";
    ProductFilterStringOperation["StartsWith"] = "startsWith";
    ProductFilterStringOperation["NotStartsWith"] = "notStartsWith";
})(ProductFilterStringOperation || (exports.ProductFilterStringOperation = ProductFilterStringOperation = {}));
var ProductFilterEnumOperation;
(function (ProductFilterEnumOperation) {
    ProductFilterEnumOperation["Equals"] = "equals";
    ProductFilterEnumOperation["NotEquals"] = "notEquals";
})(ProductFilterEnumOperation || (exports.ProductFilterEnumOperation = ProductFilterEnumOperation = {}));
var ProductFilterNumberOperation;
(function (ProductFilterNumberOperation) {
    ProductFilterNumberOperation["Equals"] = "equals";
    ProductFilterNumberOperation["NotEquals"] = "notEquals";
    ProductFilterNumberOperation["GreaterThan"] = "greaterThan";
    ProductFilterNumberOperation["LessThan"] = "lessThan";
})(ProductFilterNumberOperation || (exports.ProductFilterNumberOperation = ProductFilterNumberOperation = {}));
function isVariableRateItem(rate) {
    return rate.variableId != null;
}
exports.isVariableRateItem = isVariableRateItem;
function isVariableRate(rate) {
    return !(0, lodash_1.isNil)(rate) && Object.keys(rate).length > 0;
}
exports.isVariableRate = isVariableRate;
function isQuoteSelectionType(selectionType) {
    return !!selectionType && Object.values(QuoteSelectionType).indexOf(selectionType) >= 0;
}
exports.isQuoteSelectionType = isQuoteSelectionType;
function isPricedQuoteItem(item) {
    return item.type === QuoteItemType.FixedCost || item.type === QuoteItemType.Variable;
}
exports.isPricedQuoteItem = isPricedQuoteItem;
