"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var defaultLabelFields = {
    name: "Default Label Set",
    section: "Section",
    total: "Total",
    subTotal: "Subtotal",
    excludingTax: "Excluding Tax",
    discount: "Discount",
    percentageOff: "Off",
    description: "Description",
    item: "Item",
    quantity: "Quantity",
    price: "Price",
    per: "Per",
    dueToday: "Due Today",
    dueTodayExcludingTax: "Due Today Excluding Tax",
    oneOff: "One Off",
    oneOffAdjective: "Once",
    recurring: "Recurring",
    weekNoun: "week",
    weekAdjective: "Weekly",
    monthNoun: "month",
    monthAdjective: "Monthly",
    quarterNoun: "quarter",
    quarterAdjective: "Quarterly",
    yearNoun: "year",
    yearAdjective: "Yearly",
    select: "Select",
    selected: "Selected",
    recommended: "Recommended",
    grandTotal: "Grand Total",
};
exports.default = defaultLabelFields;
