"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokenToCreateHtml = exports.PrintOrientation = void 0;
var Models_1 = require("../Models");
var LineBreak_1 = require("./LineBreak");
var MediaVariable_1 = require("./MediaVariable");
var TokenWidget_1 = require("./TokenWidget");
var Variables_1 = require("./Variables");
var Text_1 = require("./Text");
var Link_1 = require("./Link");
var PrintOrientation;
(function (PrintOrientation) {
    PrintOrientation["Portrait"] = "portrait";
    PrintOrientation["Landscape"] = "landscape";
})(PrintOrientation || (exports.PrintOrientation = PrintOrientation = {}));
exports.tokenToCreateHtml = new Map();
exports.tokenToCreateHtml.set(Models_1.InlineType.LineBreak, LineBreak_1.createHtmlFromLineBreak);
exports.tokenToCreateHtml.set(Models_1.InlineType.Link, Link_1.createHtmlFromLink);
exports.tokenToCreateHtml.set(Models_1.BlockType.MediaVariable, MediaVariable_1.createHtmlFromMediaVariable);
exports.tokenToCreateHtml.set(Models_1.InlineType.TokenWidget, TokenWidget_1.createHtmlFromTokenWidget);
exports.tokenToCreateHtml.set(Models_1.InlineType.Variable, Variables_1.createHtmlFromVariable);
exports.tokenToCreateHtml.set(Models_1.BlockType.Caption, function (_token, options) {
    var innerHtml = options.innerHtml;
    if (innerHtml === "" || /^<span[^>]*><\/span>$/.test(innerHtml)) {
        innerHtml = "<br />";
    }
    return "<p class=\"p-widget__spacing-shim project-block__block-element\">".concat(innerHtml, "</p>");
});
exports.tokenToCreateHtml.set("text", Text_1.createHtmlFromText);
