"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectCreatedFields = exports.ProjectAccessSettingChangedFields = exports.ProjectOpenedFields = exports.ProjectExpiryChangedFields = exports.ProjectShareViewedFields = void 0;
var ProjectShareViewedFields;
(function (ProjectShareViewedFields) {
    ProjectShareViewedFields["Origin"] = "project_share_viewed_origin";
    ProjectShareViewedFields["Version"] = "project_share_viewed_version";
})(ProjectShareViewedFields || (exports.ProjectShareViewedFields = ProjectShareViewedFields = {}));
var ProjectExpiryChangedFields;
(function (ProjectExpiryChangedFields) {
    ProjectExpiryChangedFields["IsTemplate"] = "page_expiry_changed_is_template";
    ProjectExpiryChangedFields["Action"] = "page_expiry_changed_action";
    ProjectExpiryChangedFields["PageState"] = "page_expiry_changed_page_state";
    ProjectExpiryChangedFields["Days"] = "page_expiry_changed_days";
})(ProjectExpiryChangedFields || (exports.ProjectExpiryChangedFields = ProjectExpiryChangedFields = {}));
var ProjectOpenedFields;
(function (ProjectOpenedFields) {
    ProjectOpenedFields["Origin"] = "project_opened_origin";
    ProjectOpenedFields["AuthUserId"] = "auth_user_id";
    ProjectOpenedFields["AccessLevel"] = "access_level";
    ProjectOpenedFields["AccessInFolder"] = "access_in_folder";
})(ProjectOpenedFields || (exports.ProjectOpenedFields = ProjectOpenedFields = {}));
var ProjectAccessSettingChangedFields;
(function (ProjectAccessSettingChangedFields) {
    ProjectAccessSettingChangedFields["IsTemplate"] = "page_access_changed_is_template";
    ProjectAccessSettingChangedFields["Action"] = "page_access_changed_action";
    ProjectAccessSettingChangedFields["EventOrigin"] = "event_origin";
})(ProjectAccessSettingChangedFields || (exports.ProjectAccessSettingChangedFields = ProjectAccessSettingChangedFields = {}));
var ProjectCreatedFields;
(function (ProjectCreatedFields) {
    ProjectCreatedFields["ClonedOrigin"] = "project_cloned_origin";
})(ProjectCreatedFields || (exports.ProjectCreatedFields = ProjectCreatedFields = {}));
