// Generated by CoffeeScript 1.12.7

/*
  A recurring issue in our Angular code is not knowing when DOM has actually
  been rendered and we have to do things like:
  @ui =
    $init: (scope) ->
      scope.$watch 'ui.dom', (dom) ->
        ...

  So that we can ensure that the relevant piece of DOM has been initialised.
  The $onElementRendered event handler makes that a built-in part of the
  dom directive. Additionally, we've created an $onElementRemoved event for
  plugging into the end of the DOM's lifecycle.
 */

(function () {
  module.exports = angular.module("qwilr.domtrick", []).directive("dom", function ($timeout) {
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        var propertyName;
        propertyName = attrs.dom || "element";
        scope.$parent.ui[propertyName] = element;
        if (scope.$parent.ui["$onElementRendered"] != null) {
          return scope.$parent.ui["$onElementRendered"](element, propertyName, scope);
        }
      },
    };
  });
}.call(this));
