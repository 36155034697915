import "../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormScreen.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUwY7bIBC95yvooVJzILKz6SYlt/7CHnqMxjC2Z4uBAt44qfrvlXHXdpJtlbbyATHMG948v2HldrIEm7HvC8acDRTJGsE8aoj0gvvFj8VqSMlTirTaesFewH/gPG04SWueWikxhMMhL/T2+TmPyxlynZBHUrEWbJe5br9grEaq6jjtC+sVeu5BURsEy7PsfR9WFJyGk2CVJ9UHnAaJnCI2QTCJJqJPcJBfK29bo/gbFEPrS5A40quWw5UdDzUoexSMTMDIsvTlrrtAO08N+NPnxHCskacaoKkyPKAuJzZj4w+p8dKayAOdUbB85zr2jhpnfQQT96+nx19iPGbZxfFYaZMqjWKUGpNo/coVeZTDb5NWt42Z4T4mXAXuVY/gQJKpeNd1emrl2/x3Pf4OMwOYOWD7D5fs/vaST1cOhSJY3UbsZdBYRsGyWTb8IdsPWs/TN6Ncq4hd5GRcGw+HtKRKV+7MJsdebHhhY7SNYGvXsWA1qQsj1QiKTDV2iMlCDlQf5WQ0GbyfmBBpFmqrFfrE0vYaxtNdJTQUqG/8mYbxBrodnfHfLLZ3sBgk6wNPdEaecma+WHfLm8GZIF9ShDeoqG1GzMMwrW88DT2bMW2zvJrOxDV6MKG0vhFMgqMIms79y/gTrevp8DwFAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AcceptFlow/ReactComponents/FormComponents/Esignature.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW4W7jNgz+36fQUAxodlNmO2nTuMAw4A57gN0DBLJEJ0plySfRbdKh7z5IdhzL8WXF3S8DFEmTHz9+0nyTHlPbfKtT8u8NIQXjz1trGi0oN8rYnLzuJMKTPzJWgKWWCdm4nLwwe0dpa/wn2Cg3Gq1Rm01aqNV+n6bb2TkwJ2l9IM4oKcjtY8qyIlsmTzfvN/NTBVlbwaX3l1W6Xqwj30Xw3YHc7jAnnCl+1xbU1fBVvgGt+kqyZEY+kTSUUzMhpN5SBSWe2nA14952cOfin4O3kK5W7JiTUsHBG5iSW00lQuVy4tAC8p237xuHsjyGAkBjTnxOoAXgK4AeAFgYRFNNwSGiFlMyR1a40OiW1TlJpo43G6mF5AyN/c4Ab5Pk8X5VTAcjK6iSDqnUGmwEapokv042xkEj2CjfMkR2yF6AOkR1P4sC79tA4yRKo3PCCmdU0xJOagc4bvthFGBBMZQvIYC5GjhSy1CanGTzxZr8QdIofBXCY8fFPF1cOLIPFXal58Ea1DGTtlaKIbCXXIo4BjrmRRFjHcPDw+GrFLg7T/C0UslHWPiYTCz71VajnSFJ62waVFJDTrTRrXz0xDzbSqORlqyS6piTL6ySRv9OeGNdN9Bw7uQb5GSxrMP6nUidLbPPI03geWl44wYq0q/AcnW/uBecj/zzWjEOO6NEx/2fKEiUsIKC/CKr2lhkGgMKHiM85iNyiWGNp0lk/SROSqaMpTtgHvQeYghcOu3oQ+aLGGSGkLmnWgv04Lz8f1rH0z1raFpFau7LFcztIJLzn7gmEA5IA/PPEjOhv/5LhbTA2w64UU2lrwrVaKHO5iCrV/sdQLedIlaarNd/f36aFl5/WPLFIkqzI3+S30Kqitmt1P3840WWU4scS/PAex+8e7a162qk75PCC2h0E1xQPxJUxUExr/VQmvrGxso4FAwZY2yuqDuai2Fd5vL8MAxz4m/34Xqe3hCTgxLh0A/qI/x1jS0Zh/OPd+HH3ThtO6KPX4H1gAzdFLxy0n7Y8yz414+8ZGbZfR9InyAhgwHMnxX1mzRKP4Zh8P9vwYFSxjnUGIJPwAyFyNv7HpazkRq2nt4QXl6KFaDoecWzwzngtetrmST92qNl2pXGVv4tV0tkSr6FoStAT8cOx3BdRcXjsLu2iIs+Yrib4WKtx/rZBAAdKOC42YCCCjRSClqMXlcTqxHHSl03GL1oTwF/VSAkI45bAE2YFuSuYgfalbS8T+rDrAsUx1wbvLutrdQ4I6PXCSFJThotoJQawpPi/WoU/04UIelFnvf/ALzJhgAZDAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var signatureContainer = '_1y1ruqp0';
export var card = _7a468({defaultClassName:'_1y1ruqp1',variantClassNames:{invalid:{true:'_1y1ruqp2'}},defaultVariants:{},compoundVariants:[]});
export var cardHeader = '_1y1ruqp3';
export var actions = '_1y1ruqp4';
export var tabPanel = _7a468({defaultClassName:'_1y1ruqp6',variantClassNames:{mode:{type:'_1y1ruqp7',draw:'_1y1ruqp8',upload:'_1y1ruqp9'}},defaultVariants:{},compoundVariants:[]});
export var inputWrapper = _7a468({defaultClassName:'_1y1ruqpa',variantClassNames:{isSimpleMode:{true:'_1y1ruqpb'}},defaultVariants:{},compoundVariants:[]});
export var input = _7a468({defaultClassName:'_1y1ruqpc',variantClassNames:{isSimpleMode:{true:'_1y1ruqpd'}},defaultVariants:{},compoundVariants:[]});
export var uploadWrapper = _7a468({defaultClassName:'_1y1ruqp5',variantClassNames:{hasUploaded:{true:'_1y1ruqpe'}},defaultVariants:{},compoundVariants:[]});
export var upload = _7a468({defaultClassName:'_1y1ruqpf',variantClassNames:{draggedOver:{true:'_1y1ruqpg'}},defaultVariants:{},compoundVariants:[]});
export var uploadDescription = '_1y1ruqph';
export var canvas = _7a468({defaultClassName:'_1y1ruqpi _1y1ruqp5',variantClassNames:{mode:{type:'_1y1ruqpj',draw:'_1y1ruqpk',upload:'_1y1ruqpl'},hasUploaded:{true:'_1y1ruqpm'}},defaultVariants:{},compoundVariants:[]});
export var error = '_1y1ruqpn';
export var errorIcon = '_1y1ruqpo';
export var errorText = '_1y1ruqpp';
export var simpleModeLabel = '_1y1ruqpq';
export var optionMenuItem = _7a468({defaultClassName:'',variantClassNames:{isSimpleMode:{true:'_1y1ruqpr',false:'_1y1ruqps'}},defaultVariants:{},compoundVariants:[]});
export var label = '_1y1ruqpt';
export var select = '_1y1ruqpu';