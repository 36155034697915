// This file was modified from the original Coffeescript source to:
// - add strikethrough support: https://github.com/qwilr/qwilr/pull/6893
(function () {
  var CurrencyDecimalFormatter, DiscountLabel, QuoteSection, isUsingTCDC, quoteSelection;

  CurrencyDecimalFormatter = require("Utils/CurrencyDecimalFormatter");

  DiscountLabel = require("./DiscountLabel");

  quoteSelection = require("./quoteSectionSelection")["default"];

  isUsingTCDC = require("@CommonFrontendBackend/FeatureFlag").isUsingTCDC;

  /*
  The view for a single quote section
   */

  module.exports = QuoteSection = (function () {
    function QuoteSection(
      createUi,
      make,
      invoke,
      di,
      uniqueId,
      $timeout,
      isEditor,
      isMobile,
      sectionAdapter,
      quoteAdapter,
      quoteView,
      deleteSection,
      selectSection,
      canMoveSectionUp,
      canMoveSectionDown,
      clientViewComponents,
      disableInteractives,
      discountService,
      hint,
      $sce,
      block,
      usingFeature,
      brandStore,
      blockStore,
    ) {
      var buildLineItemHints,
        createItemView,
        editor,
        formatCurrency,
        ignoredElemsForToolbar,
        inlineToolbar,
        inlineToolbarOptions,
        isTCDCEnabled,
        itemFactory,
        itemViews,
        lineItemButtons,
        moveUpDown,
        sectionSettings,
        showLineItemHint,
        swapItems,
        uniqueSectionId,
        useEditorControls;
      useEditorControls = isEditor && !isMobile;
      sectionSettings = null;
      this.sectionAdapter = sectionAdapter;
      itemViews = [];
      inlineToolbar = {
        ui: null,
      };
      itemFactory = make(require("./QuoteItemFactory"), {
        sectionView: this,
        useEditorControls: useEditorControls,
      });
      formatCurrency = invoke(CurrencyDecimalFormatter);
      createItemView = (function (_this) {
        return function (itemAdapter, index) {
          var view;
          view = itemFactory.createItem(itemAdapter);
          itemViews.splice(index, 0, view);
          return view;
        };
      })(this);
      buildLineItemHints = function () {
        hint.small({
          targetSelector: "#" + uniqueSectionId + " .toggle-icon__line-items",
          hint: function () {
            if (sectionAdapter.itemsViewable()) {
              return "hide all columns";
            } else {
              return "show all columns";
            }
          },
          spacing: 0,
          position: "top",
        });
        hint.small({
          targetSelector: "#" + uniqueSectionId + " .toggle-icon__rate",
          hint: function () {
            if (sectionAdapter.showRate()) {
              return "hide column";
            } else {
              return "show column";
            }
          },
          spacing: 0,
          position: "top",
        });
        hint.small({
          targetSelector: "#" + uniqueSectionId + " .toggle-icon__quantity",
          hint: function () {
            if (sectionAdapter.showQuantity()) {
              return "hide column";
            } else {
              return "show column";
            }
          },
          spacing: 0,
          position: "top",
        });
        return hint.small({
          targetSelector: "#" + uniqueSectionId + " .toggle-icon__cost",
          hint: function () {
            if (sectionAdapter.showCost()) {
              return "hide column";
            } else {
              return "show column";
            }
          },
          spacing: 0,
          position: "top",
        });
      };
      this.deleteItem = (function (_this) {
        return function (itemView) {
          var index;
          if (sectionAdapter.itemCount() > 1) {
            index = _.findIndex(itemViews, itemView);
            if (index >= 0) {
              sectionAdapter.deleteItem(index);
              return itemViews.splice(index, 1);
            }
          }
        };
      })(this);

      /*
        Move an item up one place, if it is not already the top item
       */
      this.moveItemUp = (function (_this) {
        return function (itemView) {
          var index;
          index = _.findIndex(itemViews, itemView);
          if (index >= 1) {
            return swapItems(index, index - 1);
          }
        };
      })(this);

      /*
        Move an item down one place, if it is not already the bottom item
       */
      this.moveItemDown = (function (_this) {
        return function (itemView) {
          var index;
          index = _.findIndex(itemViews, itemView);
          if (index < itemViews.length - 1) {
            return swapItems(index, index + 1);
          }
        };
      })(this);
      swapItems = (function (_this) {
        return function (indexA, indexB) {
          var itemToMove;
          sectionAdapter.swapItems(indexA, indexB);
          itemToMove = itemViews[indexA];
          itemViews[indexA] = itemViews[indexB];
          return (itemViews[indexB] = itemToMove);
        };
      })(this);
      this.indexForView = (function (_this) {
        return function (itemView) {
          return _.findIndex(itemViews, itemView) + 1;
        };
      })(this);
      this.insertItem = (function (_this) {
        return function (prevItem, type) {
          var a, index, itemView;
          index = _this.indexForView(prevItem);
          if (index >= 0) {
            a = sectionAdapter.createItem(index, type);
            itemView = createItemView(a, index);
            return $timeout(function () {
              var ref;
              return (ref = $("#" + itemView.ui.uniqueId + " .description content-area")) != null
                ? ref.focus()
                : void 0;
            });
          }
        };
      })(this);
      this.rebuildItems = (function (_this) {
        return function () {
          var i, index, itemAdapter, len, ref, results;
          itemViews = [];
          ref = sectionAdapter.getItemAdapters();
          results = [];
          for (index = i = 0, len = ref.length; i < len; index = ++i) {
            itemAdapter = ref[index];
            results.push(createItemView(itemAdapter, index));
          }
          return results;
        };
      })(this);
      this.setSectionId = (function (_this) {
        return function (id) {
          return (_this.ui.sectionId = id);
        };
      })(this);
      this.insertSavedLineItems = (function (_this) {
        return function (itemsToInsert, options) {
          var i, index, item, len;
          index = -1;
          if (options && options.prevItem) {
            index = _this.indexForView(options.prevItem);
          }
          if (index < 0) {
            index = itemViews.length - 1;
          }
          for (i = 0, len = itemsToInsert.length; i < len; i++) {
            item = itemsToInsert[i];
            sectionAdapter.createItemFromSaved(index, item);
            index++;
          }
          return _this.rebuildItems();
        };
      })(this);
      this.createLineItemByType = (function (_this) {
        return function (type) {
          return _this.insertItem(itemViews[itemViews.length - 1], type);
        };
      })(this);
      if (isEditor) {
        editor = make(di.get("Editor"), {
          model: sectionAdapter.descriptionModel(),
          options: {
            enableWidgets: false,
            autoFocus: false,
            buttonNames: ["bold", "italic", "strikethrough", "orderedList", "h2", "link", "unorderedList"],
            allowTab: true,
          },
          configuration: "quote",
          showSpinner: false,
        });
        inlineToolbarOptions = [
          {
            value: "move up",
            hint: "move up",
            hintSize: "small",
            isDisabled: (function (_this) {
              return function () {
                return !canMoveSectionUp(_this);
              };
            })(this),
            onClick: (function (_this) {
              return function () {
                return quoteView.moveSectionUp(_this);
              };
            })(this),
            icon: "arrow-up",
            isNonSelectable: true,
          },
          {
            value: "move down",
            hint: "move down",
            hintSize: "small",
            isDisabled: (function (_this) {
              return function () {
                return !canMoveSectionDown(_this);
              };
            })(this),
            onClick: (function (_this) {
              return function () {
                return quoteView.moveSectionDown(_this);
              };
            })(this),
            icon: "arrow-down",
            isNonSelectable: true,
          },
          {
            value: "clone",
            hint: "clone",
            hintSize: "small",
            isDisabled: (function (_this) {
              return function () {
                return false;
              };
            })(this),
            onClick: (function (_this) {
              return function () {
                return quoteView.cloneSection(_this);
              };
            })(this),
            icon: "clone",
            isNonSelectable: true,
          },
          {
            value: "delete",
            hint: "delete section",
            isDisabled: function () {
              return quoteAdapter.sectionCount() <= 1;
            },
            onClick: (function (_this) {
              return function () {
                return deleteSection(_this);
              };
            })(this),
            hintSize: "small",
            icon: "delete",
            isNonSelectable: true,
          },
        ];
        inlineToolbar = make(di.get("attachQuoteAcceptToolbar"), {
          options: inlineToolbarOptions,
          block: block,
          brandStore: brandStore,
          blockStore: blockStore,
        });
      }
      isTCDCEnabled = isUsingTCDC(usingFeature);
      uniqueSectionId = "quote-section--" + uniqueId(8);
      showLineItemHint = hint.small({
        targetSelector: "#" + uniqueSectionId + " .section-header .collapse-line-items",
        hint: function () {
          if (sectionAdapter.itemsCollapsed()) {
            return "Open section";
          } else {
            return "Close section";
          }
        },
      });
      ignoredElemsForToolbar = [
        ".quote-items-table",
        ".section-description",
        ".quote-item",
        ".item-settings-menu-tooltip",
        ".quantity-tooltip",
      ].join(", ");
      this.ui = createUi(require("./QuoteSection.html"), {
        isTCDCEnabled: isTCDCEnabled,
        useEditorControls: useEditorControls,
        isMobile: isMobile,
        disableInteractives: disableInteractives,
        descriptionEditorUi: editor != null ? editor.ui : void 0,
        discountLabel: make(DiscountLabel, {
          discountAdapter: sectionAdapter.getDiscountAdapter(),
        }),
        sectionSettingsUi: null,
        sectionId: null,
        uniqueId: uniqueSectionId,
        inlineToolbarUi: inlineToolbar.ui,
        showInlineToolbar: false,
        isActive: (function (_this) {
          return function () {
            return quoteSelection.activeSectionId === _this.ui.uniqueId;
          };
        })(this),
        handleSectionClick: (function (_this) {
          return function (event) {
            var targetElem;
            targetElem = $(event.target);
            if (
              isTCDCEnabled &&
              !targetElem.is(ignoredElemsForToolbar) &&
              targetElem.parents(ignoredElemsForToolbar).length === 0
            ) {
              if (!_this.ui.isActive()) {
                quoteSelection.activeSectionId = _this.ui.uniqueId;
                return (_this.ui.showInlineToolbar = true);
              } else {
                return (_this.ui.showInlineToolbar = !_this.ui.showInlineToolbar);
              }
            }
          };
        })(this),
        shouldRemoveBorder: function () {
          if (block.style != null) {
            return block.style.quoteBackgroundOn === false;
          }
        },
        isOnlySection: function () {
          return quoteAdapter.sectionCount() === 1;
        },
        getItems: (function (_this) {
          return function () {
            if (sectionAdapter.hasMismatchedLengths()) {
              _this.rebuildItems();
            }
            return itemViews;
          };
        })(this),
        getDescription: function () {
          return $sce.trustAsHtml(sectionAdapter.descriptionContent());
        },
        showSubtotal: function () {
          return sectionAdapter.showSubtotal();
        },
        getPreDiscount: function () {
          return formatCurrency(sectionAdapter.getPreDiscount());
        },
        getDiscountName: function () {
          var savedDiscount;
          if (!sectionAdapter.getDiscountAdapter().getModel().id) {
            return sectionAdapter.getDiscountAdapter().getModel().name;
          }
          savedDiscount = discountService.findDiscountById(sectionAdapter.getDiscountAdapter().getModel().id);
          if (savedDiscount) {
            return savedDiscount.name;
          }
        },
        hasDiscount: function () {
          return sectionAdapter.getDiscountAdapter().hasNonZeroDiscount();
        },
        showSectionIds: function () {
          return quoteAdapter.showSectionIds();
        },
        getSubtotal: function () {
          return formatCurrency(sectionAdapter.getSubTotal());
        },
        toggleItemsCollapsed: _.throttle(
          function (event) {
            event.stopPropagation();
            showLineItemHint.hideQuickly();
            sectionAdapter.setItemsCollapsed(!sectionAdapter.itemsCollapsed());
            if (!sectionAdapter.itemsCollapsed()) {
              return buildLineItemHints();
            }
          },
          300,
          {
            leading: true,
          },
        ),
        toggleSelected: (function (_this) {
          return function (event) {
            if (!disableInteractives) {
              selectSection(_this, !sectionAdapter.isSelected());
            }
            return event.stopPropagation();
          };
        })(this),
        isSelected: (function (_this) {
          return function () {
            return sectionAdapter.isSelected();
          };
        })(this),
        isSelectable: function () {
          var ref;
          return (
            ((ref = quoteAdapter.getSelectionType()) === "single" || ref === "multi") && !sectionAdapter.isMandatory()
          );
        },
        isMultiSelect: function () {
          return quoteAdapter.getSelectionType() === "multi";
        },
        isSingleSelect: function () {
          return quoteAdapter.getSelectionType() === "single";
        },
        itemsCollapsed: function () {
          return sectionAdapter.itemsCollapsed();
        },
        showRate: function () {
          return sectionAdapter.showRate();
        },
        showLineItems: function () {
          return sectionAdapter.itemsViewable();
        },
        showCost: function () {
          return sectionAdapter.showCost();
        },
        showQuantity: function () {
          return sectionAdapter.showQuantity();
        },
        deleteSection: (function (_this) {
          return function () {
            return deleteSection(_this);
          };
        })(this),
        labelForField: function (field) {
          return sectionAdapter.labelForField(field);
        },
        toggleItemRateDisplay: (function (_this) {
          return function () {
            return sectionAdapter.setShowRate(!sectionAdapter.showRate());
          };
        })(this),
        toggleQuantityDisplay: (function (_this) {
          return function () {
            return sectionAdapter.setShowQuantity(!sectionAdapter.showQuantity());
          };
        })(this),
        toggleCostDisplay: (function (_this) {
          return function () {
            return sectionAdapter.setShowCost(!sectionAdapter.showCost());
          };
        })(this),
        toggleLineItemDisplay: (function (_this) {
          return function () {
            sectionAdapter.setItemsViewable(!sectionAdapter.itemsViewable());
            sectionAdapter.setShowRate(sectionAdapter.itemsViewable());
            sectionAdapter.setShowQuantity(sectionAdapter.itemsViewable());
            return sectionAdapter.setShowCost(sectionAdapter.itemsViewable());
          };
        })(this),
        $onElementRendered: function () {
          return $timeout(buildLineItemHints);
        },
      });
      this.rebuildItems();
      if (useEditorControls) {
        sectionSettings = make(clientViewComponents.SectionSettings, {
          sectionView: this,
          sectionAdapter: sectionAdapter,
        });
        moveUpDown = make(clientViewComponents.MoveUpDown, {
          description: "",
          viewToMove: this,
          moveUp: quoteView.moveSectionUp,
          moveDown: quoteView.moveSectionDown,
        });
        lineItemButtons = make(clientViewComponents.LineItemButtons, {
          insertSavedLineItems: this.insertSavedLineItems,
          createLineItemByType: this.createLineItemByType,
          itemViews: itemViews,
        });
        this.ui.sectionSettingsUi = sectionSettings.ui;
        this.ui.moveUpDownUi = moveUpDown.ui;
        this.ui.lineItemButtons = lineItemButtons.ui;
      }
    }

    return QuoteSection;
  })();
}.call(this));
