"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimatedBackground = void 0;
var react_1 = __importStar(require("react"));
var AnimatedBackground = function (_a) {
    var children = _a.children, pulsing = _a.pulsing, pageUnlocked = _a.pageUnlocked;
    var animatedBackgroundRef = react_1.default.useRef(null);
    (0, react_1.useEffect)(function () {
        var shimmer = false;
        var interval;
        var background = animatedBackgroundRef.current;
        var applyShimmer = function () {
            var _a, _b;
            shimmer = true;
            (_a = background === null || background === void 0 ? void 0 : background.style) === null || _a === void 0 ? void 0 : _a.setProperty("--shimmer-opacity", "1");
            (_b = background === null || background === void 0 ? void 0 : background.style) === null || _b === void 0 ? void 0 : _b.setProperty("--shimmer-saturation", "1.2");
        };
        var resetShimmer = function () {
            var _a, _b;
            shimmer = false;
            (_a = background === null || background === void 0 ? void 0 : background.style) === null || _a === void 0 ? void 0 : _a.setProperty("--shimmer-opacity", "0.4");
            (_b = background === null || background === void 0 ? void 0 : background.style) === null || _b === void 0 ? void 0 : _b.setProperty("--shimmer-saturation", "1");
        };
        if (pulsing) {
            applyShimmer();
            interval = setInterval(function () {
                if (shimmer) {
                    resetShimmer();
                }
                else {
                    applyShimmer();
                }
            }, 500);
        }
        return function () {
            clearInterval(interval);
            resetShimmer();
        };
    }, [pulsing]);
    return (react_1.default.createElement("div", { className: "animated-background", "data-fade-bg": pageUnlocked },
        react_1.default.createElement("div", { className: "animation", "data-unlocked": pageUnlocked && !pulsing, "data-shimmer": pulsing, ref: animatedBackgroundRef },
            react_1.default.createElement("div", { className: "animation__ellipse" }),
            react_1.default.createElement("div", { className: "animation__ellipse" }),
            react_1.default.createElement("div", { className: "animation__ellipse" }),
            react_1.default.createElement("div", { className: "animation__ellipse" }),
            react_1.default.createElement("div", { className: "animation__ellipse" }),
            react_1.default.createElement("div", { className: "animation__texture" })),
        children));
};
exports.AnimatedBackground = AnimatedBackground;
