"use strict";
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductStaticWellKnownMapping = void 0;
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var lodash_1 = require("lodash");
var Types_2 = require("./Types");
var ProductStaticWellKnownMapping = (function () {
    function ProductStaticWellKnownMapping() {
    }
    ProductStaticWellKnownMapping.getMappedField = function (productTokenId, field) {
        if (!this.isKnownProductTokenId(productTokenId)) {
            return undefined;
        }
        return (0, lodash_1.get)(ProductStaticWellKnownMapping.mappings, [productTokenId, field]);
    };
    ProductStaticWellKnownMapping.getKnownProductProperties = function (productTokenId) {
        if (!this.isKnownProductTokenId(productTokenId)) {
            return undefined;
        }
        return Object.values(ProductStaticWellKnownMapping.mappings[productTokenId]).filter(function (field) { return field.id; });
    };
    ProductStaticWellKnownMapping.getKnownProductTokenIds = function () {
        return Object.keys(ProductStaticWellKnownMapping.mappings);
    };
    ProductStaticWellKnownMapping.getProductNameandDescription = function (productTokenId) {
        return Object.values(ProductStaticWellKnownMapping.defaultMappings[productTokenId]).filter(function (field) { return field.id; });
    };
    ProductStaticWellKnownMapping.hasAllProperties = function (productTokenId, itemProperties) {
        if (!ProductStaticWellKnownMapping.defaultMappings[productTokenId]) {
            return productTokenId.includes(Types_1.ProviderType.Hubspot) || productTokenId.includes(Types_1.ProviderType.Salesforce);
        }
        var propertiesFromMappings = ProductStaticWellKnownMapping === null || ProductStaticWellKnownMapping === void 0 ? void 0 : ProductStaticWellKnownMapping.getProductNameandDescription(productTokenId);
        var propertiesFromDefaultMappings = ProductStaticWellKnownMapping === null || ProductStaticWellKnownMapping === void 0 ? void 0 : ProductStaticWellKnownMapping.getKnownProductProperties(productTokenId);
        var itemPropertyIds = itemProperties.map(function (item) { return item.id; });
        if (propertiesFromMappings && propertiesFromDefaultMappings) {
            var minimumRequiredIds = propertiesFromMappings
                .concat(propertiesFromDefaultMappings)
                .map(function (property) { return property.id; })
                .filter(lodash_1.isString);
            return minimumRequiredIds.every(function (requiredId) { return itemPropertyIds.includes(requiredId); });
        }
        return false;
    };
    ProductStaticWellKnownMapping.isKnownProductTokenId = function (tokenId) {
        return !!ProductStaticWellKnownMapping.mappings[tokenId];
    };
    ProductStaticWellKnownMapping.supportsMappedProductField = function (field, tokenId) {
        return !!ProductStaticWellKnownMapping.getMappedProductFieldName(field, tokenId);
    };
    ProductStaticWellKnownMapping.getMappedProductFieldName = function (field, tokenId) {
        if (!tokenId) {
            return "";
        }
        var mappedField = (0, lodash_1.get)(ProductStaticWellKnownMapping.mappings, [tokenId, field]);
        return mappedField === null || mappedField === void 0 ? void 0 : mappedField.label;
    };
    ProductStaticWellKnownMapping.getProductItemProperties = function (field, productToken, itemProperties) {
        if (!productToken || !productToken.substitutionToken || !itemProperties) {
            return undefined;
        }
        var index = productToken.substitutionToken.providerType == Types_1.ProviderType.Zoho ||
            productToken.substitutionToken.providerType == Types_1.ProviderType.Pipedrive
            ? productToken.substitutionToken.id
            : productToken.substitutionToken.providerType;
        var mapping = (0, lodash_1.get)(ProductStaticWellKnownMapping.defaultMappings, [index, field]);
        if (!mapping) {
            return undefined;
        }
        return itemProperties.find(function (t) { return (t.hierarchyInfo ? t.id === mapping.id : t.apiReference === mapping.name); });
    };
    ProductStaticWellKnownMapping.mappings = (_a = {},
        _a["token:salesforce:opportunity:products"] = (_b = {},
            _b[Types_2.ProductMappedField.Quantity] = {
                id: "token:salesforce:opportunity:products:Quantity",
                label: "Quantity",
                apiName: "Quantity",
            },
            _b[Types_2.ProductMappedField.Rate] = {
                id: "token:salesforce:opportunity:products:Sales_Price",
                label: "Sales Price",
                apiName: "Sales_price",
            },
            _b),
        _a["token:hubspot:deal:products"] = (_c = {},
            _c[Types_2.ProductMappedField.Quantity] = {
                id: "token:hubspot:deal:products:Quantity",
                label: "Quantity",
                apiName: "Quantity",
            },
            _c[Types_2.ProductMappedField.Rate] = {
                id: "token:hubspot:deal:products:Unit_price",
                label: "Unit price",
                apiName: "Unit_price",
            },
            _c[Types_2.ProductMappedField.BillingFrequency] = {
                id: "token:hubspot:deal:products:Recurring_Billing_Frequency",
                label: "Recurring Billing Frequency",
                apiName: "Recurring_Billing_Frequency",
            },
            _c),
        _a["token:zoho:Quotes:Products"] = (_d = {},
            _d[Types_2.ProductMappedField.Quantity] = {
                id: "token:zoho:Quotes:Products:Quantity",
                label: "Quantity",
                apiName: "Quantity",
            },
            _d[Types_2.ProductMappedField.Rate] = {
                id: "token:zoho:Quotes:Products:List_Price",
                label: "List Price",
                apiName: "List_Price",
            },
            _d[Types_2.ProductMappedField.Discount] = {
                id: "token:zoho:Quotes:Products:Discount",
                label: "Discount",
                apiName: "Discount",
                type: Types_2.DiscountType.Fixed,
            },
            _d),
        _a["token:zoho:Leads:Products"] = (_e = {},
            _e[Types_2.ProductMappedField.Quantity] = {
                label: "Quantity",
                apiName: "Quantity",
            },
            _e[Types_2.ProductMappedField.Rate] = {
                id: "token:zoho:Leads:Products:Unit_Price",
                label: "Unit Price",
                apiName: "Unit_Price",
            },
            _e),
        _a["token:zoho:Accounts:Products"] = (_f = {},
            _f[Types_2.ProductMappedField.Quantity] = {
                label: "Quantity",
                apiName: "Quantity",
            },
            _f[Types_2.ProductMappedField.Rate] = {
                id: "token:zoho:Accounts:Products:Unit_Price",
                label: "Unit Price",
                apiName: "Unit_Price",
            },
            _f),
        _a["token:zoho:Deals:Products"] = (_g = {},
            _g[Types_2.ProductMappedField.Quantity] = {
                label: "Quantity",
                apiName: "Quantity",
            },
            _g[Types_2.ProductMappedField.Rate] = {
                id: "token:zoho:Deals:Products:Unit_Price",
                label: "Unit Price",
                apiName: "Unit_Price",
            },
            _g),
        _a["token:zoho:Contacts:Products"] = (_h = {},
            _h[Types_2.ProductMappedField.Quantity] = {
                label: "Quantity",
                apiName: "Quantity",
            },
            _h[Types_2.ProductMappedField.Rate] = {
                id: "token:zoho:Contacts:Products:Unit_Price",
                label: "Unit Price",
                apiName: "Unit_Price",
            },
            _h),
        _a["token:pipedrive:Deals:Products"] = (_j = {},
            _j[Types_2.ProductMappedField.Quantity] = {
                id: "token:pipedrive:Deals:Products:Quantity",
                label: "Quantity",
                apiName: "quantity",
            },
            _j[Types_2.ProductMappedField.Rate] = {
                id: "token:pipedrive:Deals:Products:Item_Price",
                label: "Item Price",
                apiName: "item_price",
            },
            _j[Types_2.ProductMappedField.Discount] = {
                id: "token:pipedrive:Deals:Products:discount",
                label: "Discount",
                apiName: "discount",
            },
            _j[Types_2.ProductMappedField.DiscountType] = {
                id: "token:pipedrive:Deals:Products:discount_type",
                label: "Discount Type",
                apiName: "discount_type",
                transform: {
                    amount: Types_2.DiscountType.Fixed,
                    percentage: Types_2.DiscountType.Percent,
                },
            },
            _j),
        _a);
    ProductStaticWellKnownMapping.defaultMappings = (_k = {},
        _k[Types_1.ProviderType.Hubspot] = (_l = {},
            _l[Types_2.ProductAbstractSubfield.Name] = { name: "name", label: "Name" },
            _l[Types_2.ProductAbstractSubfield.Description] = { name: "description", label: "Description" },
            _l),
        _k[Types_1.ProviderType.Salesforce] = (_m = {},
            _m[Types_2.ProductAbstractSubfield.Name] = { name: "name", label: "Name" },
            _m[Types_2.ProductAbstractSubfield.Description] = { name: "description", label: "Description" },
            _m),
        _k["token:zoho:Quotes:Products"] = (_o = {},
            _o[Types_2.ProductAbstractSubfield.Name] = { id: "token:zoho:Quotes:Products:Name" },
            _o[Types_2.ProductAbstractSubfield.Description] = { id: "token:zoho:Quotes:Products:Description" },
            _o),
        _k["token:zoho:Leads:Products"] = (_p = {},
            _p[Types_2.ProductAbstractSubfield.Name] = { id: "token:zoho:Leads:Products:Name" },
            _p[Types_2.ProductAbstractSubfield.Description] = { id: "token:zoho:Leads:Products:Description" },
            _p),
        _k["token:zoho:Accounts:Products"] = (_q = {},
            _q[Types_2.ProductAbstractSubfield.Name] = { id: "token:zoho:Accounts:Products:Name" },
            _q[Types_2.ProductAbstractSubfield.Description] = { id: "token:zoho:Accounts:Products:Description" },
            _q),
        _k["token:zoho:Deals:Products"] = (_r = {},
            _r[Types_2.ProductAbstractSubfield.Name] = { id: "token:zoho:Deals:Products:Name" },
            _r[Types_2.ProductAbstractSubfield.Description] = { id: "token:zoho:Deals:Products:Description" },
            _r),
        _k["token:zoho:Contacts:Products"] = (_s = {},
            _s[Types_2.ProductAbstractSubfield.Name] = { id: "token:zoho:Contacts:Products:Name" },
            _s[Types_2.ProductAbstractSubfield.Description] = { id: "token:zoho:Contacts:Products:Description" },
            _s),
        _k["token:pipedrive:Deals:Products"] = (_t = {},
            _t[Types_2.ProductAbstractSubfield.Name] = { id: "token:pipedrive:Deals:Products:Name" },
            _t[Types_2.ProductAbstractSubfield.Description] = { id: "token:pipedrive:Deals:Products:Description" },
            _t),
        _k);
    return ProductStaticWellKnownMapping;
}());
exports.ProductStaticWellKnownMapping = ProductStaticWellKnownMapping;
