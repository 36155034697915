import "../CommonFrontend/Public/Widgets/AccessAuthentication/AccessAuthentication.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/Public/Widgets/AccessAuthentication/AccessAuthentication.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VUy27bMBC8+yv2UsABSldSEttlLv2HfoBBiyuJBh8CubLlFP73QrRcyVGcGAF6XY1mZpe7s9is89r4xwT+zAAKZ4kVwih95JALrbYemcZS5MeXGUAtpFS25LAXfs5YqEWubMnMZpNu9Wq3S1Pz0OEqVGVFHNIk2VddQapQa3HkUHolu0Ip6rcsemCxkUVoVVqmCE3gEEh46qq7JpAqjix3ltAShxwtoY+kXklGaGotCFnudGNs4GCUNaKdJ9/hKUnq9mEK9e4QOKSFB9GQG3XKyNVdG+cu3B59od2BHXkPPM0W/fzSOD8jWnZQkip+1nqJNV8qyyH5Rz6dRudGeBQcUvgB6Y0JTebcS6VJ8m3sJYteLm45VEpKtGPEY0RMZQfE04DwHUc2tMK2jsiZT95voHr+VGwZEV9akhsr2Y6A7srN6r9rARC2xOL6Dus5OFjfcPD+9nZbmRV+chBT3p9f7WxMsYjela0b2my02KKOrLFKXthQOG84NDZgPMeYGEG94kWjK/xWr8jizyOp7Hx5GonQX6xwSMb6IorlTjvP4VApwvHXbX9jcQk1FsSBpVl3ZQMmH+zefAB5Bwavh1lobD9Mn9Psl0GpBMyNspcMWMW8iUyLq5x9EzBZHzCnMY1o2SVH18vHu3jS5495VsvVfTxjP6Gpa+cpwHwU63Jfvc9zjelJ/gJzXWxfZQYAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var wrapper = '_8cpmr30';
export var container = '_8cpmr31';
export var card = '_8cpmr32';
export var mainCrossFadeWrapper = '_8cpmr33';
export var securedByQwilr = '_8cpmr34';
export var spinner = '_8cpmr35';
export var stackGapL = '_8cpmr36';
export var stackGapLTextCentre = '_8cpmr37';
export var formHeader = '_8cpmr38';
export var form = '_8cpmr39';
export var primaryButtonText = '_8cpmr3a';
export var backButton = '_8cpmr3b';
export var disclaimer = '_8cpmr3c';
export var logInCta = '_8cpmr3d';
export var resendEmailButton = '_8cpmr3e';