"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SideBySidePlaceholderIndex = exports.AllowedPercentageValues = exports.SideBySideColumnSize = exports.SideBySideOrder = void 0;
var SideBySideOrder;
(function (SideBySideOrder) {
    SideBySideOrder["Normal"] = "normal";
    SideBySideOrder["Reverse"] = "reverse";
})(SideBySideOrder || (exports.SideBySideOrder = SideBySideOrder = {}));
var SideBySideColumnSize;
(function (SideBySideColumnSize) {
    SideBySideColumnSize[SideBySideColumnSize["OneQuarter"] = 25] = "OneQuarter";
    SideBySideColumnSize[SideBySideColumnSize["OneHalf"] = 50] = "OneHalf";
    SideBySideColumnSize[SideBySideColumnSize["ThreeQuarters"] = 75] = "ThreeQuarters";
})(SideBySideColumnSize || (exports.SideBySideColumnSize = SideBySideColumnSize = {}));
exports.AllowedPercentageValues = [
    SideBySideColumnSize.OneQuarter,
    SideBySideColumnSize.OneHalf,
    SideBySideColumnSize.ThreeQuarters,
];
var SideBySidePlaceholderIndex;
(function (SideBySidePlaceholderIndex) {
    SideBySidePlaceholderIndex[SideBySidePlaceholderIndex["Column1"] = 1] = "Column1";
    SideBySidePlaceholderIndex[SideBySidePlaceholderIndex["Column2"] = 2] = "Column2";
})(SideBySidePlaceholderIndex || (exports.SideBySidePlaceholderIndex = SideBySidePlaceholderIndex = {}));
