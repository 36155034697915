"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GetCustomClass_1 = require("../GetCustomClass");
var createdSpeechBubbles = [];
exports.default = (function (Tooltip, make, di) {
    var makeSpeechBubble = function (type) {
        return function (options) {
            var targetSelector = options.targetSelector, message = options.message, position = options.position;
            var speechBubbleObj = createdSpeechBubbles.find(function (elem) {
                return elem.targetSelector === targetSelector && elem.message === message;
            });
            if (speechBubbleObj) {
                speechBubbleObj.speechBubble.show();
                return;
            }
            var tooltipOptions = {
                customTheme: "speech-bubble speech-bubble--".concat(type),
                position: position || "top",
                customClass: (0, GetCustomClass_1.getCustomClass)(position),
                stringContent: message,
                hideAfter: 3000,
                closeOnClickAway: true,
                spacing: 5,
            };
            var speechBubble = make(Tooltip, {
                targetSelector: targetSelector,
                options: tooltipOptions,
            });
            speechBubble.show();
            createdSpeechBubbles.push({
                targetSelector: targetSelector,
                message: message,
                speechBubble: speechBubble,
            });
            return;
        };
    };
    return {
        alert: makeSpeechBubble("alert"),
        explain: makeSpeechBubble("explain"),
    };
});
