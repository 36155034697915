// Generated by CoffeeScript 1.12.7
(function () {
  var CurrencyDecimalFormatter, DiscountLabel, Totals, TotalsTemplate;

  TotalsTemplate = require("./Totals.html");

  CurrencyDecimalFormatter = require("Utils/CurrencyDecimalFormatter");

  DiscountLabel = require("./DiscountLabel");

  module.exports = Totals = (function () {
    function Totals(
      createUi,
      make,
      invoke,
      isEditor,
      quoteAdapter,
      clientViewComponents,
      isPrintMode,
      discountService,
      block,
      hint,
    ) {
      var formatCurrency, totalsSettings;
      if (isEditor) {
        totalsSettings = make(clientViewComponents.TotalsSettings);
      }
      formatCurrency = invoke(CurrencyDecimalFormatter);
      this.updateIsMobile = (function (_this) {
        return function (isMobile) {
          return (_this.ui.isMobile = isMobile);
        };
      })(this);
      this.ui = createUi(TotalsTemplate, {
        isEditor: isEditor,
        showFloatingDiscount: (function (_this) {
          return function () {
            return !_this.ui.isMobile || isPrintMode;
          };
        })(this),
        isMobile: null,
        totalsSettingsUi: totalsSettings != null ? totalsSettings.ui : void 0,
        discountLabel: make(DiscountLabel, {
          discountAdapter: quoteAdapter.getDiscountAdapter(),
        }),
        getTotal: function () {
          return formatCurrency(quoteAdapter.getTotal());
        },
        showTaxTotals: function () {
          return quoteAdapter.showTaxTotals() && !_.isEmpty(quoteAdapter.getTaxes());
        },
        showTotalDiscount: function () {
          return quoteAdapter.showTotalDiscount() && quoteAdapter.hasAnyDiscountApplied();
        },
        showExTax: function () {
          return quoteAdapter.showExTax();
        },
        getExTax: function () {
          return formatCurrency(quoteAdapter.getExTax());
        },
        getTaxes: function () {
          return quoteAdapter.getTaxes();
        },
        getPreDiscount: function () {
          return formatCurrency(quoteAdapter.getPreDiscount());
        },
        getDiscountName: function () {
          var savedDiscount;
          if (!quoteAdapter.getDiscountAdapter().getModel().id) {
            return quoteAdapter.getDiscountAdapter().getModel().name;
          }
          savedDiscount = discountService.findDiscountById(quoteAdapter.getDiscountAdapter().getModel().id);
          if (savedDiscount) {
            return savedDiscount.name;
          }
        },
        hasDiscount: function () {
          return quoteAdapter.getDiscountAdapter().hasNonZeroDiscount();
        },
        getTotalDiscount: function () {
          return formatCurrency(quoteAdapter.getAllDiscounts());
        },
        formatCurrency: function (amount) {
          return formatCurrency(amount);
        },
        labelForField: function (field) {
          return quoteAdapter.labelForField(field);
        },
        shouldRemoveBorder: function () {
          if (block.style != null) {
            return block.style.quoteBackgroundOn === false;
          }
        },
      });
    }

    return Totals;
  })();
}.call(this));
