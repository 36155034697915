"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringifyFilter = exports.labelForEnumOperation = exports.labelForNumberOperation = exports.labelForStringOperation = exports.removeChildPropertyLabel = void 0;
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var ProductMetadataService_1 = require("@CommonFrontendBackend/Tokens/ProductMetadataService");
var removeChildPropertyLabel = function (token, name) {
    return name.replace(new RegExp("^".concat(token.childPropertyLabel, ".")), "");
};
exports.removeChildPropertyLabel = removeChildPropertyLabel;
var labelForStringOperation = function (operation) {
    var _a;
    return ((_a = {},
        _a[Types_1.ProductFilterStringOperation.Equals] = "is",
        _a[Types_1.ProductFilterStringOperation.NotEquals] = "is not",
        _a[Types_1.ProductFilterStringOperation.Contains] = "includes",
        _a[Types_1.ProductFilterStringOperation.NotContains] = "does not include",
        _a[Types_1.ProductFilterStringOperation.StartsWith] = "starts with",
        _a[Types_1.ProductFilterStringOperation.NotStartsWith] = "does not start with",
        _a)[operation]);
};
exports.labelForStringOperation = labelForStringOperation;
var labelForNumberOperation = function (operation) {
    var _a;
    return ((_a = {},
        _a[Types_1.ProductFilterNumberOperation.Equals] = "equals",
        _a[Types_1.ProductFilterNumberOperation.NotEquals] = "does not equal",
        _a[Types_1.ProductFilterNumberOperation.GreaterThan] = "is greater than",
        _a[Types_1.ProductFilterNumberOperation.LessThan] = "is less than",
        _a)[operation]);
};
exports.labelForNumberOperation = labelForNumberOperation;
var labelForEnumOperation = function (operation) {
    var _a;
    return ((_a = {},
        _a[Types_1.ProductFilterEnumOperation.Equals] = "is",
        _a[Types_1.ProductFilterEnumOperation.NotEquals] = "is not",
        _a)[operation]);
};
exports.labelForEnumOperation = labelForEnumOperation;
var stringifyFilter = function (item) {
    var _a, _b, _c, _d, _e;
    var token = item.token, filter = item.filter;
    if (!filter) {
        return "All products";
    }
    var stringifiedName = (0, exports.removeChildPropertyLabel)(token, filter.token.label);
    var stringifiedOperation = "";
    var stringifiedValue = "";
    if (filter.type === Types_1.ProductFilterType.String) {
        stringifiedOperation = (0, exports.labelForStringOperation)(filter.operation);
        stringifiedValue = "\"".concat(filter.value, "\"");
    }
    else if (filter.type === Types_1.ProductFilterType.Number) {
        stringifiedOperation = (0, exports.labelForNumberOperation)(filter.operation);
        stringifiedValue = filter.value.toString();
    }
    else if (filter.type === Types_1.ProductFilterType.Enum) {
        stringifiedOperation = (0, exports.labelForEnumOperation)(filter.operation);
        if (ProductMetadataService_1.ProductMetadataService.canFormat((_b = (_a = filter.token.substitutionToken) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "")) {
            stringifiedValue =
                (_e = ProductMetadataService_1.ProductMetadataService.format((_d = (_c = filter.token.substitutionToken) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : "", filter.value)) !== null && _e !== void 0 ? _e : filter.value;
        }
        else {
            stringifiedValue = filter.value;
        }
    }
    return "If ".concat(stringifiedName, " ").concat(stringifiedOperation, " ").concat(stringifiedValue);
};
exports.stringifyFilter = stringifyFilter;
