"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitialsFromUser = exports.getInitials = void 0;
var lodash_1 = require("lodash");
var getInitials = function (first, last) {
    if (last != null && last.length > 0) {
        return (0, lodash_1.upperCase)("".concat(first[0]).concat(last[0]));
    }
    else {
        return (0, lodash_1.upperCase)(first[0]);
    }
};
exports.getInitials = getInitials;
var getInitialsFromUser = function (user) {
    if (user != null && user.firstName != null) {
        return (0, exports.getInitials)(user.firstName, user.lastName);
    }
    else {
        return null;
    }
};
exports.getInitialsFromUser = getInitialsFromUser;
