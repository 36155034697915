// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (element) {
    var clientRect, offset;
    offset = element.offset();
    clientRect = element[0].getBoundingClientRect();
    offset.bottom = offset.top + clientRect.height;
    offset.right = offset.left + clientRect.width;
    return offset;
  };
}.call(this));
