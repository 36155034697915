"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildName = void 0;
var buildName = function (first, last) {
    if (last != null && last.length > 0) {
        return "".concat(first, " ").concat(last);
    }
    else if (first != null) {
        return first;
    }
    else {
        return "";
    }
};
exports.buildName = buildName;
var getFullName = function (user) {
    return (0, exports.buildName)(user.firstName, user.lastName);
};
exports.default = getFullName;
