"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariableLabelSize = void 0;
var React = __importStar(require("react"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var MediaVariable_1 = require("ContentTokens/DataModels/MediaVariable");
var VariableLabelSize;
(function (VariableLabelSize) {
    VariableLabelSize["Normal"] = "normal";
    VariableLabelSize["Small"] = "small";
    VariableLabelSize["Tiny"] = "tiny";
})(VariableLabelSize || (exports.VariableLabelSize = VariableLabelSize = {}));
var MediaVariable = (function (_super) {
    __extends(MediaVariable, _super);
    function MediaVariable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MediaVariable.prototype.render = function () {
        return (React.createElement("div", { className: "media-variable-widget" },
            React.createElement("div", { className: "media-variable-label media-variable-label--".concat(this.props.size) },
                this.renderIcon(this.props.media),
                React.createElement("span", { className: "media-variable-label__label" }, this.props.label))));
    };
    MediaVariable.prototype.renderIcon = function (mediaType) {
        switch (mediaType) {
            case MediaVariable_1.Media.Image:
                return React.createElement(icons_1.Image, { className: "media-variable-label__icon" });
            case MediaVariable_1.Media.Video:
                return React.createElement(icons_1.Video, { className: "media-variable-label__icon" });
            case MediaVariable_1.Media.GoogleMaps:
                return React.createElement(icons_1.BlockEmbed, { className: "media-variable-label__icon" });
            case MediaVariable_1.Media.Iframe:
                return React.createElement(icons_1.Api, { className: "media-variable-label__icon" });
        }
    };
    return MediaVariable;
}(React.Component));
exports.default = MediaVariable;
