// Generated by CoffeeScript 1.12.7
(function () {
  var CostLabel,
    ItemRateInput,
    ItemStore,
    ItemView,
    OptionSelect,
    QuantityInput,
    QuoteItemFactory,
    RateLabel,
    TextLabel,
    UnitNameInput,
    VariableUnitLabel,
    createItemDiscountComponent,
    descriptionFactory,
    itemRateFactory;

  ItemView = require("./QuoteItem");

  ItemStore = require("../Stores/ItemStore")["default"];

  descriptionFactory = require("./Factories/DescriptionFactory");

  itemRateFactory = require("./Factories/ItemRateFactory");

  createItemDiscountComponent = require("./ItemComponents/CreateItemDiscountComponent").createItemDiscountComponent;

  TextLabel = require("./ItemComponents/TextLabel.html");

  VariableUnitLabel = require("./ItemComponents/VariableUnitLabel.html");

  UnitNameInput = require("./ItemComponents/UnitNameInput.html");

  QuantityInput = require("./ItemComponents/QuantityInput");

  ItemRateInput = require("./ItemComponents/ItemRateInput");

  RateLabel = require("./ItemComponents/RateLabel");

  CostLabel = require("./ItemComponents/CostLabel");

  OptionSelect = require("./ItemComponents/OptionSelect");

  /*
  A view factory for building QuoteItem objects, figuring out what UI component to put into each
  column based on whether we are on Client/Public and view settings for the quote section
  @param useEditorControls Are we in the editor and not in mobile view?
  @param sectionView The section view to add item views to
  @param clientViewComponents Components that have been injected in from ./Client
   */

  module.exports = QuoteItemFactory = (function () {
    function QuoteItemFactory(
      make,
      createUi,
      uniqueId,
      useEditorControls,
      isMobile,
      isPrintMode,
      Editor,
      sectionView,
      variableService,
      clientViewComponents,
      $sce,
      usingFeature,
      di,
      quoteCurrencyFormatter,
      quoteAdapter,
      mountReactComponent,
    ) {
      var createCost,
        createDescription,
        createDiscount,
        createInsertButton,
        createItemRate,
        createOptionSelect,
        createQuantity,
        createSettingsButton,
        createUnitName,
        isTextItem;
      createDescription = function (itemAdapter, itemStore) {
        return descriptionFactory.createDescription(
          usingFeature,
          di,
          itemAdapter,
          itemStore,
          Editor,
          createUi,
          useEditorControls,
        );
      };
      createDiscount = function (itemAdapter) {
        return di.convoke(createItemDiscountComponent, null, {
          itemAdapter: itemAdapter,
          formatCurrency: di.invoke(require("Utils/CurrencyDecimalFormatter")),
          discountLabel: quoteAdapter.labelForField("discount"),
        });
      };
      createItemRate = function (itemAdapter, itemViewId, itemStore) {
        var itemRateComponent;
        itemRateComponent = itemRateFactory.createItemRate(
          di,
          itemAdapter,
          itemStore,
          clientViewComponents,
          quoteCurrencyFormatter,
          quoteAdapter,
          useEditorControls,
        );
        return mountReactComponent(itemRateComponent);
      };
      createQuantity = function (itemAdapter, itemViewId) {
        var quantityInput;
        if (!isTextItem(itemAdapter.type())) {
          /*
            We always construct the quantity number even if we aren't initially showing it,
            and it is up to the QuoteItem view to show/hide the compoment.
          
            This is because we always show the quantity number if the quantity is optional,
            even if the "Show rate and quantity" section setting is off. The QuoteItem view can
            then show/hide the component when the user toggles "Make quantity optional" on and off,
            without completely reconstructing the entire item table
           */
          if (!isPrintMode && (useEditorControls || itemAdapter.isQuantityOptional())) {
            quantityInput = make(QuantityInput, {
              itemAdapter: itemAdapter,
              itemViewId: itemViewId,
              sectionViewId: sectionView.ui.uniqueId,
            });
            return quantityInput.ui;
          } else {
            return createUi(TextLabel, {
              text: itemAdapter.getQuantity(),
            });
          }
        }
      };
      createUnitName = function (itemAdapter) {
        var ref;
        if (isTextItem(itemAdapter.type())) {
          return;
        }
        if (!useEditorControls && !itemAdapter.showQuantity()) {
          return;
        }
        if (itemAdapter.type() === "fixedCost") {
          if (useEditorControls) {
            return createUi(UnitNameInput, {
              rate: itemAdapter.rate(),
            });
          } else {
            return createUi(TextLabel, {
              text: (ref = itemAdapter.rate()) != null ? ref.unit : void 0,
            });
          }
        } else if (itemAdapter.type() === "variable") {
          return createUi(VariableUnitLabel, {
            getText: function () {
              var ref1, ref2;
              return (ref1 = variableService.getVariable(
                (ref2 = itemAdapter.rate()) != null ? ref2.variableId : void 0,
              )) != null
                ? ref1.unitName
                : void 0;
            },
          });
        }
      };
      createCost = function (itemAdapter) {
        var label;
        if (!useEditorControls && !itemAdapter.showCost() && itemAdapter.type() !== "text") {
          return;
        }
        if (!isTextItem(itemAdapter.type())) {
          label = make(CostLabel, {
            itemAdapter: itemAdapter,
          });
          return label.ui;
        }
      };
      createOptionSelect = function (itemAdapter) {
        var optionSelect;
        if (useEditorControls || itemAdapter.isOptional()) {
          optionSelect = make(OptionSelect, {
            itemAdapter: itemAdapter,
          });
          return optionSelect.ui;
        }
      };
      createInsertButton = function (itemView) {
        return make(clientViewComponents.InsertItem, {
          sectionView: sectionView,
          itemView: itemView,
        });
      };
      createSettingsButton = function (itemAdapter, itemView) {
        return make(clientViewComponents.ItemSettings, {
          itemAdapter: itemAdapter,
          sectionView: sectionView,
          itemView: itemView,
        });
      };
      isTextItem = function (type) {
        return type === "text";
      };
      this.createItem = function (itemAdapter) {
        var components, insertButton, itemStore, itemView, itemViewId, settingsButton;
        itemViewId = "item-view--" + uniqueId(8);
        itemStore = new ItemStore(itemAdapter.model);
        components = {
          description: createDescription(itemAdapter, itemStore),
          itemRate: createItemRate(itemAdapter, itemViewId, itemStore),
          quantity: createQuantity(itemAdapter, itemViewId),
          unitName: createUnitName(itemAdapter),
          cost: createCost(itemAdapter),
          optionSelect: createOptionSelect(itemAdapter),
          discount: createDiscount(itemAdapter),
        };
        itemView = make(ItemView, {
          itemAdapter: itemAdapter,
          componentUis: components,
          itemViewId: itemViewId,
        });
        if (useEditorControls) {
          insertButton = createInsertButton(itemView);
          settingsButton = createSettingsButton(itemAdapter, itemView);
          itemView.addInsertButton(insertButton);
          itemView.addSettingsButton(settingsButton);
        }
        return itemView;
      };
    }

    return QuoteItemFactory;
  })();
}.call(this));
