// Generated by CoffeeScript 1.12.7

/*
  Aspect-aware resize directive

  This is resize-directive approximately equivalent to object-fit: cover.
  However, unlike object-fit, it can work on any element that is relatively or
  absolutely positioned.

  It sets the element size using the DOM, so it should avoid most of the
  issues with attempts to maintain an aspect ratio using CSS.

  Use it like this: <div aspectResize={{16/9}}></div>
 */

(function () {
  module.exports = angular.module("qwilr.aspectResize", []).directive("aspectResize", function ($window) {
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        var parent, ratio, resize, setSize;
        element = element[0];
        ratio = attrs.aspectResize;
        parent = element.parentNode;
        while (!(parent.offsetWidth && parent.offsetHeight)) {
          parent = parent.parentNode;
        }
        setSize = function (width, height) {
          element.style.width = width + "px";
          element.style.height = height + "px";
          element.style.left = (parent.offsetWidth - width) / 2 + "px";
          return (element.style.top = (parent.offsetHeight - height) / 2 + "px");
        };
        resize = function () {
          var height, width;
          width = parent.offsetWidth;
          height = parent.offsetHeight;
          if (width > height * ratio) {
            return setSize(width, width * (1 / ratio));
          } else {
            return setSize(height * ratio, height);
          }
        };
        angular.element($window).on("resize", resize);
        scope.$on("$destroy", function () {
          return angular.element($window).off("resize", resize);
        });
        return resize();
      },
    };
  });
}.call(this));
