"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.screen = exports.multiply = exports.getColorsForAccount = exports.getRgba = exports.getHex = exports.getRgb = exports.isValidHex = exports.isValidColor = exports.replaceOpacity = exports.areColorsEqual = exports.areColorsEquivalent = exports.getColorContrast = exports.areColorsContrasting = exports.shouldUseDarkText = exports.getLuma = exports.assembleBrandColors = void 0;
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var assembleBrandColors = function (brandColors) {
    var brand = brandColors.brand, complementary = brandColors.complementary, extra = brandColors.extra;
    var brandColorsObject = [
        {
            value: (0, exports.getRgba)(brand),
            themeIndex: 0,
        },
        {
            value: (0, exports.getRgba)(complementary),
            themeIndex: 1,
        },
    ];
    var extraColors = !!extra
        ? extra.map(function (color, index) { return ({
            value: color,
            themeIndex: index + 2,
        }); })
        : [];
    return __spreadArray(__spreadArray([], __read(brandColorsObject), false), __read(extraColors), false);
};
exports.assembleBrandColors = assembleBrandColors;
var getLumaComponent = function (component) {
    var normalizedComponent = component / 255;
    if (normalizedComponent <= 0.03928) {
        return normalizedComponent / 12.92;
    }
    return Math.pow((normalizedComponent + 0.055) / 1.055, 2.4);
};
var getLuma = function (color) {
    var _a = getColorObj(color), r = _a.r, g = _a.g, b = _a.b;
    return 0.2126 * getLumaComponent(r) + 0.7152 * getLumaComponent(g) + 0.0722 * getLumaComponent(b);
};
exports.getLuma = getLuma;
var shouldUseDarkText = function (backgroundColor) {
    try {
        if ((0, exports.areColorsContrasting)(backgroundColor, DefaultColors_1.DEFAULT_MIDNIGHT.value)) {
            return true;
        }
        else {
            return false;
        }
    }
    catch (error) {
        return false;
    }
};
exports.shouldUseDarkText = shouldUseDarkText;
var areColorsContrasting = function (color1, color2) {
    if (color1 === "transparent" || color2 === "transparent") {
        return color1 !== color2;
    }
    var lumaColor1 = (0, exports.getLuma)(color1) * 100;
    var lumaColor2 = (0, exports.getLuma)(color2) * 100;
    var lumaDiff = (Math.max(lumaColor1, lumaColor2) + 5) / (Math.min(lumaColor1, lumaColor2) + 5);
    var WCAG_AA_THRESHOLD = 4.5;
    return lumaDiff >= WCAG_AA_THRESHOLD;
};
exports.areColorsContrasting = areColorsContrasting;
var getColorContrast = function (color1, color2) {
    if (color1 === "transparent" || color2 === "transparent") {
        if (color1 !== color2) {
            return;
        }
    }
    var lumaColor1 = (0, exports.getLuma)(color1) * 100;
    var lumaColor2 = (0, exports.getLuma)(color2) * 100;
    var lumaDiff = (Math.max(lumaColor1, lumaColor2) + 5) / (Math.min(lumaColor1, lumaColor2) + 5);
    return lumaDiff;
};
exports.getColorContrast = getColorContrast;
var areColorsEquivalent = function (color1, color2) {
    var areValidColors = (0, exports.isValidColor)(color1) && (0, exports.isValidColor)(color2);
    return areValidColors ? (0, exports.getRgb)(color1) === (0, exports.getRgb)(color2) : false;
};
exports.areColorsEquivalent = areColorsEquivalent;
var areColorsEqual = function (color1, color2) {
    var areValidColors = (0, exports.isValidColor)(color1) && (0, exports.isValidColor)(color2);
    return areValidColors ? (0, exports.getRgba)(color1) === (0, exports.getRgba)(color2) : false;
};
exports.areColorsEqual = areColorsEqual;
var replaceOpacity = function (color, opacity) {
    var rgbColor = (0, exports.getRgba)(color);
    var strings = rgbColor.split(",");
    strings[3] = " ".concat(opacity, ")");
    return strings.join(",");
};
exports.replaceOpacity = replaceOpacity;
var isValidColor = function (color) {
    return !!color && (isValidRgb(color) || isValidRgba(color) || (0, exports.isValidHex)(color));
};
exports.isValidColor = isValidColor;
var isValidHex = function (color) {
    if (color == null) {
        return false;
    }
    if (color.charAt(0) !== "#") {
        color = "#".concat(color);
    }
    if (color.length < 4) {
        return false;
    }
    return color.match(/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i) != null;
};
exports.isValidHex = isValidHex;
var isValidRgb = function (color) {
    var regexMatch = color.match(/^rgb\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/i);
    if (!regexMatch) {
        return false;
    }
    var rgbValues = [regexMatch[1], regexMatch[2], regexMatch[3]];
    return !rgbValues.find(function (value) {
        var numberVal = parseInt(value, 10);
        return numberVal > 255 || numberVal < 0;
    });
};
var isValidRgba = function (color) {
    var regexMatch = color.match(/^rgba\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d\.]+)\s*\)/i);
    if (!regexMatch) {
        return false;
    }
    var rgbValues = [regexMatch[1], regexMatch[2], regexMatch[3]];
    var opacity = parseFloat(regexMatch[4]);
    return (!rgbValues.find(function (value) {
        var numberVal = parseInt(value, 10);
        return numberVal > 255 || numberVal < 0;
    }) &&
        opacity >= 0 &&
        opacity <= 1);
};
var convertTo6CharHex = function (color) {
    if (color.charAt(0) === "#") {
        color = color.substring(1);
    }
    if (color.length === 6) {
        return color;
    }
    if (color.length === 3) {
        var r = color[0];
        var g = color[1];
        var b = color[2];
        return "".concat(r).concat(r).concat(g).concat(g).concat(b).concat(b);
    }
    return color.toLowerCase();
};
var getColorObj = function (color) {
    if ((0, exports.isValidHex)(color)) {
        var hex = convertTo6CharHex(color);
        var rgb = parseInt(hex, 16);
        return {
            r: (rgb >> 16) & 0xff,
            g: (rgb >> 8) & 0xff,
            b: (rgb >> 0) & 0xff,
            opacity: 1,
        };
    }
    else if (isValidRgb(color)) {
        var regexMatch = color.match(/(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*/);
        return {
            r: parseInt(regexMatch[1], 10),
            g: parseInt(regexMatch[2], 10),
            b: parseInt(regexMatch[3], 10),
            opacity: 1,
        };
    }
    else if (isValidRgba(color)) {
        var regexMatch = color.match(/^rgba\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d\.]+)\s*\)/i);
        var opacity = parseFloat(regexMatch[4]);
        return {
            r: parseInt(regexMatch[1], 10),
            g: parseInt(regexMatch[2], 10),
            b: parseInt(regexMatch[3], 10),
            opacity: opacity,
        };
    }
    else {
        throw new Error("Invalid color");
    }
};
var getRgb = function (color) {
    var _a = getColorObj(color), r = _a.r, g = _a.g, b = _a.b;
    return "rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")");
};
exports.getRgb = getRgb;
var getHex = function (color) {
    var _a = getColorObj(color), r = _a.r, g = _a.g, b = _a.b;
    return "#".concat(getHexUnit(r)).concat(getHexUnit(g)).concat(getHexUnit(b));
};
exports.getHex = getHex;
var getRgba = function (color) {
    var _a = getColorObj(color), r = _a.r, g = _a.g, b = _a.b, opacity = _a.opacity;
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(opacity, ")");
};
exports.getRgba = getRgba;
var getHexUnit = function (c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0".concat(hex) : hex;
};
var getColorsForAccount = function (accountBrandColors) {
    var brand = accountBrandColors.brand, complementary = accountBrandColors.complementary, extra = accountBrandColors.extra;
    var defaultColorString = DefaultColors_1.allDefaultColors.map(function (color) { return color.value; });
    if (!(extra === null || extra === void 0 ? void 0 : extra.length)) {
        return __spreadArray([brand, complementary], __read(defaultColorString), false);
    }
    return __spreadArray(__spreadArray([brand, complementary], __read(extra), false), __read(defaultColorString), false);
};
exports.getColorsForAccount = getColorsForAccount;
var multiply = function (c1, c2) {
    var first = getColorObj(c1);
    var second = getColorObj(c2);
    var _a = {
        r: Math.round((first.r * second.r) / 255),
        g: Math.round((first.g * second.g) / 255),
        b: Math.round((first.b * second.b) / 255),
    }, r = _a.r, g = _a.g, b = _a.b;
    return "#".concat(getHexUnit(r)).concat(getHexUnit(g)).concat(getHexUnit(b));
};
exports.multiply = multiply;
var screen = function (c1, c2) {
    var first = getColorObj(c1);
    var second = getColorObj(c2);
    var _a = {
        r: Math.round((1 - (1 - first.r / 255) * (1 - second.r / 255)) * 255),
        g: Math.round((1 - (1 - first.g / 255) * (1 - second.g / 255)) * 255),
        b: Math.round((1 - (1 - first.b / 255) * (1 - second.b / 255)) * 255),
    }, r = _a.r, g = _a.g, b = _a.b;
    return "#".concat(getHexUnit(r)).concat(getHexUnit(g)).concat(getHexUnit(b));
};
exports.screen = screen;
