"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateEmbedUrlPrefix = void 0;
var updateEmbedUrlPrefix = function (embedUrl) {
    if (embedUrl === null || embedUrl === void 0 ? void 0 : embedUrl.startsWith("//loom.com")) {
        return embedUrl === null || embedUrl === void 0 ? void 0 : embedUrl.replace("//loom.com", "https://www.loom.com");
    }
    return embedUrl;
};
exports.updateEmbedUrlPrefix = updateEmbedUrlPrefix;
