"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AngularSyncServices = void 0;
var DIProvider_1 = require("DIProvider/DIProvider");
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var rfc6902_1 = require("rfc6902");
var jsondiffpatch = __importStar(require("./jsondiffpatch"));
exports.AngularSyncServices = (0, DIProvider_1.makeServiceIdentifier)(Symbol("SyncServices"));
var isSyncingDisabled = function (services) { return "disableSync" in services; };
var identity = function (input) { return input; };
var stripObservables = function (operation) {
    if ("value" in operation) {
        return __assign(__assign({}, operation), { value: (0, mobx_1.toJS)(operation.value, { recurseEverything: true }) });
    }
    return operation;
};
var AngularSyncStore = (function () {
    function AngularSyncStore(initialData, services, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.extractDataFromAngular, extractDataFromAngular = _c === void 0 ? identity : _c, _d = _b.getArrayItemId, getObjectId = _d === void 0 ? function () { return undefined; } : _d, _e = _b.debounceSync, debounceSync = _e === void 0 ? false : _e, _f = _b.skipDiffOnAngularChange, skipDiffOnAngularChange = _f === void 0 ? false : _f;
        var _this = this;
        this.DEBUG = false;
        this.ignoreNextAngularUpdate = false;
        this.syncFromReactToAngular = function (reactData, $timeout) {
            var patch = jsondiffpatch.formatters.jsonpatch
                .format(_this.diffpatch.diff(_this.angularData, reactData))
                .map(stripObservables);
            if (patch.length === 0) {
                if (_this.DEBUG)
                    console.log("prepareDataForAngular was called but there were no changes to sync.");
                return;
            }
            if (_this.DEBUG)
                console.log("prepareDataForAngular was called. Applying changes.\nPatch:\n", patch);
            _this.ignoreNextAngularUpdate = true;
            $timeout(function () { return (0, rfc6902_1.applyPatch)(_this.initialData, patch); });
        };
        this.diffpatch = jsondiffpatch.create({
            objectHash: getObjectId,
            textDiff: { minLength: 1000000 },
        });
        this.initialData = initialData;
        this.angularData = extractDataFromAngular(initialData);
        if (isSyncingDisabled(services)) {
            return;
        }
        var $scope = services.$scope, $timeout = services.$timeout;
        $scope.$watch(function () { return extractDataFromAngular(_this.initialData); }, function (angularData) {
            _this.angularData = angularData;
            if (_this.ignoreNextAngularUpdate) {
                _this.ignoreNextAngularUpdate = false;
                return;
            }
            if (!skipDiffOnAngularChange) {
                var patch = jsondiffpatch.formatters.jsonpatch.format(_this.diffpatch.diff(extractDataFromAngular(_this.dataForAngular), _this.angularData));
                if (patch.length === 0) {
                    return;
                }
                if (_this.DEBUG) {
                    console.log("Data has changed in angular. Invoking onAngularUpdated.\nData:\n", _this.angularData, "\nDiff:\n", patch);
                }
            }
            else {
                if (_this.DEBUG) {
                    console.log("Data has changed in angular and skipDiffOnAngularChange is true. Invoking onAngularUpdated.\nData:\n", _this.angularData);
                }
            }
            _this.onAngularUpdated(_this.angularData);
        }, true);
        var syncToAngularFunction = debounceSync
            ? (0, lodash_1.debounce)(this.syncFromReactToAngular, 500, { maxWait: 2000 })
            : this.syncFromReactToAngular;
        setTimeout(function () {
            (0, mobx_1.autorun)(function () {
                var reactData = extractDataFromAngular(_this.dataForAngular);
                syncToAngularFunction(reactData, $timeout);
            });
        }, 0);
    }
    return AngularSyncStore;
}());
exports.default = AngularSyncStore;
