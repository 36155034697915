// Generated by CoffeeScript 1.12.7
(function () {
  var LabelTemplate, RateLabel, getDecimalPlaces;

  getDecimalPlaces = require("Utils/GetDecimalPlaces").getDecimalPlaces;

  LabelTemplate = require("./TextLabel.html");

  module.exports = RateLabel = (function () {
    function RateLabel(createUi, quoteCurrencyFormatter, rateAmount, quoteAdapter) {
      var decimalPlaces;
      if (rateAmount != null) {
        decimalPlaces = Math.min(getDecimalPlaces(rateAmount), 6);
        if (decimalPlaces > 0 || quoteAdapter.showDecimalPlaces()) {
          decimalPlaces = Math.max(decimalPlaces, quoteCurrencyFormatter.idealDecimalPlaces());
        }
      } else {
        rateAmount = 0;
      }
      this.ui = createUi(LabelTemplate, {
        text: quoteCurrencyFormatter.format(rateAmount, decimalPlaces),
      });
    }

    return RateLabel;
  })();
}.call(this));
